import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UpperCaseFirstPipe } from 'src/app/pipes/ucfirst.pipe';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatPipe } from 'src/app/pipes/dateFormat.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlanActivityAreasModalComponent } from '../plan-activity-areas-modal/plan-activity-areas-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-plan-card-search',
  templateUrl: './plan-card-search.component.html',
  styleUrls: ['./plan-card-search.component.scss']
})
export class PlanCardSearchComponent implements OnInit {
  @Input()
  plan: any;
  @Input()
  isLoggedIn = false;
  @Input()
  userId;
  @Input()
  icon = '';
  @Input()
  query = '';

  activityAreasLabels = [];
  updatedAtString = '';
  activityAreasModalRef: BsModalRef;

  @Output()
  cardClick = new EventEmitter<any>();

  @Output()
  toggle = new EventEmitter<{ plan: any; isToggled: boolean }>();

  @Output()
  deleteFavorite = new EventEmitter<{ planId: string; userId: string }>();

  constructor(
    private ucfirst: UpperCaseFirstPipe,
    private translate: TranslateService,
    private dateFormatPipe: DateFormatPipe,
    private modalService: BsModalService
  ) {}

  async ngOnInit() {
    this.updatedAtString = this.plan.updatedat
      ? this.ucfirst.transform(this.translate.instant('search.changed')) +
        ' ' +
        this.dateFormatPipe.transform(this.plan.updatedat, 'DD/MM/YYYY')
      : '';
    this.activityAreasLabels = this.plan.activityareas.map(
      activityArea => activityArea.label
    );
    this.activityAreasLabels.sort();
  }

  onCardClick() {
    this.cardClick.emit(this.plan.slug);
  }

  openActivityAreasModal(clickEvent) {
    clickEvent.stopPropagation();
    this.activityAreasModalRef = this.modalService.show(
      PlanActivityAreasModalComponent,
      Object.assign(
        {},
        {
          initialState: {
            activityAreas: this.activityAreasLabels
          },
          animated: true,
          class: 'hub-modal'
        }
      )
    );
  }
}
