import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { environmentLoader } from '../../environments/environmentLoader';
import { AuthService } from '../services/auth.service';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  realm: string;

  constructor(private auth: AuthService) {
    environmentLoader.then(env => {
      this.realm = env.settings.auth.keycloak.realm;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token$ = from(this.auth.getToken());
    return token$.pipe(
      mergeMap((token: string) => {
        if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token,
              realm: this.realm
            }
          });
        }
        return next.handle(req);
      })
    );
  }
}
