import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { datetimeFormat } from '../constants';

@Pipe({ name: 'capitalizeFirst' })
export class CapitalizeFirstPipe implements PipeTransform {
  transform(content) {
    let formatted = content.charAt(0).toUpperCase();
    if (content && content.length > 1) {
      for (let i = 1; i <= content.length; i++) {
        formatted += content.charAt(i).toLowerCase();
      }
    }
    return formatted;
  }
}
