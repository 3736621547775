<div class="container hub-host">
  <div class="mb-2 title">
    <p>{{ 'welcome' | translate | ucfirst }}</p>
    <p>{{ 'view-agents-partners' | translate | ucfirst }}</p>
  </div>
  <div class="mb-3">
    <button (click)="goToLogin()" class="btn-hub btn-primary connect">{{ 'login' | translate | ucfirst }}</button>
  </div>
</div>

<ng-template #modalTemplate>
  <div class="modal-header hub-modal-header">
    <h4 class="title">
      <i class="fa fa-exclamation-circle"></i>
      {{ 'login-messages.inactive-account' | translate | ucfirst }}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="loginErrorModal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body hub-modal-body">
    <p>{{ 'login-messages.interlocutor-not-found' | translate | ucfirst }} {{ email }}.</p>
    <p>{{ 'login-messages.email-sent-to-admin' | translate | ucfirst }}</p>
  </div>
</ng-template>
