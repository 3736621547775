import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../services/session.service';
import {InterlocutorService} from '../../services/hub-api/interlocutor.service';
import {dateFormat, defaultAvatarUrl, interlocutorRoles, simpleDateComparisonFormat, telFormat} from '../../constants';
import {OccupationService} from '../../services/hub-api/occupation.service';
import {ToastrService} from 'ngx-toastr';
import {ActivityAreaService} from '../../services/hub-api/activityArea.service';
import {SpecialityService} from '../../services/hub-api/speciality.service';
import {TranslateService} from '@ngx-translate/core';
import {UpperCaseFirstPipe} from '../../pipes/ucfirst.pipe';
import {AgentAvatarService} from '../../services/hub-api/agent-avatar.service';
import {HeaderService} from '../../services/header.service';
import {TerritoryService} from '../../services/hub-api/territory.service';
import {GdiService} from '../../services/gdi.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {HubBreadCrumbsService} from "../../services/hub-breadcrumbs.service";
import {environmentLoader} from "../../../environments/environmentLoader";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  currentAgent: any;

  // Détient les informations sur tout ce qui concerne la structure, le poste etc...
  currentInterlocutor: any;

  agentAvatarUrl = '';
  fileTooLarge = false;

  // Gestion de l'état des boutons Modifier
  isModifyIdentityButtonActive = true;
  isModifyOccupationButtonActive = true;
  isModifyContactButtonActive = true;
  isModifySpecialityButtonActive = true;
  isModifyActivityAreaButtonActive = true;
  isModifyTerritoriesButtonActive = true;
  isModifyAbsenceButtonActive = true;

  isReferent: boolean;
  isExpert: boolean;
  isSpecialist: boolean;

  // Indique les états dans lesquels peuvent se trouver les différentes sections de la page de profil
  STATES = {
    READ: 'read',
    MODIFY: 'modify'
  };

  // Gestion de l'état des différentes différentes sections
  sectionsState = {
    occupation: this.STATES.READ,
    contact: this.STATES.READ,
    activityArea: this.STATES.READ,
    speciality: this.STATES.READ,
    territory: this.STATES.READ
  };

  aboutMeSections = {
    ACTIVITY_AREAS: 'activityAreas',
    SPECIALITIES: 'speciality',
    TERRITORIES: 'territories'
  };
  aboutMeSectionDisplayed: string = undefined;

  // Données pour les différentes modifications
  selectedOccupationId: any;

  // Postes dans la liste déroulante
  occupations: Array<any>;

  // Valeur de l'input Skype
  skypeInputValue = '';

  // Valeur de l'input Linkedin
  linkedinInputValue = '';

  // Ensemble des filières
  activityAreas: Array<any>;
  // Filières à afficher, sert quand on modifie la liste des filières,
  // on ne veut pas modifier directement currentInterlocutor.activityAreas
  activityAreasToDisplay: Array<any>;
  // Filières à afficher dans la liste déroulante
  activityAreasSelect: Array<any>;

  // Ensembles des domaines de compétences
  specialities: Array<any>;
  // Domaines de compétences à afficher, sert quand on modifie la liste des domaines de compétences,
  // on ne veut pas modifier directement currentInterlocutor.specialities
  specialitiesToDisplay: Array<any>;
  // Domaines de compétences à afficher dans la liste déroulante
  specialitiesSelect: Array<any>;

  // Ensemble des territoires
  territories: Array<any>;
  // Territoires à afficher, sert quand on modifie la liste des territoires,
  // on ne veut pas modifier directement currentInterlocutor.territories
  territoriesToDisplay: Array<any>;
  // Territoires à afficher dans la liste déroulante
  territoriesSelect: Array<any>;

  // Indique si l'utilisateur doit sauvegarder ses données
  // avant de changer de section (domaine de compétences, filières, territoires)
  mustSaveBeforeChangingSection = false;

  absenceForm;
  allInterlocutors;
  interlocutorsByStructure;
  interlocutorsList;
  modifyingAbsence: boolean = false;
  todayDate = this.convertAnyDateToFormDate(moment());

  maxFileSize: number;

  constructor(
    private session: SessionService,
    private interlocutorService: InterlocutorService,
    private occupationService: OccupationService,
    private activityAreaService: ActivityAreaService,
    private specialityService: SpecialityService,
    private toaster: ToastrService,
    private translate: TranslateService,
    private ucfirst: UpperCaseFirstPipe,
    private agentAvatarService: AgentAvatarService,
    private headerService: HeaderService,
    private territoryService: TerritoryService,
    private gdi: GdiService,
    private spinner: NgxSpinnerService,
    private breadcrumbs: HubBreadCrumbsService,
    private translateService: TranslateService
  ) {
    environmentLoader.then(env => {
      this.maxFileSize = env.settings.file.maxSize;
      this.maxFileSize = isNaN(this.maxFileSize) ? 0 : this.maxFileSize;
    });
  }

  async ngOnInit() {
    this.breadcrumbs.add([
      {
        label: this.translateService.instant('breadcrumbs.my-profile'),
        url: '/profil',
        params: []
      }
    ]);
    this.session.getAgentAsObservable().subscribe(async agent => {
      if (agent) {
        this.currentAgent = agent;
        await this.loadInterlocutorData();
        await this.interlocutorService
          .getinterlocutorRolesById(this.currentAgent.interlocutorid)
          .then((roles: Array<string>) => {
            this.isReferent = roles.includes(interlocutorRoles.referent);
            this.isExpert = roles.includes(interlocutorRoles.expert);
            this.isSpecialist = roles.includes(interlocutorRoles.specialist);
          });
        await this.occupationService
          .getAll()
          .then((occupations: Array<any>) => (this.occupations = occupations));
        await this.activityAreaService
          .getAll()
          .then((activityAreas: Array<any>) => {
            this.activityAreas = activityAreas;
            this.setActivityAreasSelect();
          });
        await this.specialityService
          .getAll()
          .then((specialities: Array<any>) => {
            this.specialities = specialities;
            this.setSpecialitiesSelect();
          });
        await this.territoryService.getAll().then((territories: Array<any>) => {
          this.territories = territories;
          this.setTerritoriesSelect();
        });
      }
    });
  }

  submitAbsenceForm() {

    let params = {
      debutAbsence: this.convertAnyFormDateToDate(this.absenceForm.get("startDate").value),
      finAbsence: this.convertAnyFormDateToDate(this.absenceForm.get("endDate").value),
      backup: undefined,
      telBackup: undefined
    };

    if (this.absenceForm.get("physical").value) {
      params.backup = this.absenceForm.get("backup").value;
    } else {
      params.telBackup = this.absenceForm.get("telBackup").value;
    }

    this.interlocutorService.setInterlocutorAbsencePeriode(this.currentInterlocutor.id, params).then((absence: any) => {
      let result = absence;
      result.backup = this.allInterlocutors.find(interlocutor => interlocutor.id === absence.backup);
      this.setupAbsencePeriode(result);

      this.toaster.success(this.translate.instant('my-profile.absence.form.success-update'));
      this.modifyingAbsence = false;
      this.activeButtons(true);
    });

  }

  removeAbsencePeriode() {
    this.interlocutorService.removeInterlocutorAbsencePeriode(this.currentInterlocutor.id).then( interlocutor => {

      this.currentInterlocutor = interlocutor;
      this.toaster.success(this.translate.instant('my-profile.absence.form.success-delete'));
    });
  }

  async setupAbsencePeriode(absence) {
    if (absence.debutAbsence && absence.finAbsence) {
      moment.locale('fr');
      this.currentInterlocutor.debutAbsence = moment(absence.debutAbsence).format("L");
      this.currentInterlocutor.finAbsence = moment(absence.finAbsence).format("L");
      this.currentInterlocutor.backup = absence.backup ? await this.interlocutorService.getById(absence.backup.id) : null;
      this.currentInterlocutor.telBackup = absence.telBackup;
    }
  }

  initAbsenceForm() {
    this.absenceForm = new FormGroup({
      startDate: new FormControl(this.convertAnyDateToFormDate(this.currentInterlocutor.debutAbsence)),
      endDate: new FormControl(this.convertAnyDateToFormDate(this.currentInterlocutor.finAbsence), Validators.required),
      backup: new FormControl({value: this.currentInterlocutor.backup ? this.currentInterlocutor.backup.id : undefined, disabled: !this.isPhysical()}),
      telBackup: new FormControl({
        value: this.currentInterlocutor.telBackup,
        disabled: this.isPhysical()
      }, Validators.pattern(telFormat)),
      physical: new FormControl(this.isPhysical()),
      ownStructure: new FormControl(this.backupInHisOwnStructure()),
    });

    if(this.hasAbsence()) {
      this.absenceForm.get('startDate').disable();
    }

  }

  hasAbsence() {
    return this.currentInterlocutor.debutAbsence && this.currentInterlocutor.finAbsence;
  }

  initInterlocutorList() {
    this.interlocutorService.getAllInterlocutorsSimple().then((interlocutors: any) => {
      this.allInterlocutors = interlocutors.filter( interlocutor => {
        return interlocutor.id !== this.currentInterlocutor.id;
      });
      this.interlocutorsByStructure = this.filterInterlocutorsByCurrentStructure();

      if (this.backupInHisOwnStructure()) {
        this.interlocutorsList = this.interlocutorsByStructure;
      } else {
        this.interlocutorsList = this.allInterlocutors;
      }
    });
  }

  onChangeOwnStructureCheckbox(event) {
    const isChecked = event.target.checked;

    this.absenceForm.patchValue({
      backup: null
    });

    if (!isChecked) {
      this.interlocutorsList = this.allInterlocutors;
    } else {
      this.interlocutorsList = this.interlocutorsByStructure;
    }
  }

  onChangeRadioButton() {
    if (this.absenceForm.get("physical").value) {
      this.absenceForm.patchValue({
        telBackup: null
      });
      this.absenceForm.get("ownStructure").enable();
      this.absenceForm.get("backup").enable();
      this.absenceForm.get("telBackup").disable();
    } else {
      this.absenceForm.patchValue({
        backup: null
      });
      this.absenceForm.get("telBackup").enable();
      this.absenceForm.get("backup").disable();
      this.absenceForm.get("ownStructure").disable();
    }
  }

  filterInterlocutorsByCurrentStructure() {

    let currentStructure = this.currentInterlocutor.structure.id;

    return this.allInterlocutors.filter(interlocutor => {
      return interlocutor.structure === currentStructure;
    });

  }

  isAbsent() {
    if (this.hasAbsence()) {
      return moment().isBetween(moment(this.currentInterlocutor.debutAbsence, dateFormat), moment(this.currentInterlocutor.finAbsence, dateFormat));
    } else {
      return false;
    }
  }

  /**
   * Convert Date from ngbDatePicker in Moment Date
   * @param date
   */
  convertAnyFormDateToDate(date): any {
    let dateconv;
    if (date) {
      dateconv = moment(
        moment(`${date.day}-${date.month}-${date.year}`, dateFormat),
        dateFormat,
        true
      );
    }
    return dateconv;
  }

  /**
   * Convert Date from Moment Date in ngbDatePicker format
   * @param date
   */
  convertAnyDateToFormDate(date) {

    if (date) {
      let formDate = {
        year: undefined,
        month: undefined,
        day: undefined
      };

      let momentDate = moment(date, dateFormat);

      formDate.day = +momentDate.format("D");
      formDate.month = +momentDate.format("M");
      formDate.year = +momentDate.format("YYYY");

      return formDate;
    }
    return date;
  }

  /**
   * Check if a backup is present, if it's the case :
   * return true if he is in the same structure
   * false otherwise.
   */
  backupInHisOwnStructure() {
    if (this.currentInterlocutor.backup) {

      let backupStructureId = this.currentInterlocutor.backup.structure.id;

      if (!backupStructureId) {
        backupStructureId = this.currentInterlocutor.backup.structure;
      }

      return backupStructureId === this.currentInterlocutor.structure.id;
    } else {
      return this.isPhysical();
    }
  }

  /**
   * Check if the backup is physical or if it's just a phone number.
   */
  isPhysical() {
    return !this.currentInterlocutor.telBackup;
  }

  /**
   * Check if the EndDate is after the StartDate
   */
  isAfter(): boolean {
    if (this.absenceForm) {
      const valenddate = this.absenceForm.get('endDate').value;
      const valstartdate = this.absenceForm.get('startDate').value;

      if (valenddate && valstartdate) {
        return (
          this.convertAnyFormDateToDate(valstartdate) <
          this.convertAnyFormDateToDate(valenddate)
        );
      }
    }

    return true;
  }

  /**
   * Check if the form is Valid and if it could be submit
   */
  isValidForm() {
    let isValid = false;

    if (this.absenceForm.get("endDate").value) {
      isValid = true;
    }

    if (isValid) {

      if (this.absenceForm.get("startDate").value) {
        isValid = this.isAfter();
      }

      if (isValid && this.absenceForm.get("physical").value && !this.absenceForm.get("backup").value) {
        isValid = false;
      }

      if (isValid && !this.absenceForm.get("physical").value
        && (!this.absenceForm.get("telBackup").value || this.absenceForm.get("telBackup").invalid)) {
        isValid = false;
      }
    }

    return isValid;

  }

  onClickOnModification() {
    this.activeButtons(false);
    this.initAbsenceForm();
    this.modifyingAbsence = true;
  }

  cancelModification() {
    this.activeButtons(true);
    this.absenceForm.reset();
    this.modifyingAbsence = false;
  }

  setActivityAreasSelect() {
    // On inclue la filière dans la liste déroulante, si elle n'est pas dans activityAreasToDisplay
    this.activityAreasSelect = this.activityAreas.filter(activityArea => {
      return !this.activityAreasToDisplay
        .map(item => item.id)
        .includes(activityArea.id);
    });
  }

  setSpecialitiesSelect() {
    // On inclue le domaine de compétences dans la liste déroulante, s'il n'est pas dans specialitiesToDisplay
    this.specialitiesSelect = this.specialities.filter(speciality => {
      return !this.specialitiesToDisplay
        .map(item => item.id)
        .includes(speciality.id);
    });
  }

  setTerritoriesSelect() {
    // On inclue le territoire dans la liste déroulante, s'il n'est pas dans territoriesToDisplay
    this.territoriesSelect = this.territories.filter(territory => {
      return !this.territoriesToDisplay
        .map(item => item.id)
        .includes(territory.id);
    });
  }

  onClickModifyIdentityButton() {
    const profileUrl = this.gdi.getAccountUrl();
    window.location.href = profileUrl;
  }

  onClickModifyOccupationButton() {
    this.activeButtons(false);
    this.sectionsState.occupation = this.STATES.MODIFY;
  }

  onClickCancelOccupationModification() {
    this.sectionsState.occupation = this.STATES.READ;
    this.activeButtons(true);
  }

  updateOccupation() {
    this.interlocutorService
      .put({
        ...this.prepareInterlocutorForSave(),
        occupation: { id: this.selectedOccupationId }
      })
      .then(() => {
        // Rafraichir les données dans la vue
        this.loadInterlocutorData();
        // Pour réinitialiser l'état des sections
        this.onClickCancelOccupationModification();
        this.toaster.success(
          this.ucfirst.transform(
            this.translate.instant(
              'my-profile.toast-messages.modify-occupation-ok'
            )
          )
        );
      });
    this.isModifyOccupationButtonActive = true;
  }

  onClickModifyContactButton() {
    this.sectionsState.contact = this.STATES.MODIFY;
    this.activeButtons(false);
  }

  onClickCancelContactModification() {
    this.sectionsState.contact = this.STATES.READ;
    this.skypeInputValue = this.currentInterlocutor.skype;
    this.linkedinInputValue = this.currentInterlocutor.linkedin;
    this.activeButtons(true);
  }

  updateContactLinks() {
    this.interlocutorService
      .put({
        ...this.prepareInterlocutorForSave(),
        skype: this.skypeInputValue.trim(),
        linkedin: this.linkedinInputValue.trim()
      })
      .then(() => {
        // Rafraichir les données dans la vue
        this.loadInterlocutorData();
        // Pour réinitialiser l'état des sections
        this.onClickCancelContactModification();
        this.toaster.success(
          this.ucfirst.transform(
            this.translate.instant(
              'my-profile.toast-messages.modify-contact-ok'
            )
          )
        );
      });
    this.isModifyContactButtonActive = true;
  }

  async loadInterlocutorData() {
    await this.interlocutorService
      .getById(this.currentAgent.interlocutorid)
      .then(interlocutor => {
        this.currentInterlocutor = interlocutor;
        this.selectedOccupationId = this.currentInterlocutor.occupation
          ? this.currentInterlocutor.occupation.id
          : undefined;
        this.skypeInputValue = this.currentInterlocutor.skype
          ? this.currentInterlocutor.skype
          : '';
        this.linkedinInputValue = this.currentInterlocutor.linkedin
          ? this.currentInterlocutor.linkedin
          : '';
        this.activityAreasToDisplay = this.currentInterlocutor.activityareas;
        this.specialitiesToDisplay = this.currentInterlocutor.specialities;
        this.setFilterToDisplay();
        this.agentAvatarUrl = this.currentInterlocutor.avatar
          ? this.agentAvatarService.getFileUrl(this.currentInterlocutor.avatar)
          : defaultAvatarUrl;

        this.initInterlocutorList();
        this.initAbsenceForm();
        this.setupAbsencePeriode(this.currentInterlocutor);
      });
  }

  setFilterToDisplay() {
    const territoriesWithDuplicates = this.currentInterlocutor.territoriesreferent.concat(
      this.currentInterlocutor.territoriesexpert
    );
    // suppression des doublons
    const cache = {};
    this.territoriesToDisplay = territoriesWithDuplicates.filter(function(
      elem
    ) {
      return cache[elem.id] ? 0 : (cache[elem.id] = 1);
    });
  }

  onClickModifyTerritory() {
    this.sectionsState.territory = this.STATES.MODIFY;
    this.activeButtons(false);
  }

  onClickCancelTerritoriesModification() {
    this.sectionsState.territory = this.STATES.READ;
    this.mustSaveBeforeChangingSection = false;
    if (this.isExpert) {
      this.territoriesToDisplay = this.currentInterlocutor.territoriesexpert;
    } else if (this.isReferent) {
      this.territoriesToDisplay = this.currentInterlocutor.territoriesreferent;
    } else if (this.isSpecialist) {
      this.territoriesToDisplay = this.currentInterlocutor.territoriesspecialist;
    }
    this.activeButtons(true);
  }

  onAddTerritory(territory) {
    this.territoriesToDisplay = [...this.territoriesToDisplay, territory];
    this.territoriesSelect = this.territoriesSelect.filter(
      currentTerritory => currentTerritory.id !== territory.id
    );
    this.setTerritoriesSelect();
    this.mustSaveBeforeChangingSection = true;
  }

  onRemoveTerritory(territory) {
    this.territoriesToDisplay = this.territoriesToDisplay.filter(
      currentTerritory => currentTerritory.id !== territory.id
    );
    this.mustSaveBeforeChangingSection = true;
    this.setTerritoriesSelect();
  }

  saveTerritories() {
    const objectToSave = this.prepareInterlocutorForSave()
    // En théorie pour l'instant un agent ne peut être a la fois expert et référent (13/09/2019)
    if (this.isReferent) {
      objectToSave.territoriesreferent = this.territoriesToDisplay;
    } else if (this.isExpert) {
      objectToSave.territoriesexpert = this.territoriesToDisplay;
    } else if (this.isSpecialist) {
      objectToSave.territoriesspecialist = this.territoriesToDisplay;
    }
    this.interlocutorService.put(objectToSave).then(() => {
      this.loadInterlocutorData();
      this.mustSaveBeforeChangingSection = false;
      this.sectionsState.territory = this.STATES.READ;
      this.toaster.success(
        this.ucfirst.transform(
          this.translate.instant(
            'my-profile.toast-messages.modify-territories-ok'
          )
        )
      );
    });
    this.activeButtons(true);
  }

  onLeaveAboutMeSection(nextSection: string) {
    if (this.mustSaveBeforeChangingSection) {
      this.toaster.warning(
        this.ucfirst.transform(
          this.translate.instant('my-profile.save-before-change')
        )
      );
    } else {
      this.aboutMeSectionDisplayed = nextSection;
    }
  }

  updateAgentAvatar(fileEvent) {
    const oldAvatar = this.currentInterlocutor.avatar;
    const target = fileEvent.target || fileEvent.srcElement;
    const avatar = target.files[0];

    if (avatar.size < this.maxFileSize) {
      this.fileTooLarge = false;
      this.spinner.show();
      // Upload de l'avatar sur le serveur
      this.agentAvatarService
        .upload(avatar)
        .then((uploadedAvatar: any) => {
          this.spinner.hide();
          // Liaison avec l'agent connecté
          this.interlocutorService
            .put({...this.prepareInterlocutorForSave(), avatar: uploadedAvatar})
            .then(agent => {
              // Rafraichir la vue
              this.loadInterlocutorData();
              this.headerService.avatar = uploadedAvatar.id;
              // Suppression de l'ancien logo pour optimiser l'espace mémoire
              if (oldAvatar) {
                this.agentAvatarService.delete(oldAvatar.id).subscribe(() => {
                });
              }
            });
        })
        .catch(error => {
          this.spinner.hide();
        });
    } else {
      this.fileTooLarge = true;
    }
  }

  activeButtons(state) {
    this.isModifyOccupationButtonActive = state;
    this.isModifyContactButtonActive = state;
    this.isModifyIdentityButtonActive = state;
    this.isModifySpecialityButtonActive = state;
    this.isModifyActivityAreaButtonActive = state;
    this.isModifyTerritoriesButtonActive = state;
    this.isModifyAbsenceButtonActive = state;
  }

  private prepareInterlocutorForSave() {
    return {
      ...this.currentInterlocutor,
      debutAbsence: this.currentInterlocutor.debutAbsence ? moment(this.currentInterlocutor.debutAbsence, dateFormat).format(simpleDateComparisonFormat) : null,
      finAbsence: this.currentInterlocutor.finAbsence ? moment(this.currentInterlocutor.finAbsence, dateFormat).format(simpleDateComparisonFormat) : null
    }
  }
}
