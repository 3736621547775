import { Injectable, Injector } from '@angular/core';
import { FileService } from './file.service';
import { endpoints } from '../../constants';

@Injectable()
export class AgentAvatarService extends FileService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.agentAvatar;
    this.authorizedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  }
}
