<div *ngIf="companyList?.length > 0">
  <div *ngFor="let company of companyList | paginate: configPagination">
    <hub-company-card *ngIf="!isLoadingAgentStructure" [agent_structure]="agent_structure" [agent_interlocutor]="agent_interlocutor"
                      [entity]="company"
                      (clickPageButton)="onClickPageButton($event)"></hub-company-card>
  </div>
</div>
<p class="hub-search-no-results" *ngIf="companyList?.length === 0 && hasSearchedOnce">
  {{ 'search.no-result' | translate | ucfirst }}
</p>
