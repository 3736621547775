import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';
import {EmailParams} from '../../models/email-params.model';

@Injectable()
export class MailService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.mail;
  }

  sendMailForType(type, emailParams : EmailParams) {
    return this.api.post(`${this.endpoint}/${type}`, { ...emailParams });
  }
}
