import {Injectable, Injector} from '@angular/core';

import {endpoints} from '../../constants';
import {AbstractService} from './abstract.service';
import {SearchArray} from 'src/app/components/search-card/dependencies/search-array';

@Injectable()
export class CompanyService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.company;
  }

  private externalEndpoint = 'api-entreprise';

  /**
   * This method retrieves company data from the government API thanks to a SIRET number
   * @see https://doc.entreprise.api.gouv.fr/#etablissements
   *
   * @param siret siret
   */
  getOneCompanyBySiret(siret: string) {
    return this.api.get(`${this.externalEndpoint}/etablissements/${siret}`);
  }

  /**
   * This method retrieves company data from the government API thanks to a SIREN number
   * @see https://doc.entreprise.api.gouv.fr/#entreprises
   *
   * @param siren siren
   */
  getOneCompanyBySiren(siren: string) {
    return this.api.get(`${this.externalEndpoint}/entreprises/${siren}`);
  }

  /**
   * Retourne les informations des trois derniers chiffres d'affaires (DGFIP)
   *
   * @param siret siret
   */
  getOneExerciceBySiret(siret: string) {
    return this.api.get(`${this.externalEndpoint}/exercices/${siret}`);
  }

  /**
   * Retourne tous les mandataires sociaux
   *
   * @param siren siren
   */
  getMandataireSociaux(siren: string) {
    return this.api.get(`${this.externalEndpoint}/mandataires_sociaux/${siren}`);
  }

  /**
   * Retourne tous les extraits RCS
   *
   * @param siren siren
   */
  getExtraitsRCS(siren: string) {
    return this.api.get(`${this.externalEndpoint}/extrait_rcs/${siren}`);
  }

  /**
   * Retourne le numéro de TVA Intracommunautaire
   *
   * @param siren siren
   */
  getTVAIntracommunautaire(siren: string) {
    return this.api.get(`${this.externalEndpoint}/tva_intracommunautaire/${siren}`);
  }

  /**
   * Retourne le SIREN d'une entreprise en fonction de son SIRET.
   * Il faut prendre les 9 premiers chiffres du SIRET pour avoir le SIREN.
   *
   * @param SIRET siret
   */
  getSIRENFromSIRET(SIRET: string) {
    return SIRET.substring(0, 9);
  }

  /**
   * Récupère et renvoie la liste des filières (activity_area) pour l'entreprise en params dans la BD
   *
   * @param idEntity id de l'entity dans la base de données
   */
  getActivityAreasForAnUser(idEntity) {
    const listActivityAreas = [];
    return this.getOne(idEntity).then((company: any) => {
      // Recupère la liste des filières d'un utilisateur
      company.activityareas.forEach(activityArea => {
        listActivityAreas.push(activityArea.label);
      });

      return listActivityAreas;
    });
  }

  searchCompaniesAndProjects(searchArray: SearchArray, type, pageNumber, numberOfElements) {
    return this.api.get(endpoints.companySearch, {...searchArray, type, pageNumber, numberOfElements});
  }

  getAllProjects(company) {
    return this.api.get(this.endpoint + '/' + company + '/projets', null);
  }

  getMembres(companyId) {
    return this.api.get(`${this.endpoint}/${companyId}/membres`);
  }
}
