import { Injectable } from '@angular/core';
import {
  NgbDateStruct,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { dateFormat } from '../constants';

@Injectable()
export class DateParserService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date) {
      const dateObj = moment(
        `${date.day}/${date.month}/${date.year}`,
        dateFormat
      );
      return dateObj.isValid() ? dateObj.format(dateFormat) : '';
    }

    return '';
  }
}
