import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { encryptor } from '../constants';

@Injectable()
export class EncryptorService {
  private passphrase: string;

  constructor() {
    this.passphrase = encryptor.passphrase;
  }

  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.passphrase);
  }

  decrypt(data) {
    const bytes = CryptoJS.AES.decrypt(data.toString(), this.passphrase);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}
