<div class="hub-entity-logo">
  <div *ngIf="!entity.logo">
    <i *ngIf="!isProjectHolder" class="fa-4x fa fa-building"></i>
    <i *ngIf="isProjectHolder" class="fa-4x fa fa-rocket"></i>
  </div>
  <div *ngIf="entity.logo" class="company-logo">
    <img class="hub-company-img" [src]="this.logoUrl" [style.max-width]="'125px'" [style.max-height]="'125px'"
      [title]="entity.name" [alt]="'Logo ' + entity.name" />
  </div>
</div>
