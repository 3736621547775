import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {StructureService} from 'src/app/services/hub-api/structure.service';
import {TerritoryService} from 'src/app/services/hub-api/territory.service';
import {ActivityAreaService} from 'src/app/services/hub-api/activityArea.service';
import {SearchArray} from './dependencies/search-array';
import {departments, nameTabSearchPage, searchDirectoryInputs} from 'src/app/constants';
import {SortService} from '../../services/sort.service';
import {SearchService} from 'src/app/services/search.service';
import {SearchDirectoryArray} from "./dependencies/search-directory-array";
import {PlanService} from "../../services/hub-api/plan.service";
import {SpecialityService} from "../../services/hub-api/speciality.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-search',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchCardComponent implements OnInit {
  @Input() defaultSearchValues: SearchArray = {
    query: '',
    identity: '',
    identifier: '',
    interlocutor: '',
    structure: null,
    territory: null,
    department: null,
    libelle: '',
    activityAreas: [],
    plans: [],
    specialities: [],
    structures: [],
    lastname: ''
  };
  @Input() selectedTab = 'contact';
  @Output() search = new EventEmitter<{ type: string; search: SearchArray | SearchDirectoryArray }>();
  @Output() tab = new EventEmitter<any>();

  structures: Array<any> = [];
  financers: Array<any> = [];
  territories: Array<any> = [];
  departments: Array<any> = [];
  plans: Array<any> = [];
  activityAreas: Array<any> = [];
  specialities: Array<any> = [];
  activityArea: any;
  nameTabSearchPage = nameTabSearchPage;

  SimpleFormCompany: FormGroup = new FormGroup({
    identity: new FormControl(this.defaultSearchValues.identity),
    siret: new FormControl(this.defaultSearchValues.identifier),
    interlocutor: new FormControl(this.defaultSearchValues.interlocutor),
    structure: new FormControl(this.defaultSearchValues.structure),
    territory: new FormControl(this.defaultSearchValues.territory),
    department: new FormControl(this.defaultSearchValues.department)
  });

  SimpleFormPlan: FormGroup = new FormGroup({
    libelle: new FormControl(this.defaultSearchValues.libelle),
    financers: new FormControl(this.defaultSearchValues.structures),
    activityAreas: new FormControl(this.defaultSearchValues.activityAreas)
  });

  SimpleFormInterlocutor: FormGroup = new FormGroup({
    lastname: new FormControl(this.defaultSearchValues.lastname),
    structures: new FormControl(this.defaultSearchValues.structures),
    activityAreas: new FormControl(this.defaultSearchValues.activityAreas),
    territories: new FormControl(this.defaultSearchValues.territories),
    specialities: new FormControl(this.defaultSearchValues.specialities),
    plans: new FormControl(this.defaultSearchValues.plans)
  });

  constructor(
    private structureService: StructureService,
    private territoryService: TerritoryService,
    private activityAreaService: ActivityAreaService,
    private sortService: SortService,
    private searchService: SearchService,
    private planService: PlanService,
    private specialityService: SpecialityService
  ) {
  }

  async ngOnInit() {
    if (this.searchService.query != null) {
      switch (this.searchService.query.tab) {
        case nameTabSearchPage.contact:
          this.SimpleFormCompany.patchValue({
            identity: this.searchService.query.identity,
            siret: this.searchService.query.identifier,
            interlocutor: this.searchService.query.interlocutor
          });
          break;
        case nameTabSearchPage.plan:
          this.SimpleFormPlan.patchValue({
            libelle: this.searchService.query.libelle
            // structure: this.searchService.query.structure
          });
          break;
        case nameTabSearchPage.interlocutor:
          this.SimpleFormInterlocutor.patchValue({
            lastname: this.searchService.query.lastname
          });
      }
    }

    this.structureService
      .getAllOrderByNameSimple()
      .then((structures: any) => {
        this.structures = structures;
        this.SimpleFormCompany.patchValue({
          structure: this.structures.find(
            x => this.searchService.query && x.id === this.searchService.query.structure
          )
        });
        if (this.searchService.query && this.searchService.query.structures && this.searchService.query.tab === nameTabSearchPage.plan) {
          this.SimpleFormPlan.patchValue({
            financers: this.structures.filter(structure => {
              return this.searchService.query.structures.includes(structure.id)
            }) || []
          });
        }

        if (this.searchService.query && this.searchService.query.structures && this.searchService.query.tab === nameTabSearchPage.interlocutor) {
          this.SimpleFormInterlocutor.patchValue({
            structures: this.structures.filter(structure => {
              return this.searchService.query && this.searchService.query.structures.includes(structure.id)
            }) || []
          });
        }
      })
      .catch(() => {
      });

    this.structureService
      .getAllFinancersOrderByNameSimple()
      .then((financers: any) => {
        this.financers = financers;
        if (this.searchService.query && this.searchService.query.financersId && this.searchService.query.tab === nameTabSearchPage.plan) {
          this.SimpleFormPlan.patchValue({
            financers: this.financers.filter(financer => {
              return this.searchService.query && this.searchService.query.financersId.includes(financer.id)
            }) || []
          });
        }
      });

    this.territoryService
      .get()
      .then((territories: any) => {
        this.territories = this.sortService.sortByFieldAndOrder(
          territories,
          'label'
        );
        if (this.searchService.query) {
          this.SimpleFormCompany.patchValue({
            territory: this.territories.find(
              x => x.id === this.searchService.query.territory
            )
          });
        }

        if (this.searchService.query && this.searchService.query.territories) {
          this.SimpleFormInterlocutor.patchValue({
            territories: this.territories.filter(territory => {
              return this.searchService.query.territories.includes(territory.id);
            }) || []
          });
        }
      })
      .catch(() => {
      });


    this.activityAreaService
      .get()
      .then((activityAreas: any) => {
        this.activityAreas = this.sortService.sortByFieldAndOrder(
          activityAreas,
          'label'
        );

        if (this.searchService.query && this.searchService.query.activityAreas)
          this.SimpleFormPlan.patchValue({
            activityAreas: this.activityAreas.filter(activityArea =>
              this.searchService.query.activityAreas.includes(activityArea.id)
            )
          });
      })
      .catch(() => {
      });

    this.planService
      .getAll()
      .then((plans: any) => {
        this.plans = plans;
        if (this.searchService.query && this.searchService.query.plans) {
          this.SimpleFormInterlocutor.patchValue({
            plans: this.plans.filter(plan => {
              return this.searchService.query.plans.includes(plan.id);
            }) || []
          });
        }
      });

    this.specialityService
      .getAll()
      .then((specialities: any) => {
        this.specialities = specialities;
        if (this.searchService.query && this.searchService.query.specialities) {
          this.SimpleFormInterlocutor.patchValue({
            specialities: this.specialities.filter(speciality => {
              return this.searchService.query.specialities.includes(speciality.id);
            }) || []
          });
        }
      });

    this.departments = departments;
    if (this.searchService.query && this.searchService.query.department) {
      this.SimpleFormCompany.patchValue({
        departments: this.departments.filter(department => {
          return this.searchService.query.department.includes(department.number);
        }) || []
      });
    }

  }


  clearCompany() {
    this.SimpleFormCompany.reset();
    this.emitSearch();
  }

  clearPlan() {
    this.SimpleFormPlan.reset();
    this.searchPlan();
  }

  clearInterlocutor() {
    this.SimpleFormInterlocutor.reset();
    this.searchInterlocutor();
  }

  changeSelectedTab(tabId: number) {
    switch (tabId) {
      case 0:
        this.selectedTab = nameTabSearchPage.contact;
        break;
      case 1:
        this.selectedTab = nameTabSearchPage.plan;
        break;
      case 2:
        this.selectedTab = nameTabSearchPage.interlocutor;
        break;
      default:
        break;
    }
    this.tab.emit(this.selectedTab);
  }

  emitSearch(): void {
    let structure, identity, identifier, interlocutor, territory, department;
    structure = identity = identifier = interlocutor = territory = department = '';

    if (this.SimpleFormCompany.value.structure) {
      structure = this.SimpleFormCompany.value.structure.id;
    }

    if (this.SimpleFormCompany.value.identity) {
      identity = this.SimpleFormCompany.value.identity;
    }

    if (this.SimpleFormCompany.value.siret) {
      identifier = this.SimpleFormCompany.value.siret;
    }

    if (this.SimpleFormCompany.value.interlocutor) {
      interlocutor = this.SimpleFormCompany.value.interlocutor;
    }

    if (this.SimpleFormCompany.value.territory) {
      territory = this.SimpleFormCompany.value.territory.id;
    }

    if (this.SimpleFormCompany.value.department) {
      department = this.SimpleFormCompany.value.department.number;
    }

    this.search.emit({
      type: this.selectedTab,
      search: <SearchArray>{
        identity,
        identifier,
        interlocutor,
        structure,
        territory,
        department
      }
    });
  }

  searchPlan(): void {
    let libelle = '';
    // let structure = '';
    let activityAreas = [];
    let financers = [];

    // if (this.SimpleFormPlan.value.structure) {
    //   structure = this.SimpleFormPlan.value.structure.id;
    // }

    if (this.SimpleFormPlan.value.libelle) {
      libelle = this.SimpleFormPlan.value.libelle;
    }

    if (this.SimpleFormPlan.value.activityAreas) {
      activityAreas = this.SimpleFormPlan.value.activityAreas.map(
        activityArea => activityArea.id
      );
    }

    if (this.SimpleFormPlan.value.financers) {
      financers = this.SimpleFormPlan.value.financers.map(financer => financer.id);
    }

    this.search.emit({
      type: this.selectedTab,
      search: <SearchArray>{
        libelle,
        // structure,
        activityAreas: activityAreas,
        financersId: financers
      }
    });
  }

  searchInterlocutor() {
    let lastname = '';
    let structures = [];
    let activityAreas = [];
    let territories = [];
    let specialities = [];
    let plans = [];

    if (this.SimpleFormInterlocutor.value.lastname) {
      lastname = this.SimpleFormInterlocutor.value.lastname;
    }

    if (this.SimpleFormInterlocutor.value.structures) {
      structures = this.SimpleFormInterlocutor.value.structures.map(structure => structure.id);
    }

    if (this.SimpleFormInterlocutor.value.activityAreas) {
      activityAreas = this.SimpleFormInterlocutor.value.activityAreas.map(activityArea => activityArea.id);
    }

    if (this.SimpleFormInterlocutor.value.territories) {
      territories = this.SimpleFormInterlocutor.value.territories.map(territory => territory.id);
    }

    if (this.SimpleFormInterlocutor.value.specialities) {
      specialities = this.SimpleFormInterlocutor.value.specialities.map(speciality => speciality.id);
    }

    if (this.SimpleFormInterlocutor.value.plans) {
      plans = this.SimpleFormInterlocutor.value.plans.map(plan => plan.id);
    }

    this.search.emit({
      type: this.selectedTab,
      search: <SearchArray>{
        lastname,
        structures,
        activityAreas,
        territories,
        specialities,
        plans
      }
    });
  }

  selectAll(selectbox: string) {
    const idList = [];
    switch (selectbox) {
      case searchDirectoryInputs.territories:
        this.territories.forEach(item => {
          idList.push(item);
        });
        break;
      case searchDirectoryInputs.specialities:
        this.specialities.forEach(item => {
          idList.push(item);
        });
        break;
      case searchDirectoryInputs.activityAreas:
        this.activityAreas.forEach(item => {
          idList.push(item);
        });
        break;
      case searchDirectoryInputs.structures:
        this.structures.forEach(item => {
          idList.push(item);
        });
        break;
      case searchDirectoryInputs.plans:
        this.plans.forEach(item => {
          idList.push(item);
        });
        break;
      default:
    }

    this.SimpleFormInterlocutor.get(selectbox).setValue(idList);
  }

  filterTerritories(term: string, territory: any) {
    const fullLabel = `(${territory.label}) ${territory.departments}`;
    return fullLabel.toUpperCase().includes(term.toUpperCase());
  }


  /**
   * Return true si au moins un champs est remplie, false sinon
   */
  isValidFormCompany() {
    // On test '' et null pour les input simple car au début ils sont a '' puis a null lors du vidage de la recherche
    // On test undefined et null pour les select car au début ils sont à undefined puis a null lors du vidage de la recherche
    return (
      (this.SimpleFormCompany.get('identity').value !== '' &&
        this.SimpleFormCompany.get('identity').value !== null) ||
      (this.SimpleFormCompany.get('siret').value !== '' &&
        this.SimpleFormCompany.get('siret').value !== null) ||
      (this.SimpleFormCompany.get('interlocutor').value !== '' &&
        this.SimpleFormCompany.get('interlocutor').value !== null) ||
      (this.SimpleFormCompany.get('structure').value !== undefined &&
        this.SimpleFormCompany.get('structure').value !== null) ||
      (this.SimpleFormCompany.get('territory').value !== undefined &&
        this.SimpleFormCompany.get('territory').value !== null) ||
      (this.SimpleFormCompany.get('department').value !== undefined &&
        this.SimpleFormCompany.get('department').value !== null)
    );
  }

  /**
   * Return true si au moins un champs est remplie, false sinon
   */
  isValidFormPlan() {
    // On test '' et null pour les input simple car au début ils sont a '' puis a null lors du vidage de la recherche
    // On test undefined et null pour les select car au début ils sont à undefined puis a null lors du vidage de la recherche
    return (
      (this.SimpleFormPlan.get('libelle').value !== '' &&
        this.SimpleFormPlan.get('libelle').value !== null) ||
      ((this.SimpleFormPlan.get('financers').value !== null &&
        this.SimpleFormPlan.get('financers').value !== undefined &&
        this.SimpleFormPlan.get('financers').value.length > 0) ||
        (this.SimpleFormPlan.get('activityAreas').value !== undefined &&
          this.SimpleFormPlan.get('activityAreas').value !== null &&
          this.SimpleFormPlan.get('activityAreas').value.length > 0))
    );
  }

  isValidFormInterlocutor() {
    return (
      (this.SimpleFormInterlocutor.get(searchDirectoryInputs.lastname).value &&
        this.SimpleFormInterlocutor.get(searchDirectoryInputs.lastname).value.trim() !==
        '' &&
        this.SimpleFormInterlocutor.get(searchDirectoryInputs.lastname).value.trim() !==
        null) ||
      (this.SimpleFormInterlocutor.get(searchDirectoryInputs.territories).value !== null &&
        this.SimpleFormInterlocutor.get(searchDirectoryInputs.territories).value.length) ||
      (this.SimpleFormInterlocutor.get(searchDirectoryInputs.structures).value !== null &&
        this.SimpleFormInterlocutor.get(searchDirectoryInputs.structures).value.length) ||
      (this.SimpleFormInterlocutor.get(searchDirectoryInputs.specialities).value !== null &&
        this.SimpleFormInterlocutor.get(searchDirectoryInputs.specialities).value.length) ||
      (this.SimpleFormInterlocutor.get(searchDirectoryInputs.plans).value !== null &&
        this.SimpleFormInterlocutor.get(searchDirectoryInputs.plans).value.length) ||
      (this.SimpleFormInterlocutor.get(searchDirectoryInputs.activityAreas).value !== null &&
        this.SimpleFormInterlocutor.get(searchDirectoryInputs.activityAreas).value.length)
    );
  }

}
