<div class="hub-sidenav-backdrop" *ngIf="(isOpen | async)" (click)="closeNav()" [@pop]></div>
<div class="sidenav" *ngIf="(isOpen | async)" [@slideFromRight]>
  <div class="menu-items">
    <div *ngIf="isLoggedIn" class="menu--my-personnal-space">
      <div class="menu--my-personnal-space---title title">
        {{ 'burger-menu.my-personnal-space' | translate | ucfirst }}
      </div>
      <div class="menu--my-personnal-space---sub-title">
        <ul>
          <li class="nav-item">
            <a routerLink="/" class="nav-link" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.home' | translate | ucfirst }}
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/profil" class="nav-link" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.my-profile' | translate | ucfirst }}
            </a>
          </li>
          <li class="nav-item notification">
            <a routerLink="/mes-notifications" class="nav-link pr-2" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.my-notification' | translate | ucfirst }}
            </a>
            <div *ngIf="numberNotificationsUnread > 0 || numberNotificationsUnread == numberMaxNotifications" class="circle">{{ numberNotificationsUnread }}</div>
          </li>
          <li *ngIf="isReferent" class="nav-item">
            <a routerLink="/mon-portefeuille" class="nav-link" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.my-wallet' | translate | ucfirst }}
            </a>
          </li>
		      <li class="nav-item">
            <a routerLink="/mes-contacts" class="nav-link" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.my-contacts' | translate | ucfirst }}
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/dossiers-projecteurs" class="nav-link" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.dossiers-projecteurs' | translate | ucfirst }}
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/espace-rdeo" class="nav-link" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.espace-rdeo' | translate | ucfirst }}
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/contact-us" class="nav-link" routerLinkActive="active" (click)="closeNav()">
              {{ 'burger-menu.contact-us' | translate | ucfirst }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
