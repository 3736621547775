import { Injectable, Injector } from '@angular/core';
import { endpoints } from '../../constants';
import { SearchArray } from './../../components/search-card/dependencies/search-array';
import { AbstractService } from './abstract.service';

@Injectable()
export class PlanService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.plan;
  }

  search(searchArray: SearchArray) {
    const requestParam = {};
    requestParam["searchString"] = searchArray.libelle;
    if (searchArray.activityAreas && searchArray.activityAreas.length > 0) {
      requestParam["activityareas"] = searchArray.activityAreas;
    }

    if (searchArray.financersId && searchArray.financersId.length > 0) {
      requestParam["operators"] = searchArray.financersId;
    }

    return this.api.get(endpoints.dispositifSearch, requestParam);
  }

  /**
   * Récupère un plan par un slug si fourni, sinon on récupère le slug via l'id
   * @param uuid UUID du plan
   * @param slug le slug du plan
   */
  getOneBySlugOrId(slug: string) {
    return this.api.get(`${endpoints.planBySlug}/${slug}`);
  }
}
