import {AbstractService} from './abstract.service';
import {Injectable, Injector} from '@angular/core';
import {endpoints} from '../../constants';

@Injectable()
export class RdeoVideoService extends AbstractService {

  /**
   * Liste des méthodes de l'AbstractService disponible
   * - getById / getOne : Retourne un objet Rdeo Video en fonction de son identifiant
   * - getAll : Retourne la liste de tous les objets Rdeo Video
   * - post : Envoi un objet Rdeo Video
   * - delete : Supprime un objet Rdeo Video en fonction de l'identifiant
   */
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.rdeoVideo;
  }

  /**
   * Retourne la liste de tous les dto Rdeo Video
   * @return liste de dto Rdeo Video
   */
  getAllDto() {
    return this.api.get(`${this.endpoint}/dto`);
  }

  /**
   * Retourne un dto Rdeo Video en fonction de l'identifiant
   *
   * @param id Identifiant du dto Rdeo Video
   * @return dto Rdeo Video
   */
  getByIdDto(id: string) {
    return this.api.get(`${this.endpoint}/dto/${id}`);
  }

  /**
   * Change l'odre entre deux objet Rdeo Video
   *
   * @param id1 Identifiant Rdeo Video
   * @param id2 Identifiant Rdeo Video
   * @return liste de Rdeo Video
   */
  transpose(id1, id2) {
    return this.api.put(`${this.endpoint}/transpose/${id1}/${id2}`);
  }
}
