import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {SessionService} from '../../services/session.service';
import {AgentService} from '../../services/hub-api/agent.service';
import {MailService} from '../../services/hub-api/mail.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confirmation-charte-qualite-modal',
  templateUrl: './confirmation-charte-qualite-modal.component.html',
  styleUrls: ['./confirmation-charte-qualite-modal.component.scss']
})
export class ConfirmationCharteQualiteModalComponent implements OnInit {

  constructor(private bsModalRef: BsModalRef,
              private router: Router) {}

  ngOnInit() {}

  onPopinClosure() {
    this.goToHomeAndClosePopin();
  }

  goToHomeAndClosePopin() {
    this.router.navigate(['/']);
    this.bsModalRef.hide();
  }

}
