import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {ConfirmationCharteQualiteModalComponent} from '../../components/confirmation-charte-qualite-modal/confirmation-charte-qualite-modal.component';
import {SessionService} from '../../services/session.service';
import {AgentService} from '../../services/hub-api/agent.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {UpperCaseFirstPipe} from '../../pipes/ucfirst.pipe';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'hub-charte-qualite',
  templateUrl: './charte-qualite.component.html',
  styleUrls: ['./charte-qualite.component.scss']
})
export class CharteQualiteComponent implements OnInit, AfterViewInit {

  screenWidth: number;
  widthBlur: number;
  marginBottomCharteText: number;
  marginTopCharteText: number;

  hideBlur = false;
  validationButtonIsShown = false;

  activityAreasModalRef: BsModalRef;

  @ViewChild('charteQualite')
  charteQualite: ElementRef;

  @ViewChild('blurContent')
  blurContent: ElementRef;

  @ViewChild('titleContent')
  titleContent: ElementRef;

  constructor(@Inject(DOCUMENT) document,
              private cdRef: ChangeDetectorRef,
              private modalService: BsModalService,
              private sessionService: SessionService,
              private agentService: AgentService,
              private spinner: NgxSpinnerService,
              protected toastr: ToastrService,
              private ucfirst: UpperCaseFirstPipe,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height - blur height
    if ((event.target.offsetHeight + event.target.scrollTop) >= event.target.scrollHeight - this.blurContent.nativeElement.offsetHeight) {
      this.hideBlur = true;
      this.validationButtonIsShown = true;
      this.marginBottomCharteText = 0;
      this.cdRef.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeBlurEffect();
    this.resizeMarginCharteCharteText();
  }

  ngAfterViewInit() {
    this.resizeBlurEffect();
    this.resizeMarginCharteCharteText();
  }

  resizeBlurEffect() {
    if (this.charteQualite) {
      let scrollbarWidth = this.getScrollbarWidth();
      this.widthBlur = this.charteQualite.nativeElement.offsetWidth - scrollbarWidth;
      this.cdRef.detectChanges();
    }
  }

  resizeMarginCharteCharteText() {
    if (this.titleContent) {
      this.marginTopCharteText = this.titleContent.nativeElement.offsetHeight + 30;
      this.cdRef.detectChanges();
    }
    if (this.blurContent) {
      this.marginBottomCharteText = this.blurContent.nativeElement.offsetHeight;
      this.cdRef.detectChanges();
    }
  }

  getScrollbarWidth() {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  openCharteQualitePopin() {
    this.spinner.show('global');
    this.sessionService
      .getAgent()
      .then((agent : any) => {
        if (agent) {
          this.agentService
            .validateCharte(agent.id)
            .then((agentUpdated) => {
            agent.isCharteApproved = agentUpdated.isCharteApproved;
            agent.charteApprovalDate = agentUpdated.charteApprovalDate;
            this.sessionService.setAgent(agent);

            this.spinner.hide('global');
            const config: ModalOptions = {
                initialState: {
                },
                animated: true,
                backdrop: 'static',
                class: 'hub-modal-confirmation-charte',
            };
            this.activityAreasModalRef = this.modalService.show(
              ConfirmationCharteQualiteModalComponent,
              config
            );
          }).catch(() => {
            this.spinner.hide('global');
            this.toastr.error(this.ucfirst.transform(this.translateService.instant('charte-qualite.toastr.nok')));
          });
        }
      })
  }

}
