import {FileService} from './file.service';
import {Injectable, Injector} from '@angular/core';
import {endpoints} from '../../constants';

@Injectable()
export class RdeoDocumentFileService extends FileService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.rdeoDocumentFile;
  }
}
