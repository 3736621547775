import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SliderMenuService {
  private _status: BehaviorSubject<boolean>;

  constructor() {
    this._status = new BehaviorSubject<boolean>(false);
  }

  get status(): BehaviorSubject<boolean> {
    return this._status;
  }

  show() {
    this.status.next(true);
  }

  hide() {
    this.status.next(false);
  }

  toggle() {
    this.status.next(!this.status.getValue());
  }
}
