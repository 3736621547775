import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environmentLoader } from 'src/environments/environmentLoader';

import { ApiService } from './services/hub-api/api.service';
import { RoutingStateService } from './services/routing-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hub-entreprise-front-agent';
  isHomePage = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private api: ApiService,
    private routingStateService: RoutingStateService
  ) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    this.routingStateService.loadRouting();
  }

  async loadEnvironment() {
    await environmentLoader.then(env => {
      this.api.hubApiHost = env.settings.hubApi.host;
    });
  }

  async ngOnInit() {
    this.loadEnvironment();
    this.router.events.subscribe(
      () => (this.isHomePage = this.router.url === '/')
    );
  }
}
