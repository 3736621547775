import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';

@Injectable()
export class NotificationService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.notification;
  }

  getNotificationsByInterlocutorAndType(
    interlocutorId: string,
    typeOfNotification: string
  ) {
    return this.api.get(
      `${this.endpoint}/interlocuteur/${interlocutorId}/type/${typeOfNotification}`
    );
  }

  getNotificationsByInterlocutor(interlocutorId: string) {
    return this.api.get(`${this.endpoint}/interlocuteur/${interlocutorId}`);
  }

  /**
   * Retourne le nombre de notification non lues pour un interlocutor ID
   * @param interlocutorId L'interlocutor en question
   */
  getNumberUnreadNotifications(interlocutorId: string) {
    return this.api.get(
      `${this.endpoint}/interlocuteur/${interlocutorId}/count/unread`
    );
  }
}
