import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {
  refuseReferentInputs,
  refuseReferentObjectItems
} from '../../constants';
import {UpperCaseFirstPipe} from '../../pipes/ucfirst.pipe';
import {StructureService} from '../../services/hub-api/structure.service';
import {InterlocutorService} from '../../services/hub-api/interlocutor.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {MailService} from '../../services/hub-api/mail.service';
import {SessionService} from '../../services/session.service';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../services/hub-api/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {CompanyService} from '../../services/hub-api/company.service';
import {EmailParams} from '../../models/email-params.model';
import {TYPE_MAIL_ENUM} from '../../models/enum/type-mail.enum';

@Component({
  selector: 'refuse-referent-modal',
  template: `
    <div class="hub-modal-refuse-referent">
      <div class="modal-header hub-modal-header top">
        <h4>{{ 'notification.refuse-referent-modal.title' | translate | ucfirst }}</h4>
        <button type="button" class="close refuseReferent" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body hub-modal-body bottom">
        <form (ngSubmit)="onSubmit()" [formGroup]="form" #refuseReferentForm="ngForm">
          <div class="form-group">
            <label for="object"
                   class="font-weight-bold">{{ 'notification.refuse-referent-modal.object' | translate | ucfirst }}</label>
            <ng-select [items]="objectItems" [multiple]="false" [closeOnSelect]="true" [hideSelected]="true"
                       [searchable]="false" bindLabel="label" bindValue="label"
                       placeholder="{{ 'notification.refuse-referent-modal.seletAnObject' | translate | ucfirst }}"
                       formControlName="object" required>
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.label }}">{{ item.label }}</div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <div title="{{ item.label }}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
            <div *ngIf="this.form.get('object').invalid && form.get('object').touched" class="alert alert-danger">
              {{ 'required-field' | translate | ucfirst }}
            </div>
          </div>

          <div class="offset-1 form-group other-object" *ngIf="showOtherObjectInput()">
            <label for="otherObject"
                   class="font-italic col-md-3">{{ 'notification.refuse-referent-modal.which' | translate | ucfirst }}</label>
            <input type="text" class="form-control cold-md-9" formControlName="otherObject" />
          </div>

          <div class="form-group">
            <label for="motif"
                   class="font-weight-bold">{{ 'notification.refuse-referent-modal.motif' | translate | ucfirst }}</label>

            <textarea row=3 type="text" class="form-control" formControlName="motif" required></textarea>
            <em class="max-length-motif font-italic">{{ 'notification.refuse-referent-modal.maxChar' | translate }}</em>
            <div *ngIf="form.get('motif').invalid && form.get('motif').touched" class="alert alert-danger">
              <div *ngIf="form.get('motif').hasError('maxlength')">
                {{ 'notification.refuse-referent-modal.max-length-motif' | translate | ucfirst }}
              </div>
              <div *ngIf="!form.get('motif').hasError('maxlength')">
                {{ 'required-field' | translate | ucfirst }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <div class="bold">{{ 'notification.refuse-referent-modal.structures' | translate | ucfirst }} *</div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <input type="radio" class="pointer" formControlName="showStructure"
                           [value]="1" (change)="onChangeShowStructure()">
                    <span> {{ 'notification.refuse-referent-modal.yes' | translate | ucfirst }} </span>
                  </div>
                  <div class="col-6">
                    <input type="radio" class="pointer" formControlName="showStructure"
                           [value]="2" (change)="onChangeShowStructure()">
                    <span> {{ 'notification.refuse-referent-modal.no' | translate | ucfirst }} </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12" *ngIf="showStructuresField">
                    <div *ngIf="structuresLoading; else selectStructures">
                      <div class="loader">
                        <ngx-spinner name="structureSpinner" [fullScreen]="false" bdColor="rgb(255, 255, 255)" color="rgb(0, 0, 0)" type="ball-spin-clockwise" size="small"></ngx-spinner>
                      </div>
                    </div>

                    <ng-template #selectStructures>
                      <ng-select [items]="allStructures" [multiple]="true" [closeOnSelect]="true"
                                 [virtualScroll]="true"
                                 [hideSelected]="true"
                                 bindLabel="name"
                                 bindValue="id" placeholder="Nom de la structure" formControlName="structures"
                                 (change)="changeSelectableInterlocutor()"
                                 clearAllText="{{ 'form.none' | translate | ucfirst }}"
                                 notFoundText="{{ 'notification.refuse-referent-modal.no-structure' | translate | ucfirst }}">
                        <ng-template ng-option-tmp let-item="item">
                          <div title="{{ item.name }}">{{ item.name }}</div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-icon left" (click)="clear(item)"
                                              aria-hidden="true">×</span>
                          <div title="{{ item.name }}">{{ item.name }}</div>
                        </ng-template>
                      </ng-select>
                    </ng-template>
                  </div>
                </div>

              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 bold">{{ 'notification.refuse-referent-modal.referent' | translate | ucfirst }} *</div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <input type="radio" class="pointer" formControlName="showInterlocutor"
                           [value]="1" (change)="onChangeShowInterlocutor()">
                    <span> {{ 'notification.refuse-referent-modal.yes' | translate | ucfirst }} </span>
                  </div>
                  <div class="col-6">
                    <input type="radio" class="pointer" formControlName="showInterlocutor"
                           [value]="2" (change)="onChangeShowInterlocutor()">
                    <span> {{ 'notification.refuse-referent-modal.no' | translate | ucfirst }} </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12" *ngIf="showInterlocutorsField">
                    <div *ngIf="interlocutorsLoading; else selectInterlocutor" class="w-50">
                      <div class="loader">
                        <ngx-spinner name="interlocutorSpinner" [fullScreen]="false" bdColor="rgb(255, 255, 255)" color="rgb(0, 0, 0)"  type="ball-spin-clockwise" size="small"></ngx-spinner>
                      </div>
                    </div>
                    <ng-template #selectInterlocutor>
                      <ng-select [items]="selectableInterlocutors" [multiple]="false" [closeOnSelect]="true"
                                 [virtualScroll]="true"
                                 [hideSelected]="true"
                                 bindLabel="lastname"
                                 bindValue="id" placeholder="Prénom NOM" formControlName="referent"
                                 clearAllText="{{ 'form.none' | translate | ucfirst }}"
                                 notFoundText="{{ 'notification.refuse-referent-modal.no-referent' | translate | ucfirst }}">
                        <ng-template ng-option-tmp let-item="item">
                          <div title="{{ item.firstname }} {{ item.lastname }}">{{ item.firstname }} {{ item.lastname }}</div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-icon left" (click)="clear(item)"
                                              aria-hidden="true">×</span>
                          <div title="{{ item.firstname }} {{ item.lastname }}">{{ item.firstname }} {{ item.lastname }}</div>
                        </ng-template>
                      </ng-select>
                    </ng-template>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="form-group buttons">
            <a (click)="closeModal()" class="btn btn-hub btn-cancel">{{
              'notification.refuse-referent-modal.cancel' | translate | ucfirst
              }}</a>
            <button type="submit" class="btn btn-hub btn-save" [disabled]="!isValidFormChangeReferent()">
              {{ 'notification.refuse-referent-modal.send' | translate | ucfirst }}
            </button>
          </div>

          <span
            class="required font-italic">{{ 'notification.refuse-referent-modal.required-field' | translate | ucfirst }}</span>

          <div class="loading text-center" *ngIf="isLoading">
            <app-hub-spinner-dots></app-hub-spinner-dots>
          </div>
        </form>
      </div>
    </div>
  `,
  styleUrls: ['./refuse-referent-modal.component.scss']
})
export class RefuseReferentModalComponent implements OnInit {

  @Input() notification;
  @Input() company;
  @Input() privilegedContact;

  form: FormGroup = new FormGroup({});

  isLoading = false;

  showStructuresField = false;
  structuresLoading = false;
  allStructures;

  objectItems: Array<any> = [];
  showInterlocutorsField = false;
  interlocutorsLoading = false;
  allInterlocutors;
  selectableInterlocutors;

  constructor(public activeModal: NgbActiveModal, private ucfirst: UpperCaseFirstPipe,
              private structureService: StructureService,
              private interlocutorService: InterlocutorService,
              private modalService: BsModalService,
              private mailService: MailService,
              private sessionService: SessionService,
              private toastr: ToastrService,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private spinnerService: NgxSpinnerService,
              private companyService: CompanyService) {}

  ngOnInit(): void {
    refuseReferentInputs.forEach(formField => {
      this.form.addControl(formField, new FormControl());
    });
    this.form.setControl(
      'motif',
      new FormControl('', [Validators.maxLength(250)])
    );

    this.form.setControl('showStructure', new FormControl(0));
    this.form.setControl('showInterlocutor', new FormControl(0));

    // Fill the ng-select refuse referent object with the values
    refuseReferentObjectItems.forEach(item => {
      this.objectItems = [...this.objectItems, {label: item}];
    });
  }

  closeModal() {
    this.activeModal.close('close');
  }

  async onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const userConnected = await this.sessionService.getAgent();
      // Mark notification as read and reject
      this.notificationService
        .put({
          ...this.notification,
          read: true,
          reject: true
        })
        .then(notification => {
          this.notification = notification;
          const valueForm = this.form.value;
          // We try to get information for the company by the api entreprise and construct the mail depend on it
          this.companyService
            .getOneCompanyBySiret(this.company.identifier)
            .then((companyData: any) => {
              this.sendEmail(userConnected, valueForm, companyData.data);
            }).catch(error => this.sendEmail(userConnected, valueForm));

        })
        .catch(() => {
          this.isLoading = false;
          this.activeModal.close('close');
        });
    }
  }

  async sendEmail(userConnected, valueForm, companyData?) {

    const emailParams = new EmailParams();

    emailParams.userLastname = userConnected.lastname.toLocaleUpperCase();
    emailParams.userFirstname = this.ucfirst.transform(userConnected.firstname);

    emailParams.identifier = this.company.identifier;

    emailParams.companyName = this.notification.company.name;
    emailParams.contactName = this.notification.company.contactname;
    // Create body
    let body ='';

    if (this.privilegedContact) {
      emailParams.privilegedContactEmail = this.privilegedContact.email;
      emailParams.privilegedContactPhone = this.privilegedContact.phone;
    }

    emailParams.objectRefus = valueForm.object;
    emailParams.motif = valueForm.motif;

    if (this.showOtherObjectInput()) {
      emailParams.otherObjectRefus = valueForm.otherObject;
    }

    if (this.showInterlocutorsField) {
      let referent = this.selectableInterlocutors.find(interlocutor => interlocutor.id === this.form.get('referent').value);
      emailParams.refererFirstname = referent.firstname;
      emailParams.refererLastname = referent.lastname;
      emailParams.refererStructureName = referent?.structure?.name;
    }

    if (this.showStructuresField) {
      let structuresList = "";
      this.form.get('structures').value.forEach(structureId => {
        let structure = this.allStructures.find(structure => structure.id === structureId);
        structuresList += `<li>${structure.name}</li>`
      });
      emailParams.structuresList = structuresList;
    }

    if (companyData) {
      let departement = null;
      if (companyData?.adresse?.code_postal.length >= 2)   {
        departement = String(companyData.adresse.code_postal).substring(0, 2);
      }
      emailParams.companyDepartment = departement;
      emailParams.companyNAF = companyData.activite_principale.code;
      emailParams.companyLibelleNAF = companyData.activite_principale.libelle;
    }

    // Send mail
    this.mailService
      .sendMailForType(
        TYPE_MAIL_ENUM.refus_referent_entreprise,
        emailParams
      )
      .then(() => {
        // Toastr
        this.toastr.success(
          this.ucfirst.transform(
            this.translateService.instant(
              'notification.refuse-referent-modal.toastr'
            )
          ),
          null,
          {timeOut: 15000, tapToDismiss: false}
        );

        this.isLoading = false;
        this.form.reset();
        this.activeModal.close(this.notification);
      })
      .catch(() => {
        this.isLoading = false;
        this.activeModal.close(this.notification);
      });
  }

  /**
   * If the the first element of the refuse referent popup is equal 'Autres',
   * return true to display the input 'otherObject'
   */
  showOtherObjectInput() {
    return this.form.get('object').value === 'Autres';
  }

  /**
   * If the the first element of the refuse referent popup is equal 'Autres',
   * we check if the 'otherObject' value is set, else just the 'object' & 'motif'
   */
  isValidFormChangeReferent() {
    return (this.showOtherObjectInput()
      ? this.form.valid &&
      this.form.get('object').value &&
      this.form.get('otherObject').value &&
      this.form.get('motif').value
      : this.form.valid &&
      this.form.get('object').value &&
      this.form.get('motif').value) && (this.checkIfReferentIsValid() && this.checkIfStructuresIsValid());
  }

  /**
   * check if structures in refuse referent modal are Valid
   * True if shown and structures not undefined
   * True if not shown and structures are undefined
   * False Otherwise
   */
  checkIfStructuresIsValid() {
    if (this.form.get('showStructure').value !== 0) {
      if (this.form.get('showStructure').value === 1) {
        return this.form.get('structures').value !== undefined;
      } else {
        return this.form.get('structures').value === undefined;
      }
    } else {
      return false;
    }
  }

  /**
   * check if referent in refuse referent modal is Valid
   * True if shown and referent not undefined
   * True if not shown and referent is undefined
   * False Otherwise
   */
  checkIfReferentIsValid() {
    if (this.form.get('showInterlocutor').value !== 0) {
      if (this.form.get('showInterlocutor').value === 1) {
        return this.form.get('referent').value !== undefined;
      } else {
        return this.form.get('referent').value === undefined;
      }
    } else {
      return false;
    }
  }

  onChangeShowInterlocutor() {
    this.showInterlocutorsField = this.form.get('showInterlocutor').value === 1;

    if (this.showInterlocutorsField && !this.allInterlocutors) {
      this.interlocutorsLoading = true;
      this.spinnerService.show('interlocutorSpinner');
      this.interlocutorService.getAllReferents().then(result => {

        this.allInterlocutors = result;

        if (!this.allStructures && !this.structuresLoading) {
          this.structureService.getAllStructureRDEO().then(result => {
            this.allStructures = result;
          });
        }

        this.changeSelectableInterlocutor();
        this.interlocutorsLoading = false;
        this.spinnerService.hide('interlocutorSpinner');
      });
    }

    if (!this.showInterlocutorsField) {
      this.form.controls['referent'].setValue(undefined);
    }
  }

  onChangeShowStructure() {
    this.showStructuresField = this.form.get('showStructure').value === 1;

    if (this.showStructuresField && !this.allStructures) {
      this.structuresLoading = true;
      this.spinnerService.show('structureSpinner');
      this.structureService.getAllStructureRDEO().then(result => {
        this.allStructures = result;
        this.structuresLoading = false;
        this.spinnerService.hide('structureSpinner');
      });
    }

    if (!this.showStructuresField) {
      this.form.controls['structures'].setValue(undefined);
      this.form.controls['referent'].setValue(undefined);
      this.changeSelectableInterlocutor();
    }
  }

  changeSelectableInterlocutor() {
    this.form.controls['referent'].setValue(undefined);

    if (this.form.get('structures').value && this.form.get('structures').value.length !== 0) {
      this.selectableInterlocutors = this.filterInterlocutorListByStructures();
    } else {
      this.selectableInterlocutors = this.allInterlocutors;
    }
  }

  filterInterlocutorListByStructures() {
    if (this.showInterlocutorsField) {
      return this.allInterlocutors.filter(interlocutor => {
        let isShowable = false;
        this.form.get('structures').value.forEach(structureId => {
          if (interlocutor?.structure.id === structureId) {
            isShowable = true;
          }
        });
        return isShowable;
      });
    }
  }

}
