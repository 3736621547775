import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from "@ngx-translate/core";
import {UpperCaseFirstPipe} from "../../pipes/ucfirst.pipe";


@Component({
  selector: 'video-modal',
  styleUrls: ['./modal.component.scss'],
  template: `
    <div class="modal-header">
      <div class="row justify-content-center">
        <h4 class="modal-title text-center ml-2">
          {{ videoTitle }}
        </h4>
      </div>

      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
        <iframe id="{{ videoTitle }}"
                title="{{ videoTitle }}"
                width="100%"
                height="360"
                allowfullscreen
                frameborder="0"
                [src] = "video.videoUrl | safe">
        </iframe>

    </div>
  `
})
export class VideoModalComponent implements OnInit {

  @Input() video;
  videoTitle: string

  constructor(public activeModal: NgbActiveModal,
              private translate: TranslateService,
              private ucfirst: UpperCaseFirstPipe) {
  }

  ngOnInit(): void {
    this.videoTitle = this.video.title ? this.video.title : this.ucfirst.transform(this.translate.instant('dispositif-page.video-modal.default-title'));
  }

  clicked(result: boolean) {
    this.activeModal.close(result);
  }

  close() {
    this.activeModal.close(false);
  }
}
