import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpperCaseFirstPipe } from '../pipes/ucfirst.pipe';
import { IBreadcrumb } from "../interfaces/IBreadcrumb";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class HubBreadCrumbsService {
  homeBreadCrumb: string;
  _breadcrumbs: IBreadcrumb[] = [];
  $breadcrumbs = new BehaviorSubject<Array<IBreadcrumb>>([]);

  constructor(
    private translate: TranslateService,
    private ucfirstPipe: UpperCaseFirstPipe,
  ) {}

  add(breadcrumbs: IBreadcrumb[]) {
    this.translate.get('home').subscribe(home => {
      this._breadcrumbs = [
        {
          label: this.ucfirstPipe.transform(home),
          url: '/',
          params: [],
        },
      ];

      breadcrumbs.forEach(breadcrumb => {
        this.translate.get(breadcrumb.label).subscribe(label => {
          this._breadcrumbs.push({
            ...breadcrumb,
            label: this.ucfirstPipe.transform(label),
          });
        });
      });
      this.$breadcrumbs.next(this._breadcrumbs);
    });
  }

  unset() {
    this._breadcrumbs = [];
    this.$breadcrumbs.next([]);
  }

  get breadcrumbs() {
    return this.$breadcrumbs.asObservable();
  }
}
