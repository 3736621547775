import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {entityTypes} from 'src/app/constants';
import {GDIService} from 'src/app/services/hub-api/g-d-i.service';
import {CompanyService} from "../../services/hub-api/company.service";
import {InterlocutorService} from "../../services/hub-api/interlocutor.service";


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent implements OnInit, OnChanges {
  @Input()
  entity: any = {};

  @Input()
  showPageButton = true;

  @Input()
  showReferent = true;

  @Input()
  showStructure = true;

  @Input()
  showSiret = true;

  @Input()
  showName = true;

  @Output()
  clickPageButton = new EventEmitter<string>();

  @Input()
  modifyTextButton = false;

  @Input()
  agent_structure: any = {};

  @Input()
  agent_interlocutor: any = {};

  disabled = true;
  privilegedContact: any = {};
  projects: any = [];
  actors: any = [];

  constructor(private GdiService: GDIService,
              private companyService: CompanyService,
              private interlocutorService: InterlocutorService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.getPrivilegedContact();
    }

  ngOnInit() {
    this.accessToCompanyIsDisabled();
    this.getPrivilegedContact();
  }

  private getPrivilegedContact() {
    if (this.entity.privilegedcontact) {
      this.GdiService.getUserById(
        this.entity.privilegedcontact.keycloakid
      ).then((data: any) => {
        this.privilegedContact = data.userInformation;
      });
    }
  }

//acces aux détails de l'entreprise si l'utilisateur connecté est acteur d'au moins un projet de l'entreprise
  async isActorFunction(interlocutor: any) {
    const interlocutorProjects = (await this.interlocutorService.getInterlocutorProjects(interlocutor.id)) as Array<any>;
    const companyProjects = (await this.companyService.getAllProjects(this.entity.id)) as Array<any>;
    const companyProjectsId = companyProjects.map(companyProject => companyProject.id);

    interlocutorProjects.forEach(project => {
      if (companyProjectsId.includes(project.id)){
        this.disabled = false;
      }
    });
  }

  accessToCompanyIsDisabled() {
    const email = this.agent_interlocutor.email;

    if (
      email &&
      (email.includes('@agence-adocc.com') || email.includes('@laregion.fr'))
    ) {
      this.disabled = false;
    }

    if (this.disabled && this.entity.interlocutor.structure.id === this.agent_structure.id) {
      this.disabled = false;
    }

    if (this.disabled) {
      this.isActorFunction(this.agent_interlocutor)
    }
  }




  activityAreasToToolTips() {
    let text = '';
    this.entity.activityareas.forEach(activity_area => {
      text += activity_area.label + '\n';
    });
    return text;
  }

  firstActivityArea() {
    if (this.entity.activityareas && this.entity.activityareas.length !== 0) {
      return this.entity.activityareas[0].label;
    }
  }

  isCompany() {
    return this.entity.type === entityTypes.company;
  }

  returnEntity() {
    if (this.isCompany()) {
      return {
        name: this.entity.name,
        type: this.entity.type,
        logo: this.entity.logo
      };
    }
    return {name: this.entity.contactname, type: this.entity.type};
  }

  onClickPageButton() {
    this.clickPageButton.emit(this.entity.id);
  }

  noDataForContact() {
    return (
      !this.entity.contactname &&
      !this.privilegedContact.phone &&
      !this.privilegedContact.email
    );
  }
}
