<div class="hub-search-page">
  <hub-search (search)="search($event)" (tab)="switchTab($event)" [defaultSearchValues]="paramValues" [selectedTab]="selectedSearch"></hub-search>


  <div class="hub-search-spinner"
       *ngIf="(selectedTab === companyTypeEnum.COMPANIES && isSearchingCompanies) ||
              (selectedTab === companyTypeEnum.PROJECTS && isSearchingProjects) ||
              (selectedTab === companyTypeEnum.ALL_RESULTS && isSearchingCompaniesAndProjects) ||
              (isSearchingPlansOrInterlocutor && (tab === tabs.interlocutor ||  tab === tabs.plan))">
    <app-hub-spinner-dots></app-hub-spinner-dots>
  </div>

  <div class="pagination" *ngIf="tab === 'contact' &&
              ((selectedTab === companyTypeEnum.COMPANIES && !isSearchingCompanies && companies.length > 0) ||
              (selectedTab === companyTypeEnum.PROJECTS && !isSearchingProjects && projects.length > 0) ||
              (selectedTab === companyTypeEnum.ALL_RESULTS && !isSearchingCompaniesAndProjects && entities.length > 0))">
    <div class="resultText">
      {{ 'my-directory.result' | translate | ucfirst }} ({{getLength() === 10000 ? getLength() + '+' : getLength()}})
    </div>
  </div>

  <div class="pagination" *ngIf="!isSearchingPlansOrInterlocutor &&
                                  ((tab === tabs.interlocutor && interlocutors?.length > 0) ||  (tab === tabs.plan && plans?.length > 0))">
    <div class="resultText">
      {{ 'my-directory.result' | translate | ucfirst }} ({{getLength()}})
    </div>

    <div>
      <hub-pagination-menu [configPagination]="configPaginationPlanOrInterlocutor" (onPaginationClick)="handlePageChange($event)">
      </hub-pagination-menu>
    </div>
  </div>

  <!-- PARTIE CONTACTS -->
  <div *ngIf="tab === 'contact'">
    <div *ngIf="((selectedTab === companyTypeEnum.COMPANIES && !isSearchingCompanies && companies.length > 0) ||
              (selectedTab === companyTypeEnum.PROJECTS && !isSearchingProjects && projects.length > 0) ||
              (selectedTab === companyTypeEnum.ALL_RESULTS && !isSearchingCompaniesAndProjects && entities.length > 0))">
      <app-hub-tabs (idSelected)="onTabChange($event)" [isCompanyTabs]="true" [isAlreadyInitialized]="tabsAlreadyInitialized" [currentTab]="selectedTab">
        <hub-tab titre="{{ 'home-connected.all-results' | translate | ucfirst }} ({{ totalNumberOfCompaniesAndProjects === 10000 ? totalNumberOfCompaniesAndProjects + '+': totalNumberOfCompaniesAndProjects }})" [id]="companyTypeEnum.ALL_RESULTS">
        </hub-tab>
        <hub-tab titre="{{ 'home-connected.companies' | translate | ucfirst }} ({{ totalNumberOfCompanies === 10000 ? totalNumberOfCompanies + '+': totalNumberOfCompanies }})" [id]="companyTypeEnum.COMPANIES">
        </hub-tab>
        <hub-tab titre="{{ 'home-connected.projects' | translate | ucfirst }} ({{ totalNumberOfProjects === 10000 ? totalNumberOfProjects + '+': totalNumberOfProjects }})" [id]="companyTypeEnum.PROJECTS">
        </hub-tab>
      </app-hub-tabs>

      <div class="pagination-company" >
        <hub-pagination-menu *ngIf="selectedTab === companyTypeEnum.COMPANIES" [configPagination]="configPaginationCompanies" (onPaginationClick)="handlePageChange($event)">
        </hub-pagination-menu>

        <hub-pagination-menu *ngIf="selectedTab === companyTypeEnum.PROJECTS" [configPagination]="configPaginationProjects" (onPaginationClick)="handlePageChange($event)">
        </hub-pagination-menu>

        <hub-pagination-menu *ngIf="selectedTab === companyTypeEnum.ALL_RESULTS" [configPagination]="configPaginationCompaniesAndProjects" (onPaginationClick)="handlePageChange($event)">
        </hub-pagination-menu>
      </div>
    </div>

    <hub-company-card-list *ngIf="!isSearchingCompanies && selectedTab === companyTypeEnum.COMPANIES"
                           [companyList]="companies"
                           [configPagination]="configPaginationCompanies"
                           [isLoadingAgentStructure]="isLoadingAgentStructure"
                           [agent_structure]="agent_structure"
                           [agent_interlocutor]="agent_interlocutor"
                           [hasSearchedOnce]="hasSearchedOnceCompanies"
    ></hub-company-card-list>

    <hub-company-card-list *ngIf="!isSearchingProjects && selectedTab === companyTypeEnum.PROJECTS"
                           [companyList]="projects"
                           [configPagination]="configPaginationProjects"
                           [isLoadingAgentStructure]="isLoadingAgentStructure"
                           [agent_structure]="agent_structure"
                           [agent_interlocutor]="agent_interlocutor"
                           [hasSearchedOnce]="hasSearchedOnceProjects"
    ></hub-company-card-list>

    <hub-company-card-list *ngIf="!isSearchingCompaniesAndProjects && selectedTab === companyTypeEnum.ALL_RESULTS"
                           [companyList]="entities"
                           [configPagination]="configPaginationCompaniesAndProjects"
                           [isLoadingAgentStructure]="isLoadingAgentStructure"
                           [agent_structure]="agent_structure"
                           [agent_interlocutor]="agent_interlocutor"
                           [hasSearchedOnce]="hasSearchedOnceCompaniesAndProjects"
    ></hub-company-card-list>
  </div>

  <!-- PARTIE FINANCEMENTS -->
  <div *ngIf="tab === 'plan'">
    <div *ngIf='plans?.length > 0 && !isSearchingPlansOrInterlocutor'>
      <hub-plan-card-search *ngFor="let plan of plans | paginate: configPaginationPlanOrInterlocutor" (cardClick)="goToPlanDetails($event)" [plan]="plan">
      </hub-plan-card-search>
    </div>
    <p class="hub-search-no-results" *ngIf="!isSearchingPlansOrInterlocutor && plans?.length === 0 && selectedSearch ==='plan' && hasSearchedOncePlansOrInterlocutor">{{ 'search.no-result' | translate | ucfirst }}</p>
  </div>

  <!-- PARTIE INTERLOCUTEURS -->
  <div *ngIf="tab === 'interlocutor'">
    <div *ngIf="!isSearchingPlansOrInterlocutor && interlocutors?.length > 0">
      <div class="mb-3" *ngFor="let interlocutor of interlocutors | paginate: configPaginationPlanOrInterlocutor">
        <hub-interlocutor-card
          [interlocutor]="interlocutor"
          [isContact]="isInterlocutorContact(interlocutor)"
          (onStarClick)="onStarClick($event)"></hub-interlocutor-card>
      </div>
    </div>
    <p class="hub-search-no-results" *ngIf="!isSearchingPlansOrInterlocutor && interlocutors?.length === 0 && selectedSearch === 'interlocutor' && hasSearchedOncePlansOrInterlocutor">
      {{ 'search.no-result' | translate | ucfirst }}
    </p>
  </div>
</div>
