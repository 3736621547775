<div *ngIf="link" class="hub-dispositif-download-button">
  <div class="hub-dispositif-download-button--icon">
    <i class="{{ 'fas fa-2x ' + faClass }}"></i>
  </div>

  <div class="hub-dispositif-download-button--title">
    <a *ngIf="isValidUri(link)" class="pointer" href="{{ link }}" target="_blank">{{ title | ucfirst }}</a>
    <a *ngIf="fileUrl" class="pointer" (click)="saveFile()">{{
      title | ucfirst
    }}</a>
    <span *ngIf="updatedAt">{{ 'search.changed' | translate | ucfirst }} {{ updatedAt | dateFormatDDMMYYYY:'x' }}</span>
  </div>
</div>
