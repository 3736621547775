import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'hub-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() classToAppend = '';
  @Input() key = '';
  shown = false;
  elementRef;

  constructor(elementRef: ElementRef,
              private renderer: Renderer2) {
    this.elementRef = elementRef;
    elementRef.nativeElement.parentElement.onclick = e => {
      e.preventDefault();
      this.shown = !this.shown;
    }

    elementRef.nativeElement.onclick = e => {
      e.preventDefault();
      e.stopPropagation();
      this.shown = true;
    }
  }

  ngOnInit() {
    this.elementRef.nativeElement.parentElement.classList.add(`position-relative`);
    this.elementRef.nativeElement.parentElement.classList.add(`dropdown-parent-${this.key}`);
  }

  onClickOutside(event) {
    event.preventDefault();
    this.shown = false
  }

}
