import {Injectable, Injector} from '@angular/core';
import {AbstractService} from "./abstract.service";
import {endpoints} from "../../constants";

@Injectable({
  providedIn: 'root'
})
export class SubventionService extends AbstractService {

  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.subvention;
  }

  getSubventionsBySiret(siret: string) {
    return this.api.get(`${this.endpoint}/siret/${siret}`);
  }
}
