<div>
  <h1 class="line">
    <span>{{ 'dossiers-projecteurs.title' | translate | ucfirst }}</span>
  </h1>

  <div *ngIf="isLoading" class="text-center">
    <app-hub-spinner-dots></app-hub-spinner-dots>
  </div>

  <div *ngIf="apiError && !isLoading">
    <div class="italic center api-error-message">
      <p>{{ 'dossiers-projecteurs.error-timeout' | translate | ucfirst }}</p>
      <button (click)="getDossiersProjecteurs()" class="btn btn-hub btn-primary btn-sm mt-4">{{ 'dossiers-projecteurs.try-again' | translate | ucfirst }}</button>
    </div>
  </div>

  <ngb-accordion #rubriqueAccordion="ngbAccordion" *ngIf="!isLoading">
    <ngb-panel *ngFor="let rubrique of rubriques;let i = index" [id]="'item' + i.toString()">
      <ng-template ngbPanelTitle>
        <span [innerHTML]="rubrique"></span><i [ngClass]="getClass(rubriqueAccordion, i.toString()) "></i>
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngFor="let dossier of dossiersProjecteurs[rubrique]" class="mb-4">
          <div class="mb-1">
            <span class="bold" [innerHTML]="dossier.fiche_titre"></span>
            <span class="bold" *ngIf="dossier.fiche_reference"> - {{ 'dossiers-projecteurs.reference' | translate | ucfirst }} : {{ dossier.fiche_reference }}</span>
          </div>
          <p [innerHTML]="dossier.description"></p>
          <p *ngIf="dossier.fiche_fichier"><a href="{{ dossier.fiche_fichier }}" target="_blank">{{ 'dossiers-projecteurs.link_to_file' | translate | ucfirst }}</a></p>
          <span *ngIf="dossier.changed">{{ 'dossiers-projecteurs.modification_date' | translate | ucfirst }} : {{ dossier.changed | frenchdate }}</span>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
