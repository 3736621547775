<div class="hub-ref-card">
  <div class="hub-ref-card--person">
    <div class="row">
      <div class="col">
        <i class="far fa-star pointer mr-2" *ngIf="!isContact" (click)="onRegularStarToggle()"></i>
        <i class="fas fa-star pointer mr-2" *ngIf="isContact" (click)="onSolidStarToggle()"></i>
        <span class="hub-ref-card--person---name d-inline" [ngClass]="{'disabled-name': isAbsent}"
              title="{{ interlocutor?.firstname }} {{ interlocutor?.lastname  | uppercase }}">{{ interlocutor?.firstname }}
          {{ interlocutor?.lastname  | uppercase }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-5 flex-item">
        <img id="person-logo" [src]="agentAvatarUrl" class="interlocutor-avatar"
             alt="{{ 'refCard.profilePicture' | translate | ucfirst }}"/>
        <span *ngFor="let type of interlocutorType" class="hub-ref-card--person---refExp">
        {{type}} <i *ngIf="type === expertTxt && interlocutor.plans.length > 0"
                    class="fa fa-chevron-circle-down tooltip--img" [ngbTooltip]="plans" placement="bottom"
                    tooltipClass="custom-tooltip"></i>
      </span>
      </div>
      <div class="col-7 flex-item">
        <div class="hub-ref-card--person---infos">
        <span *ngIf="interlocutor?.occupation?.label" class="hub-ref-card--person---occupation"
              title="{{ interlocutor?.occupation?.label }}">{{
          interlocutor?.occupation?.label
          }}</span>
          <span *ngIf="interlocutor?.structure?.name" title="{{ interlocutor?.structure?.name }}">{{
            interlocutor?.structure?.name
            }}</span>
          <span *ngIf="interlocutor?.phonenumber && !interlocutor?.phonenumber2">
          {{ interlocutor?.phonenumber }}</span>
          <span *ngIf="!interlocutor?.phonenumber && interlocutor?.phonenumber2">
          {{ interlocutor?.phonenumber2 }}</span>
          <span *ngIf="interlocutor?.phonenumber && interlocutor?.phonenumber2">
          {{ interlocutor?.phonenumber }} - {{ interlocutor?.phonenumber2 }}</span>
          <span *ngIf="interlocutor?.email" title="{{ interlocutor?.email }}">
          <button type="button" class="btn btn-link" (click)="goToLink('mailto: ' + this.interlocutor.email, false)" [ngClass]="{'disabled-link': isAbsent}">
            {{ interlocutor?.email }}</button></span>
        </div>
        <div class="hub-ref-card--contact">
          <img *ngIf="interlocutor?.email" src="/assets/icons/mail.png" [ngClass]="{'pointer': !isAbsent}" (click)="goToLink('mailto: ' + this.interlocutor.email, false)"
               class="hub-ref-card--contact---logo pr-1"/>
            <img *ngIf="interlocutor?.skype" src="/assets/icons/skype.png" [ngClass]="{'pointer': !isAbsent}" (click)="goToLink(sanitize('skype:' + interlocutor?.skype + '?chat'), true)"
                 class="hub-ref-card--contact---logo pr-1"/>
            <img *ngIf="interlocutor?.linkedin" src="/assets/icons/linkedin.png" [ngClass]="{'pointer': !isAbsent}" (click)="goToLink(checklink(interlocutor?.linkedin), true)"
                 class="hub-ref-card--contact---logo pr-1"/>
          <div *ngIf="interlocutor?.structure?.logo?.id">
            <img src="{{ getLogoUrl(interlocutor?.structure?.logo) }}"
                 alt="{{ 'information-card.logoInfo' | translate | ucfirst }}" class="hub-ref-card--contact---logo"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #plans>
  <div *ngFor='let plan of interlocutor.plans'>
    {{plan.name}}
  </div>
</ng-template>
