import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {subvention, dateFormat} from '../../constants';
import * as moment from 'moment';

@Component({
  selector: 'app-hub-subvention-card',
  templateUrl: './subvention-card.component.html',
  styleUrls: ['./subvention-card.component.scss']
})
export class SubventionCardComponent implements OnInit {
  @Input() subvention;
  admissibility;
  lastDecision;
  showProject = false;
  shownLiquidations;
  sumLiquidations: number;
  sumDecisionsAmount: number;
  areAllLiquidationsPaid: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.sumLiquidations = this.calcSumLiquidations();
    this.sumDecisionsAmount = this.calcSumDecisionsAmount();
    this.admissibility = this.getAdmissibility();
    this.lastDecision = this.getLastDecision();
    this.showProject = !this.isLastDecisionValid() && !this.isRefused();
    this.shownLiquidations = this.subvention.liquidations.filter(l => {
      return l.montant && SubventionCardComponent.convertAmount(l.montant) !== 0
    });
    this.areAllLiquidationsPaid = this.sumLiquidations >= this.sumDecisionsAmount && this.sumDecisionsAmount > 0;
  }

  /**
   * Allow to retrieve the string of admissibility to display at the user.
   * Based on what is return from the backend, could be changed.
   */
  getAdmissibility() {

    let admissibilityString;

    switch (this.subvention.dossierProgos.avisInstructeur) {
      case subvention.avisInstructionsCode.attente:
        admissibilityString = subvention.avisInstructionDisplay.attente;
        break;
      case subvention.avisInstructionsCode.irrecevable :
      case subvention.avisInstructionsCode.ineligible :
        admissibilityString = subvention.avisInstructionDisplay.non;
        break;
      case subvention.avisInstructionsCode.eligible:
        admissibilityString = subvention.avisInstructionDisplay.oui;
        break;
      default:
        admissibilityString = subvention.noDisplay;
        break;
    }

    return admissibilityString;
  }

  /**
   * Verifying if the project is acceptable ("Oui" or "en attente")
   */
  isAdmissible() {
    return this.admissibility === subvention.avisInstructionDisplay.attente || this.admissibility === subvention.avisInstructionDisplay.oui;
  }

  /**
   * Verifying if the project was accepted
   */
  isAdmitted() {
    return this.admissibility === subvention.avisInstructionDisplay.oui;
  }

  isRefused() {
    return this.admissibility === subvention.avisInstructionDisplay.non;
  }

  /**
   * Put a date in a french format for display
   * @param date
   */
  getDisplayableDate(date) {
    if (date) {
      return moment(date).format(dateFormat);
    } else {
      return subvention.noDisplay;
    }
  }

  displayLiquidationDate(date) {
    return moment(date, 'DD-MM-YYYY').format(dateFormat);
  }

  /**
   * retrieve the last decision declared
   */
  getLastDecision() {
    const moments = this.subvention.dossierProgos.decisions.map(decision => {
      return moment(decision.dateDecision);
    });

    const maxDate = moment.max(moments);

    return this.subvention.dossierProgos.decisions.find(decision => {
      const momentDate = moment(decision.dateDecision);
      return momentDate.isSame(maxDate)
    });
  }

  /**
   * Verifying if the last decision is valid
   * in other words, if the decision has a date and an amount declared.
   */
  isLastDecisionValid() {
    if (this.lastDecision) {
      return  this.isAdmitted() && this.lastDecision.dateDecision && this.lastDecision.montantVote;
    } else {
      return false;
    }
  }

  isOneLiquidationNotEmpty() {
    return this.subvention.liquidations.some(l => {
      return SubventionCardComponent.convertAmount(l.montant) > 0;
    });
  }

  calcSumLiquidations() {
    return this.subvention.liquidations.reduce((acc, l) => {
      return SubventionCardComponent.convertAmount(l.montant) + acc;
    }, 0);
  }

  calcSumDecisionsAmount() {
    return this.subvention.dossierProgos.decisions.reduce((acc, d) => {
      return d.montantVote + acc;
    }, 0);
  }

  private static convertAmount(amount: string): number {
    if (amount.includes(',')) {
      amount = amount.replace(',', '.');
    }
    return +amount;
  }

}
