import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {KeycloakAngularModule} from 'keycloak-angular';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CookieService} from 'ngx-cookie-service';
import {MomentModule} from 'ngx-moment';
import {OrderModule} from 'ngx-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {AppAuthModule} from './app-auth.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AddProjectActorComponent} from './components/add-project-actor/add-project-actor.component';
import {CompanyCardComponent} from './components/company-card/company-card.component';
import {EntityHeaderComponent} from './components/entity-header/entity-header.component';
import {EntityLogoComponent} from './components/entity-logo/entity-logo.component';
import {InformationCardComponent} from './components/information-card/information-card.component';
import {InterlocutorCardComponent} from './components/interlocutor-card/interlocutor-card.component';
import {LinkCardComponent} from './components/link-card/link-card.component';
import {ModalComponent} from './components/modal/modal.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {NotificationCardComponent} from './components/notification-card/notification-card.component';
import {ProjectSummaryComponent} from './components/project-summary/project-summary';
import {RefererCardComponent} from './components/referer-card/referer-card.component';
import {SearchCardComponent} from './components/search-card/search-card.component';
import {HeaderConnectedComponent} from './components/shared/header/connected/header.connected.component';
import {HeaderDefaultComponent} from './components/shared/header/default/header.default.component';
import {HeaderComponent} from './components/shared/header/header.component';
import {SliderMenuComponent} from './components/shared/slider-menu/slider-menu.component';
import {SpinnerDotsComponent} from './components/spinner-dots/spinner-dots.component';
import {SubventionCardComponent} from './components/subvention-card/subvention-card.component';
import {TabComponent} from './components/tab/tab.component';
import {TabsCompanyComponent} from './components/tabs/tabs-company/tabs-company.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {AuthGuard} from './guards/auth.guard';
import {InterlocutorGuard} from './guards/interlocutor.guard';
import {ReferentGuard} from './guards/referent.guard';
import {StructureGuard} from './guards/structure.guard';
import {AuthenticationInterceptor} from './interceptors/authentication.interceptor';
import {CompanyDetailsComponent} from './pages/entity/company-details/company-details.component';
import {EntityComponent} from './pages/entity/entity.component';
import {HomeConnectedComponent} from './pages/home/connected/connected.component';
import {HomeDefaultComponent} from './pages/home/default/default.component';
import {HomeComponent} from './pages/home/home.component';
import {MyWalletComponent} from './pages/my-wallet/my-wallet.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {SearchComponent} from './pages/search/search.component';
import {DatePipe} from './pipes/date.pipe';
import {DateFormatPipe} from './pipes/dateFormat.pipe';
import {DatetimePipe} from './pipes/datetime.pipe';
import {FrenchVerboseDatePipe} from './pipes/french-verbose-date.pipe';
import {SirenPipe} from './pipes/siren.pipe';
import {SiretPipe} from './pipes/siret.pipe';
import {SortEntitiesPipe} from './pipes/sort-entities.pipe';
import {UpperCaseFirstPipe} from './pipes/ucfirst.pipe';
import {AuthService} from './services/auth.service';
import {EncryptorService} from './services/encryptor.service';
import {ActivityAreaService} from './services/hub-api/activityArea.service';
import {AgentService} from './services/hub-api/agent.service';
import {ApiService} from './services/hub-api/api.service';
import {CompanyLogoService} from './services/hub-api/company-logo.service';
import {CompanyService} from './services/hub-api/company.service';
import {AgentConsultationCompanyService} from './services/hub-api/consultation-company.service';
import {DirectoryService} from './services/hub-api/directory.service';
import {FileService} from './services/hub-api/file.service';
import {InterlocutorService} from './services/hub-api/interlocutor.service';
import {MailService} from './services/hub-api/mail.service';
import {NotificationService} from './services/hub-api/notification.service';
import {PlanService} from './services/hub-api/plan.service';
import {PlanCardSearchComponent} from './components/plan-card-search/plan-card-search.component';
import {ReferentRequestService} from './services/hub-api/referentRequest.service';
import {SpecialityService} from './services/hub-api/speciality.service';
import {StructureLogoService} from './services/hub-api/structure-logo.service';
import {StructureService} from './services/hub-api/structure.service';
import {SubventionService} from './services/hub-api/subvention.service';
import {TerritoryService} from './services/hub-api/territory.service';
import {HubBreadCrumbsService} from './services/hub-breadcrumbs.service';
import {LocalStorageService} from './services/local-storage.service';
import {RoutingStateService} from './services/routing-state.service';
import {SessionService} from './services/session.service';
import {SliderMenuService} from './services/slider-menu.service';
import {SortService} from './services/sort.service';
import {ProfileComponent} from './pages/profile/profile.component';
import {OccupationService} from './services/hub-api/occupation.service';
import {AgentAvatarService} from './services/hub-api/agent-avatar.service';
import {HeaderService} from './services/header.service';
import {NgxMaskModule} from 'ngx-mask';
import {ContactDetailsComponent} from './pages/entity/contact-details/contact-details.component';
import {GDIService} from './services/hub-api/g-d-i.service';
import {UserService} from './services/hub-api/user.service';
import {GdiService} from './services/gdi.service';
import {FooterComponent} from './components/footer/footer.component';
import {SanitizerPipe} from './pipes/sanitizer.pipe';
import {StaticPageComponent} from './pages/static-page/static-page.component';
import {StaticPageService} from './services/static-page.service';
import {TitleService} from './services/title.service';
import {DispositifComponent} from './pages/dispositif/dispositif.component';
import {DataCardComponent} from './components/data-card/data-card.component';
import {DownloadButtonComponent} from './components/download-button/download-button.component';
import {PlanFileService} from './services/hub-api/plan-file.service';
import {SearchService} from './services/search.service';
import {PlanActivityAreasModalComponent} from './components/plan-activity-areas-modal/plan-activity-areas-modal.component';
import {CapitalizeFirstPipe} from "./pipes/capitalize-first.pipe";
import {ContactUsComponent} from './pages/contact/contact-us.component';
import {ProjectService} from "./services/hub-api/project.service";
import {DropdownComponent} from "./components/dropdown/dropdown.component";
import {ClickOutsideModule} from 'ng-click-outside';
import {MyContactsComponent} from "./pages/my-contacts/my-contacts.component";
import {DatePickerService} from './services/datepicker.service';
import {DateParserService} from './services/dateparser.service';
import {DossiersProjecteursComponent} from "./pages/dossiers-projecteurs/dossiers-projecteurs.component";
import {EvenementsListComponent} from "./pages/evenements/evenements-list/evenements-list.component";
import {EvenementsAddComponent} from "./pages/evenements/evenements-add/evenements-add.component";
import {EvenementsEditComponent} from "./pages/evenements/evenements-edit/evenements-edit.component";
import {EventCardComponent} from "./components/event-card/event-card.component";
import {BpiFranceCreationService} from "./services/hub-api/bpi-france-creation.service";
import {EncartComponent} from "./components/encart/encart.component";
import {EncartService} from "./services/hub-api/encart.service";
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";
import {UserConsultationPlanService} from "./services/hub-api/user-consultation-plan.service";
import {EventLogoService} from "./services/hub-api/event-logo.service";
import {EventService} from "./services/hub-api/eventService";
import {EvenementsFormComponent} from "./pages/evenements/evenements-form/evenements-form.component";
import {ConfirmDeleteEventComponent} from "./components/confirm-delete-event/confirm-delete-event.component";
import {RefuseReferentModalComponent} from './components/refuse-referent-modal/refuse-referent-modal.component';
import {PlanSiteComponent} from "./pages/plan-site/plan-site.component";
import {BreadcrumbsComponent} from "./components/breadcrumbs/breadcrumbs.component";
import {VideoModalComponent} from './components/modal/videoModal.component';
import {SafePipe} from './utils/safePipe';
import {EventEditionGuard} from "./guards/event-edition.guard";
import {CompanyCardListComponent} from './components/company-card-list/company-card-list.component';
import {PaginationMenuComponent} from './components/pagination-menu/pagination-menu.component';
import {CharteQualityGuard} from './guards/charte-quality.guard';
import {CharteQualiteComponent} from './pages/charte-qualite/charte-qualite.component';
import { ConfirmationCharteQualiteModalComponent } from './components/confirmation-charte-qualite-modal/confirmation-charte-qualite-modal.component';
import {AccessCharteQualityGuard} from './guards/access-charte-quality.guard';
import {EspaceRdeoComponent} from './pages/espace-rdeo/espace-rdeo.component';
import {RdeoDocumentService} from './services/hub-api/rdeo-document.service';
import {RdeoDocumentFileService} from './services/hub-api/rdeo-document-file.service';
import {RdeoVideoService} from './services/hub-api/rdeo-video.service';

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    HomeComponent,
    SearchCardComponent,
    TabComponent,
    TabsComponent,
    HomeConnectedComponent,
    HomeDefaultComponent,
    AddProjectActorComponent,
    CompanyCardComponent,
    CompanyCardListComponent,
    PaginationMenuComponent,
    InterlocutorCardComponent,
    InformationCardComponent,
    EntityHeaderComponent,
    EntityLogoComponent,
    UpperCaseFirstPipe,
    SirenPipe,
    SiretPipe,
    EntityComponent,
    CompanyDetailsComponent,
    TabComponent,
    TabsComponent,
    RefererCardComponent,
    SpinnerDotsComponent,
    SliderMenuComponent,
    HeaderComponent,
    HeaderDefaultComponent,
    HeaderConnectedComponent,
    NotificationsComponent,
    NotificationCardComponent,
    DatetimePipe,
    PageNotFoundComponent,
    NotFoundComponent,
    TabComponent,
    TabsComponent,
    ProjectSummaryComponent,
    LinkCardComponent,
    NotificationsComponent,
    SearchComponent,
    SortEntitiesPipe,
    FrenchVerboseDatePipe,
    DatePipe,
    DateFormatPipe,
    MyWalletComponent,
    SubventionCardComponent,
    TabsCompanyComponent,
    ModalComponent,
    ProfileComponent,
    ContactDetailsComponent,
    FooterComponent,
    StaticPageComponent,
    SanitizerPipe,
    PlanCardSearchComponent,
    DispositifComponent,
    DataCardComponent,
    DownloadButtonComponent,
    PlanActivityAreasModalComponent,
    CapitalizeFirstPipe,
    ContactUsComponent,
    DropdownComponent,
    MyContactsComponent,
    DossiersProjecteursComponent,
    EvenementsListComponent,
    EvenementsAddComponent,
    EvenementsEditComponent,
    EventCardComponent,
    EncartComponent,
    EvenementsFormComponent,
    ConfirmDeleteEventComponent,
    RefuseReferentModalComponent,
    PlanSiteComponent,
    BreadcrumbsComponent,
    VideoModalComponent,
    SafePipe,
    CharteQualiteComponent,
    ConfirmationCharteQualiteModalComponent,
    EspaceRdeoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppAuthModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgbModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OrderModule,
    NgSelectModule,
    MomentModule,
    NgSelectModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    ClickOutsideModule,
    KeycloakAngularModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    {provide: NgbDatepickerI18n, useClass: DatePickerService},
    {provide: NgbDateParserFormatter, useClass: DateParserService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    AuthService,
    SliderMenuService,
    SessionService,
    CookieService,
    EncryptorService,
    LocalStorageService,
    HubBreadCrumbsService,
    UpperCaseFirstPipe,
    ApiService,
    StructureService,
    TerritoryService,
    CompanyService,
    CookieService,
    SessionService,
    EncryptorService,
    StructureService,
    StructureLogoService,
    DirectoryService,
    DateFormatPipe,
    MailService,
    ReferentRequestService,
    FileService,
    AgentConsultationCompanyService,
    AgentService,
    InterlocutorService,
    NotificationService,
    AuthGuard,
    CompanyService,
    InterlocutorGuard,
    StructureGuard,
    RoutingStateService,
    SortService,
    SubventionService,
    ReferentGuard,
    SpecialityService,
    ActivityAreaService,
    PlanService,
    PlanCardSearchComponent,
    CompanyLogoService,
    OccupationService,
    AgentAvatarService,
    HeaderService,
    GDIService,
    UserService,
    StaticPageService,
    TitleService,
    GdiService,
    PlanFileService,
    SearchService,
    ProjectService,
    BpiFranceCreationService,
    EncartService,
    BpiFranceCreationService,
    EventService,
    EventLogoService,
    UserConsultationPlanService,
    EventEditionGuard,
    CharteQualityGuard,
    AccessCharteQualityGuard,
    RdeoDocumentService,
    RdeoDocumentFileService,
    RdeoVideoService
  ],
  bootstrap: [AppComponent],
  entryComponents: [PlanActivityAreasModalComponent, AddProjectActorComponent, ConfirmDeleteEventComponent, RefuseReferentModalComponent, VideoModalComponent, ConfirmationCharteQualiteModalComponent]
})
export class AppModule {}
