import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';

@Injectable()
export class DirectoryService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.directory;
  }

  /**
   * Retourne l'id du contact en fonction du user, de la company et de l'interlocutor
   *
   * @param idUser id de l'user
   * @param idCompany id de la company
   * @param idInterlocutor id de l'interlocutor
   */
  async getIDContact(idUser, idCompany, idInterlocutor) {
    const response: any = await this.get({
      company: idCompany,
      utilisateur: idUser,
      interlocutor: idInterlocutor
    });

    return !response.hasError && response.result && response.result.length
      ? response.result[0].id
      : null;
  }
}
