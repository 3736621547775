import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class LocalStorageService {
  private showCookiesWarning = 'showCookiesWarning';
  private cookiesWarningLifetime = 'cookiesWarningLifetime';
  private loginError = 'loginError';

  setShowCookiesWarning(value?: string) {
    window.localStorage.setItem(
      this.showCookiesWarning,
      value ? value : 'false'
    );
  }

  getShowCookiesWarning() {
    return window.localStorage.getItem(this.showCookiesWarning);
  }

  setCookiesWarningLifetime(value?: string) {
    window.localStorage.setItem(
      this.cookiesWarningLifetime,
      value
        ? value
        : String(
            moment()
              .add(13, 'M')
              .valueOf()
          )
    );
  }

  getCookiesWarningLifetime() {
    return window.localStorage.getItem(this.cookiesWarningLifetime);
  }

  setLoginError(email) {
    window.localStorage.setItem(this.loginError, email);
  }

  deleteLoginError() {
    window.localStorage.removeItem(this.loginError);
  }

  getLoginError() {
    return window.localStorage.getItem(this.loginError);
  }
}
