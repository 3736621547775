<pagination-template class="pagination-all" #p="paginationApi" [id]="configPagination.id"
                     (pageChange)="onPageChange($event)">
  <div class="custom-pagination">
    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
      <a *ngIf="!p.isFirstPage()" (click)="p.previous()"
         title="{{ 'my-directory.previous-page' | translate | ucfirst }}">
        <i class="fa fa-chevron-circle-left"></i> </a>
    </div>
    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
      <ng-container *ngIf="page.value < 1000">
        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
          <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value" class="paginationSelected">
          <span>{{ page.label }}</span>
        </div>
      </ng-container>
    </div>
    <div class="pagination-next" [class.disabled]="p.isLastPage()">
      <a *ngIf="!p.isLastPage()" (click)="p.next()"
         title="{{ 'my-directory.next-page' | translate | ucfirst }}">
        <i class="fa fa-chevron-circle-right"></i> </a>
    </div>
  </div>
</pagination-template>
