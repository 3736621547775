import {Component, OnInit} from '@angular/core';
import {BpiFranceCreationService} from "../../services/hub-api/bpi-france-creation.service";
import {NgbAccordion} from "@ng-bootstrap/ng-bootstrap";
import {HubBreadCrumbsService} from "../../services/hub-breadcrumbs.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-dossiers-projecteurs',
  templateUrl: './dossiers-projecteurs.component.html',
  styleUrls: ['./dossiers-projecteurs.component.scss']
})
export class DossiersProjecteursComponent implements OnInit {

  dossiersProjecteurs: any;
  rubriques: Array<string> = [];
  isLoading: boolean;
  apiError = false;

  constructor(
    private bpi: BpiFranceCreationService,
    private breadcrumbs: HubBreadCrumbsService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.breadcrumbs.add([
      {
        label: this.translateService.instant('breadcrumbs.dossiers-projecteurs'),
        url: '/dossiers-projecteurs',
        params: []
      }
    ]);
    this.getDossiersProjecteurs();
  }

  getDossiersProjecteurs() {
    this.apiError = false;
    this.isLoading = true;
    this.bpi.getDossiersProjecteurs()
      .then((unorderedDossiersProjecteurs) => {
        this.dossiersProjecteurs = this.bpi.groupAndSortByRubrique(unorderedDossiersProjecteurs as Array<any>);
        this.rubriques = Object.keys(this.dossiersProjecteurs);
        this.isLoading = false;
      })
      .catch((error) => {
        this.apiError = true;
        this.isLoading = false;
      });
  }

  getClass(accordion: NgbAccordion, panelId) {
    return accordion.isExpanded(panelId)
      ? 'fa-caret-up fa float-right'
      : 'fa-caret-down fa float-right';
  }

}
