import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';
import {HttpClient} from "@angular/common/http";
import {EventLogoService} from "./event-logo.service";
import {StructureLogoService} from "./structure-logo.service";

@Injectable()
export class EventService extends AbstractService {
  constructor(injector: Injector,
              private eventLogoService: EventLogoService,
              private structureLogoService: StructureLogoService) {
    super(injector);
    this.endpoint = endpoints.event;
  }

  getEventsByStructure(structureId: string){
    return this.api.get(`${endpoints.event}/structure/${structureId}`)
  }

  getLogoUrl(event: any) {
    if (event.logo) {
      if (!event.defaultLogo) {
        return this.eventLogoService.getFileUrl(event.logo);
      } else {
        return this.structureLogoService.getFileUrl(event.logo);
      }
    } else {
      return '/assets/icons/building-solid.svg';
    }
  }
}
