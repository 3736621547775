<div class="card-body evenement-form">

  <div class="row">
    <div class="col-9 refused" *ngIf="isRefused">
      <i class="fas fa-exclamation-circle"></i>
      <span class="ml-1 bold">{{ 'evenements-form.refused' | translate | ucfirst }}</span>
      <div>{{ 'evenements-form.motif' | translate | ucfirst }}</div>
      <div>{{ event.motif | translate | ucfirst }}</div>
    </div>
    <div class="col-3" [ngClass]="{'offset-8':!isRefused}" *ngIf="event.id">
      <div class="pointer" (click)="delete()">
        <i class="fas fa-trash fa-lg"></i>
        <span class="ml-1">{{ 'evenements-form.delete-event' | translate | ucfirst }}</span>
      </div>
    </div>
  </div>


  <form #eventForm=ngForm (ngSubmit)="submit()" class="mt-5">
    <div class="form-group mb-5 row">
      <div class="col-12">
        <div class="row">
          <div class="col-3">
            <label class="logo">{{ 'evenements-form.image' | translate | ucfirst }}</label>
            <span class="info">{{ 'evenements-form.image-subtitle' | translate | ucfirst }}</span>
          </div>

          <div class="col-2 d-flex">
            <img *ngIf="event.logo && event.logo.id" src="{{ getLogoUrl(event) }}" alt="logo de l'événement"
                 class="mb-4"/>
            <input type="file" (change)="uploadFile($event)" #uploader [style.display]="'none'"
                   name="logo" accept="image/*">
            <button *ngIf="event.logo === null || !event.logo.id" (click)="uploader.click()" type="button"
                    class="btn btn-secondary browse mx-auto"
                    aria-label="Browse">
              <span aria-hidden="true">{{ 'evenements-form.download' | translate | ucfirst }}</span>
            </button>
          </div>

          <div *ngIf="event.logo === null || !event.logo.id" class="col-7 info logo default">
            {{ 'evenements-form.image-default' | translate | ucfirst }}
          </div>
        </div>

        <div *ngIf="event.logo && event.logo.id" class="row">
          <div class="col-2 offset-3">
            <button (click)="deleteLogo()" type="button"
                    class="btn btn-secondary browse"
                    aria-label="Browse">
              <span aria-hidden="true">{{ 'evenements-form.delete' | translate | ucfirst }}</span>
            </button>
          </div>
          <div class="col-7 info logo">
            {{ 'evenements-form.image-default' | translate | ucfirst }}
          </div>
        </div>
      </div>
      <div *ngIf="fileTooLarge" class="row">
        <div class="col-auto">
          <div class="alert alert-danger">
            {{ 'file-too-large' | translate | ucfirst }} {{ maxFileSize / 1000000}} Mo
          </div>
        </div>
      </div>
      <div *ngIf="fileErrorMessage" class="row">
        <div class="form-group col-md-10">
          <div class="alert alert-danger">{{ fileErrorMessage | translate | ucfirst }}</div>
        </div>
      </div>
    </div>


    <div class="form-group row mb-4">
      <label class="col-3">{{ 'evenements-form.title' | translate | ucfirst }} *</label>
      <input class="form-control border col-9" type="text" [(ngModel)]="event.title" name="title" required/>
      <div *ngIf="eventForm.form?.get('title')?.invalid && eventForm.form?.get('title')?.touched"
           class="alert alert-danger col-9 offset-3">
        <span *ngIf="eventForm.form?.get('title')?.errors && eventForm.form?.get('title')?.errors['required']">
          {{'required-field' | translate | ucfirst }}
        </span>
      </div>
    </div>

    <div class="form-group row mb-4">
      <label class="col-3">{{ 'evenements-form.description' | translate | ucfirst }} *</label>
      <textarea style="resize: none;" rows="5" class="form-control border col-9" name="post_content_plain"
                [(ngModel)]="event.post_content_plain" required>
        </textarea>
      <div
        *ngIf="eventForm.form?.get('post_content_plain')?.invalid && eventForm.form?.get('post_content_plain')?.touched"
        class="alert alert-danger col-9 offset-3">
        <span *ngIf="eventForm.form?.get('post_content_plain')?.errors &&
        eventForm.form?.get('post_content_plain')?.errors['required']">
          {{'required-field' | translate | ucfirst }}
        </span>
      </div>
    </div>
    <div class="form-group row mb-4">
      <label class="col-3">{{ 'evenements-form.lieu' | translate | ucfirst }}</label>
      <input class="form-control border col-9" type="text" [(ngModel)]="event.lieu" name="lieu"/>
    </div>
    <div class="form-group row mb-4">
      <label class="col-3">{{ 'evenements-form.permalink' | translate | ucfirst }} *</label>
      <input class="form-control border col-9" type="text" [(ngModel)]="event.permalink" name="permalink" required
             pattern="^(http:\/\/|https:\/\/|www.){1}[a-z0-9].*$"
             placeholder="{{ 'evenements-form.permalink-placeholder' | translate }}"/>
      <div *ngIf="eventForm.form?.get('permalink')?.invalid && eventForm.form?.get('permalink')?.touched"
           class="alert alert-danger col-9 offset-3">
        <span *ngIf="eventForm.form?.get('permalink')?.errors && eventForm.form?.get('permalink')?.errors['required']">
          {{'required-field' | translate | ucfirst }}
        </span>
        <span *ngIf="!isValidUrl(eventForm.form?.get('permalink')?.value)
        && isNotNullUrl(eventForm.form?.get('permalink')?.value)">
          {{ 'evenements-form.permalink-invalid' | translate | ucfirst }}
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <label class="col-3 date">{{ 'evenements-form.datedebut' | translate | ucfirst }} *</label>
      <div class="d-flex align-items-center col-3">
        <i class="fa fa-calendar pointer mr-2" style="font-size: 30px" (click)="sd.toggle()"></i>
        <input class="form-control" [(ngModel)]="formattedStartDate" readonly name="datedebut"
               [minDate]="minDate" [maxDate]="formattedEndDate"
               ngbDatepicker #sd="ngbDatepicker" required>
      </div>

      <label class="col-3 date">{{ 'evenements-form.heuredebut' | translate | ucfirst }}</label>
      <ngb-timepicker [(ngModel)]="formattedStartHour" [spinners]="false" name="heuredebut"
                      class="col-3"></ngb-timepicker>
    </div>
    <div class="row mb-4">
      <label class="col-3 date">{{ 'evenements-form.datefin' | translate | ucfirst }} *</label>
      <div class="d-flex align-items-center col-3">
        <i class="fa fa-calendar pointer mr-2" style="font-size: 30px" (click)="sd2.toggle()"></i>
        <input class="form-control" [(ngModel)]="formattedEndDate" readonly name="datefin"
               [minDate]="formattedStartDate"
               ngbDatepicker #sd2="ngbDatepicker" required>
      </div>

      <label class="col-3 date">{{ 'evenements-form.heurefin' | translate | ucfirst }}</label>
      <ngb-timepicker [(ngModel)]="formattedEndHour" [spinners]="false" name="heurefin"
                      class="col-3"></ngb-timepicker>
    </div>
    <div class="d-flex justify-content-end mt-5 mb-4">
      <span class="info col-6 pl-4 pt-2">{{ 'evenements-form.mandatory-fields' | translate | ucfirst }}</span>
      <span type="button" class="cancel col-3" (click)="cancel()">
        {{ 'evenements-form.cancel' | translate | ucfirst }}
      </span>
      <button type="submit" class="btn btn-warning  col-3 mr-2"
              [disabled]="!eventForm.form.valid || isSubmitIsAlreadyClicked">
        {{ 'evenements-form.publish' | translate | ucfirst }}
      </button>
    </div>
  </form>
</div>
