import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {entityTypes} from 'src/app/constants';
import {InterlocutorService} from 'src/app/services/hub-api/interlocutor.service';
import {HubBreadCrumbsService} from 'src/app/services/hub-breadcrumbs.service';
import {SessionService} from 'src/app/services/session.service';
import {SortService} from 'src/app/services/sort.service';
import {GDIService} from 'src/app/services/hub-api/g-d-i.service';

@Component({
  selector: 'app-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.css']
})
export class MyWalletComponent implements OnInit {
  allCompanies: Array<any> = [];
  companies: Array<any> = [];
  projects: Array<any> = [];
  isLoading = true;
  agent_structure: any = {};
  agent_interlocutor: any = {};

  constructor(
    private interlocutorService: InterlocutorService,
    private sessionService: SessionService,
    private router: Router,
    private breadcrumbs: HubBreadCrumbsService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private sortService: SortService,
    private GdiService: GDIService
  ) {}

  ngOnInit() {
    this.breadcrumbs.add([
      {
        label: this.translateService.instant('breadcrumbs.my-wallet'),
        url: '/mon-portefeuille',
        params: []
      }
    ]);

    this.sessionService
      .getAgent()
      .then(agent => {
        this.interlocutorService
          .getById(agent.interlocutorid)
          .then((interlocutor: any) => {
            this.agent_structure = interlocutor.structure;
            this.agent_interlocutor = interlocutor;
          });
        this.spinner.show();
        this.interlocutorService
          .getReferentCompanies(agent.interlocutorid)
          .then((companies: any) => {
            companies.forEach(company => {

              this.allCompanies.push(company);
              if (company.type === entityTypes.company) {
                this.companies.push(company);
              } else if (company.type === entityTypes.project) {
                this.projects.push(company);
              }

              this.GdiService.getUserById(
                company.privilegedcontact.keycloakid
              ).then((contact: any) => {
                company.contactname =
                  contact.userInformation.lastName +
                  contact.userInformation.firstName;
              });
            });
          })
          .catch(err => {
            this.spinner.hide();
            this.isLoading = false;
          });
        this.spinner.hide();
        this.isLoading = false;
      })
      .catch(err => {
        this.spinner.hide();
        this.isLoading = false;
      });
  }

  /**
   * If the company is found in the list, go to the detailed page
   */
  goToCompanyOrProjectPage(dataId) {
    const companyClicked =
      this.allCompanies.find(companyFind => companyFind.id === dataId) || {};
    if (companyClicked.type === entityTypes.company) {
      this.router.navigate(['entite', dataId]);
    }
  }

  /**
   * Sort the list in param desc by the name attribute
   */
  sortByName(listToSort) {
    return this.sortService.sortByNameAndTypeCompanyAndOrder(listToSort);
  }
}
