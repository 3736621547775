import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  KeycloakAngularModule
} from 'keycloak-angular';
import {AppInitService} from './app-init.service';

export function initializer(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}

@NgModule({
  imports: [KeycloakAngularModule],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [
        AppInitService
      ]
    }
  ]
})
export class AppAuthModule {}
