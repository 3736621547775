import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-interlocutor-card',
  templateUrl: './interlocutor-card.component.html',
  styleUrls: ['./interlocutor-card.component.scss']
})
export class InterlocutorCardComponent implements OnInit {
  @Input()
  interlocutor: any = {};
  @Input()
  isContact = false;

  @Output()
  onStarClick = new EventEmitter<{'isToggled': boolean, 'interlocutor': any}>();

  teritories: any = [];

  async ngOnInit() {
    this.createTerritoriesList();
    this.setupAbsenceDatesFormat();
  }

  createTerritoriesList() {
    this.teritories = this.interlocutor.territoriesreferent.concat(
      this.interlocutor.territoriesexpert,
      this.interlocutor.territoriesspecialist
    );
    // suppression des doublons
    const cache = {};
    this.teritories = this.teritories.filter(function(elem) {
      return cache[elem.id] ? 0 : (cache[elem.id] = 1);
    });
  }

  starClick(event: {isToggled: boolean, interlocutor: any}) {
    this.onStarClick.emit({
      isToggled: event.isToggled,
      interlocutor: event.interlocutor
    });
  }

  setupAbsenceDatesFormat() {
    if (this.hasAbsence()) {
      moment.locale('fr');
      this.interlocutor.debutAbsence = moment(this.interlocutor.debutAbsence).format("L");
      this.interlocutor.finAbsence = moment(this.interlocutor.finAbsence).format("L");
    }
  }

  hasAbsence() {
    return this.interlocutor.debutAbsence && this.interlocutor.finAbsence;
  }

  isAbsent() {
    return this.hasAbsence()
      && moment().isBetween(moment(this.interlocutor.debutAbsence, "DD/MM/YYYY"), moment(this.interlocutor.finAbsence, "DD/MM/YYYY"));
  }
}
