import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'siret' })
export class SiretPipe implements PipeTransform {
  /**
   * On rajoute des espaces après le 3ème, 6ème et 9ème chiffre pour plus de lisibilité
   *
   * @param content le siret
   */
  transform(content) {
    const positionZero = 0;
    const positionThree = 3;
    const positionSix = 6;
    const positionNine = 9;
    const positionFifteen = 15;

    if (content) {
      return content
        .slice(positionZero, positionThree)
        .concat(' ')
        .concat(content.slice(positionThree, positionSix))
        .concat(' ')
        .concat(content.slice(positionSix, positionNine))
        .concat(' ')
        .concat(content.slice(positionNine, positionFifteen));
    }

    return content;
  }
}
