import { Injector } from '@angular/core';

import { ApiService } from './api.service';

export abstract class AbstractService {
  protected endpoint: string;
  protected api: ApiService;

  constructor(injector: Injector) {
    this.api = injector.get(ApiService);
  }

  get(params: object = {}) {
    return this.api.get(this.endpoint, params);
  }

  getById(id: string) {
    return this.api.get(`${this.endpoint}/${id}`);
  }

  getAll() {
    return this.api.get(this.endpoint, null);
  }

  getOne(id: string) {
    return this.api.get(`${this.endpoint}/${id}`);
  }

  post(params: object = {}) {
    return this.api.post(this.endpoint, params);
  }

  put(params: object = {}) {
    return this.api.put(this.endpoint, params);
  }

  delete(id: string) {
    return this.api.delete(`${this.endpoint}/${id}`);
  }
}
