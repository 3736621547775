import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'hub-encart-extranet',
  templateUrl: './encart.component.html',
  styleUrls: ['./encart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EncartComponent implements OnInit {

  @Input()
  encart: any;

  @Input()
  showMoreButton = false;

  isContentShown: boolean;

  constructor() { }

  ngOnInit() {
    this.isContentShown = !this.showMoreButton;
  }

  showMore() {
    this.isContentShown = !this.isContentShown;
  }

}
