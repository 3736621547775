<div class="hub-home-company-details">
  <div class="hub-home-tell-once">
    <div class="information d-flex align-items-center p-2">
      <div class="mr-4">
        <i class="fa fa-2x fa-info-circle info-circle"></i>
      </div>
      <div>
        <span [innerText]="'company-details.information' | translate | ucfirst"></span>
      </div>
    </div>
  </div>

  <ngb-accordion class="home-accordion" #companyDataAccordion="ngbAccordion">
    <ngb-panel id="identity">
      <ng-template ngbPanelTitle>
        <span class="title">{{ 'company-details.identity' | translate | ucfirst }}
          <i [ngClass]="getClass(companyDataAccordion, 'identity')"></i>
        </span>
      </ng-template>
      <ng-template ngbPanelContent>
        <table class="enterprise">
          <tr>
            <td><span class="bold">{{ 'company-details.SIREN' | translate | ucfirst }}</span></td>
            <td *ngIf="companySiteData.siret">{{ companySiteData.siret | siren }}</td>
            <td *ngIf="!companySiteData.siret">-</td>
          </tr>
          <tr>
            <td><span class="bold">{{ 'company-details.SIRET' | translate | ucfirst }}</span></td>
            <td *ngIf="companySiteData.siret">{{ companySiteData.siret | siret }}</td>
            <td *ngIf="!companySiteData.siret">-</td>
          </tr>
          <tr>
            <td><span class="bold">{{ 'company-details.headOffice' | translate | ucfirst }}</span></td>
            <td> {{ companySiteData.siege_social === true ? 'Oui' : 'Non' }} </td>
          </tr>
          <tr>
            <td><span class="bold">{{ 'company-details.capitalSocial' | translate | ucfirst }}</span>
            </td>
            <td *ngIf="extraitsRCS.capital?.montant">{{ extraitsRCS.capital?.montant | currency: '€' }}</td>
            <td *ngIf="!extraitsRCS.capital?.montant">-</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.commercialName' | translate | ucfirst }}</span>
            </td>
            <td *ngIf="extraitsRCS.nom_commercial">{{ extraitsRCS.nom_commercial }}</td>
            <td *ngIf="!extraitsRCS.nom_commercial">-</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.numberTVA' | translate | ucfirst }}</span> </td>
            <td *ngIf="tvaIntracommunautaire.tva_number">{{ tvaIntracommunautaire.tva_number }}</td>
            <td *ngIf="!tvaIntracommunautaire.tva_number">-</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.legalForm' | translate | ucfirst }}</span> </td>
            <td *ngIf="companyData.forme_juridique?.libelle">{{ companyData.forme_juridique?.libelle }}</td>
            <td *ngIf="!companyData.forme_juridique?.libelle">-</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.NAF' | translate | ucfirst }}</span> </td>
            <td *ngIf="companyData.activite_principale?.code && companyData.activite_principale?.libelle "> {{
              companyData.activite_principale?.code }},
              {{ companyData.activite_principale?.libelle }} </td>
            <td *ngIf="!companyData.activite_principale?.code && companyData.activite_principale?.libelle ">- , {{
              companyData.activite_principale?.libelle }}
            </td>
            <td *ngIf="companyData.activite_principale?.code && !companyData.activite_principale?.libelle ">{{
              companyData.activite_principale?.code }},
              -</td>
            <td *ngIf="!companyData.activite_principale?.code && !companyData.activite_principale?.libelle ">- , -</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.companyName' | translate | ucfirst }}</span> </td>
            <td *ngIf="companyData.companyName">{{ companyData.companyName }}</td>
            <td *ngIf="!companyData.companyName">-</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.creationDate' | translate | ucfirst }}</span>
            </td>
            <td *ngIf="companyData.date_creation">{{ formatDateFromTimestamp(companyData.date_creation) }}</td>
            <td *ngIf="!companyData.date_creation">-</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.enterprise-category' | translate | ucfirst }}</span>
            </td>
            <td *ngIf="companyData.categorie_entreprise">{{ companyData.categorie_entreprise }}</td>
            <td *ngIf="!companyData.categorie_entreprise">-</td>
          </tr>
          <tr>
            <td> <span class="bold">{{ 'company-details.numberOfEmployeesCompany' | translate | ucfirst }}</span>
            </td>
            <td *ngIf="companySiteData.tranche_effectif_salarie?.intitule">{{
              companySiteData.tranche_effectif_salarie.intitule }}</td>
            <td *ngIf="!companySiteData.tranche_effectif_salarie?.intitule">{{
              'company-details.noEffetiveEffectif' | translate | ucfirst }}</td>
          </tr>
        </table>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="identity-mandataire">
      <ng-template ngbPanelTitle>
        <span>{{ 'company-details.identity-mandataire' | translate | ucfirst }} <i
            [ngClass]=" getClass(companyDataAccordion, 'identity-mandataire') "></i></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="mandataires" *ngFor="let mandataire of mandataireSociaux.data">
          <table *ngIf="mandataire.data.nom && mandataire.data.prenom" class="table-mandataires">
            <tr>
              <td class="title"><span class="bold">{{ 'refCard.lastname' | translate | ucfirst }}</span> </td>
              <td *ngIf="mandataire.data.nom">{{ mandataire.data.nom }}</td>
              <td *ngIf="!mandataire.data.nom">-</td>
            </tr>
            <tr>
              <td class="title"><span class="bold">{{ 'refCard.firstname' | translate | ucfirst }}</span>
              </td>
              <td *ngIf="mandataire.data.prenom">{{ mandataire.data.prenom }}</td>
              <td *ngIf="!mandataire.data.prenom">-</td>
            </tr>
            <tr>
              <td class="title"><span class="bold">{{ 'company-details.function' | translate | ucfirst }}</span>
              </td>
              <td *ngIf="mandataire.data.fonction">{{ mandataire.data.fonction }}</td>
              <td *ngIf="!mandataire.data.fonction">-</td>
            </tr>
          </table>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="financial-data">
      <ng-template ngbPanelTitle>
        <span>{{ 'company-details.financial-data' | translate | ucfirst }} <i
            [ngClass]="getClass(companyDataAccordion, 'financial-data')"></i></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <span class="title bold" [ngClass]="{ 'error-retrieve-data' : !exercices.data}">{{
          'company-details.accounts' | translate | ucfirst }}
          :</span>&nbsp;
        <div *ngIf="exercices.data && exercices.data.length > 0" class="accounts">
          <table *ngFor="let exercice of exercices.data" class="account">
            <tr>
              <td class="sub-title"><span class="bold">{{ 'company-details.end-date-exercice' | translate |
                  ucfirst }}</span>
              </td>
              <td> {{ exercice.data.date_fin_exercice | date : "MM/dd/y" }} </td>
            </tr>
            <tr>
              <td class="sub-title"><span class="bold">{{ 'company-details.revenues' | translate | ucfirst
                  }}</span>
              </td>
              <td>{{ exercice.data.chiffre_affaires | currency: '€' }}</td>
            </tr>
          </table>
        </div>
        <span class="error-retrieve-data" *ngIf="!exercices.data">{{ 'error-retrieve-data' | translate |
          ucfirst }}</span>

      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
