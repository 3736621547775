import {Injectable, Injector} from '@angular/core';
import {endpoints} from "../../constants";
import {AbstractService} from "./abstract.service";

@Injectable()
export class BpiFranceCreationService extends AbstractService {

  private dossiersProjecteurs;

  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.bpiFranceCreation;
  }

  getDossiersProjecteurs() {
    if (this.dossiersProjecteurs) {
      return Promise.resolve(this.dossiersProjecteurs);
    }
    return this.api.get(`${this.endpoint}/dossiers-projecteurs`).then(dossiersProjecteurs => {
      this.dossiersProjecteurs = dossiersProjecteurs;
      return Promise.resolve(dossiersProjecteurs);
    });
  }

  groupAndSortByRubrique(dossiersProjecteurs: Array<any>) {
    const grouped = this.groupByRubrique(dossiersProjecteurs);
    return this.sortByRubrique(grouped);
  }

  private groupByRubrique(dossiersProjecteurs: Array<any>) {
    let grouped = {};
    dossiersProjecteurs.forEach(dossier => {
      if (grouped[dossier.rubrique_libelle]) {
        grouped[dossier.rubrique_libelle] = [...grouped[dossier.rubrique_libelle], dossier];
      } else {
        grouped[dossier.rubrique_libelle] = [dossier];
      }
    });
    return grouped;
  }

  private sortByRubrique(dossiersProjecteurs) {
    const ordered = {};
    Object.keys(dossiersProjecteurs).sort().forEach(rubrique => {
      ordered[rubrique] = dossiersProjecteurs[rubrique];
    });
    return ordered;
  }
}
