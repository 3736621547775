import { Injectable } from '@angular/core';

@Injectable()
export class StaticPageService {
  endpoint = 'assets/static-pages';

  constructor() {}

  load(slug) {
    return new Promise<any>(resolve => {
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open('GET', `./${this.endpoint}/${slug}.html`, true);
      xmlhttp.onload = () =>
        resolve(xmlhttp.status === 200 ? xmlhttp.responseText : '');
      xmlhttp.send();
    });
  }
}
