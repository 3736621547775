import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from '../../services/title.service';
import { StaticPageService } from '../../services/static-page.service';
import { staticPages } from '../../constants/index';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit {
  html = '';

  constructor(
    private route: ActivatedRoute,
    private staticPageService: StaticPageService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      const slug = params.slug;
      this.titleService.setPageTitle(this.getTitle(slug));
      this.staticPageService.load(slug).then(html => (this.html = html));
      window.scroll(0,0);
    });
  }

  getTitle(slug) {
    return staticPages[slug] ? staticPages[slug].title : '';
  }
}
