import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { FileService } from './file.service';

@Injectable()
export class PlanFileService extends FileService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.planFile;
  }

  /**
   * Trie une liste de documents selon l'attribut order.
   * Ne modifie pas le tableau original
   * @param documents la liste de documents
   * @returns la liste de documents triée par ordre
   */
  sortDocuments(documents) {
    return [...documents].sort((doc1, doc2) => {
      if (doc1.order < doc2.order) {
        return -1;
      } else if (doc1.order > doc2.order) {
        return 1;
      }
      return 0;
    });
  }
}
