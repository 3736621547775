<div class="hub-header">
  <div class="hub-header--content">
    <div role="button" class="hub-header-content--img" (keydown.enter)="goToHome()" (click)="goToHome()">
      <img class="hub-header--logo" src="../../../../../assets/images/logo-region-hub.jpg" alt="{{ 'header.picture' | translate | ucfirst }}" />
      <!--<img class="hub-header--logo" src="assets/images/logo.png" alt="{{ 'header.picture' | translate | ucfirst }}" /> -->
    </div>
  </div>
  <div class="hub-header--menus">
    <div class="hub-header--menus-inner">
    </div>
  </div>
</div>
