import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  /**
   * Id du fichier de l'avatar de l'utilisateur
   * Peut changer durant l'utilisation de l'application (page de profil -> changement d'avatar)
   * D'où le besoin de l'boserver pour rafraichir le header.
   */
  avatar$ = new BehaviorSubject<any>(undefined);

  constructor() {}

  get avatar() {
    return this.avatar$.asObservable();
  }

  set avatar(avatar: any) {
    this.avatar$.next(avatar);
  }
}
