<div class="hub-notifications-page container-fluid">
  <div class="hub-notifications-page-container">
    <div>
      <h3 class="line">
        <span>{{ 'notification.my-notifications' | translate | ucfirst }}</span>
      </h3>
    </div>

    <div *ngIf="isLoading" class="text-center">
      <app-hub-spinner-dots></app-hub-spinner-dots>
    </div>

    <div *ngIf="notifications.length > 0">
      <div *ngFor="let notification of notifications" class="notification-container">
        <hub-notification-card [notification]="notification"></hub-notification-card>
      </div>
    </div>
    <div *ngIf="notifications.length == 0" class="center">
      <span class="italic">{{ 'notification.no-notification-currently' | translate | ucfirst }}</span>
    </div>
  </div>
</div>
