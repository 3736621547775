export const environment = {
  production: false,
  qualification: false,
  integration: false,
  settings: {
    hubApi: {
      host: 'https://localhost:8443'
    },
    auth: {
      keycloak: {
        url: 'https://auth-agent-recette.hubentreprendre.laregion.fr/auth',
        realm: 'gdi-partenaire-recette-azure-region',
        clientId: 'fo-agent'
      },
      gdi: {
        host: 'https://auth-agent-recette.hubentreprendre.laregion.fr'
      }
    },
    file: {
      maxSize: '4000000'
    }
  }
};
