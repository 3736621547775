<div class="modal-header title">
  {{ 'add-actor.title' | translate | ucfirst }}
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="input-wrapper">
    <input class="form-control" type="text" placeholder="{{ 'add-actor.name-hint' | translate | ucfirst }}" #actor>
  </div>
  <div class="mb-5">
    <ul *ngIf="!isSearching && interlocutors">
      <li class="row"
          *ngFor="let interlocutor of interlocutors"
          (click)="addInterlocutor(interlocutor)"
          title="{{ 'add-actor.select-interlocutor' | translate | ucfirst }}">
        <div class="col-3 structure-logo d-flex">
          <img *ngIf="interlocutor?.structure?.logo?.id"
               src="{{ getLogoUrl(interlocutor?.structure?.logo) }}"
               alt="{{ 'information-card.logoInfo' | translate | ucfirst }}"/>
        </div>
        <div class="col-5 actor-name"><span>{{ interlocutor.firstname }} {{ interlocutor.lastname }}</span></div>
        <div class="col-4 roles">
          <span *ngIf="interlocutor.roles && interlocutor.roles.length > 0">
            {{ formatRoles(interlocutor) }}
          </span>
        </div>
      </li>
    </ul>
    <p *ngIf="!isSearching && interlocutors?.length === 0 && hasSearched" class="no-results">
      {{ 'add-actor.no-result' | translate | ucfirst }}
    </p>
    <div class="text-center mt-5" *ngIf="isSearching">
      <app-hub-spinner-dots></app-hub-spinner-dots>
    </div>
  </div>

  <div *ngIf="selectedInterlocutors.length > 0">
    {{ 'add-actor.selected-interlocutors' | translate | ucfirst }}
    <ng-select
      [items]="selectedInterlocutors"
      [ngModel]="selectedInterlocutors"
      bindLabel="name"
      [multiple]="true"
      [searchable]="false"
      [isOpen]="false"
      (remove)="removeInterlocutor($event)"
      (clear)="removeAllSelectedInterlocutors()">
    </ng-select>
  </div>

  <div *ngIf="existingActors && existingActors.length > 0" class="actual-team">
    <div>
      <span>{{ 'add-actor.actual-team' | translate | ucfirst }}</span>
    </div>
    <ul>
      <li *ngFor="let actor of existingActors">
        {{actor.firstname}} {{actor.lastname}}
      </li>
    </ul>
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn btn-secondary" (click)="modalRef.hide()">
      {{ 'add-actor.cancel' | translate | ucfirst }}
    </button>
    <button class="btn btn-primary ml-4" [disabled]="selectedInterlocutors.length == 0"
            (click)="onSubmit()">
      {{ 'add-actor.add' | translate | ucfirst }}
    </button>
  </div>

</div>



