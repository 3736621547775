import {Injectable} from '@angular/core';
import {KeycloakEvent, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {AgentService} from './services/hub-api/agent.service';
import {SessionService} from './services/session.service';
import {InterlocutorService} from './services/hub-api/interlocutor.service';
import {AuthService} from './services/auth.service';
import {LocalStorageService} from './services/local-storage.service';
import {MailService} from './services/hub-api/mail.service';
import {TranslateService} from '@ngx-translate/core';
import {HeaderService} from './services/header.service';
import {UpperCaseFirstPipe} from './pipes/ucfirst.pipe';
import {EmailParams} from './models/email-params.model';
import {TYPE_MAIL_ENUM} from './models/enum/type-mail.enum';

@Injectable()
export class AppInitService {

  constructor(protected keycloak: KeycloakService,
              protected agentService: AgentService,
              protected sessionService: SessionService,
              protected interlocutorService: InterlocutorService,
              protected authService: AuthService,
              protected localStorageService: LocalStorageService,
              protected mailService: MailService,
              protected translateService: TranslateService,
              protected headerService: HeaderService,
              protected ucfirst: UpperCaseFirstPipe) {
  }

  init() {
    return new Promise<any>(  (resolve, reject) => {
      try {
        this.initKeycloak();
        this.keycloak.keycloakEvents$.subscribe(
          async (event: KeycloakEvent) => {
            if (
              event.type === KeycloakEventType.OnAuthSuccess &&
              !this.sessionService.isAgentSet()
            ) {
              const userInfo : any = await this.keycloak.getKeycloakInstance().loadUserInfo();

              const { sub, email, given_name, family_name } = userInfo;
              let agent: any;
              try {
                agent = await this.agentService
                  .loadOrCreateAgent(sub, {
                    email,
                    firstname: given_name,
                    lastname: family_name,
                    isCharteApproved: false
                  })
              } catch (e) {
                await this.authService.logout();
                return resolve();
              }

              if (!agent.interlocutor) {
                try {
                  const interlocutor : any = await this.interlocutorService.getInterlocutorByEmail(email);

                  this.sessionService.setAgent({
                    id: agent.id,
                    keycloakid: sub,
                    email,
                    firstname: given_name,
                    lastname: family_name,
                    interlocutorid: interlocutor.id,
                    isCharteApproved: agent.isCharteApproved,
                    charteApprovalDate: agent.charteApprovalDate,
                    isReferent: interlocutor.isreferent
                  });

                  await this.agentService.put({
                    ...agent,
                    interlocutor: {
                      id: interlocutor.id
                    }
                  });
                  this.headerService.avatar = interlocutor.avatar
                    ? interlocutor.avatar.id
                    : undefined;

                  return resolve();
                } catch (e) {
                  // Ici on a pas trouvé l'interlocuteur
                  // On set le localStorage pour afficher la popup
                  // On envoie un mail à l'admin
                  // Et on déconnecte l'agent
                  try {
                    await this.handleInterlocutorNotFound(email);
                    await this.authService.logout();
                    return resolve();
                  } catch(e) {
                    await this.authService.logout();
                    return resolve();
                  }
                }

              } else {
                const userProfile : any = await this.keycloak.loadUserProfile();
                const interlocutor : any = await this.interlocutorService.getById(agent.interlocutor.id);

                this.sessionService.setAgent({
                  id: agent.id,
                  keycloakid: sub,
                  email,
                  firstname: given_name,
                  lastname: family_name,
                  phoneNumber:
                    ((userProfile as any).attributes.phoneNumber &&
                      (userProfile as any).attributes
                        .phoneNumber[0]) ||
                    '',
                  phoneNumber2:
                    ((userProfile as any).attributes.phoneNumber2 &&
                      (userProfile as any).attributes
                        .phoneNumber2[0]) ||
                    '',
                  interlocutorid: agent.interlocutor.id,
                  isCharteApproved: agent.isCharteApproved,
                  charteApprovalDate: agent.charteApprovalDate,
                  isReferent: interlocutor.referent
                });

                this.headerService.avatar = interlocutor.avatar
                  ? interlocutor.avatar.id
                  : undefined;

                return resolve();
              }
            } else if (
              event.type === KeycloakEventType.OnAuthSuccess &&
              this.sessionService.isAgentSet()
            ) {
              // Code déclenché par exemple quand on rafraichit la page, ou on arrive sur l'application déja connecté.
              // Il faut juste bien préciser l'avatar à afficher au header
              const agent : any = await this.sessionService.getAgent();
              const userProfile: any = await this.keycloak.loadUserProfile()
              const interlocutor: any = await this.interlocutorService.getById(agent.interlocutorid);

              this.headerService.avatar = interlocutor.avatar
                ? interlocutor.avatar.id
                : undefined;
              await this.interlocutorService.put({
                ...interlocutor,
                firstname: userProfile.firstName,
                lastname: userProfile.lastName,
                phonenumber:
                  ((userProfile as any).attributes.phoneNumber &&
                    (userProfile as any).attributes.phoneNumber[0]) ||
                  interlocutor.phonenumber,
                phonenumber2:
                  ((userProfile as any).attributes.phoneNumber2 &&
                    (userProfile as any).attributes.phoneNumber2[0]) ||
                  interlocutor.phonenumber2
              });
              // Il faut recharger les informations de session car les données peuvent avoir changé (modification de profil)
              // sans avoir besoin de se réauthentifier
              this.sessionService.setAgent({
                ...agent,
                phoneNumber:
                  ((userProfile as any).attributes.phoneNumber &&
                    (userProfile as any).attributes.phoneNumber[0]) ||
                  '',
                phoneNumber2:
                  ((userProfile as any).attributes.phoneNumber2 &&
                    (userProfile as any).attributes.phoneNumber2[0]) ||
                  '',
                firstname: userProfile.firstName,
                lastname: userProfile.lastName
              });

              const agentFromDb: any = await this.agentService.getById(agent.id);
              await this.agentService.put({
                ...agentFromDb,
                firstname: userProfile.firstName,
                lastname: userProfile.lastName
              });
              return resolve();
            } else {
              return resolve();
            }
          });
      } catch (error) {
        return reject(error);
      }
    });
  }

  initKeycloak() {
    return this.keycloak
      .init({
        config: environment.settings.auth.keycloak,
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false
        },
        bearerExcludedUrls: ['/']
      });
  }

  handleInterlocutorNotFound(email): Promise<any> {
    this.localStorageService.setLoginError(email);
    const emailParams = new EmailParams();
    emailParams.emailInterlocutor = email;

    return this.mailService
      .sendMailForType(
        TYPE_MAIL_ENUM.interlocutor_not_found,
        emailParams
      );
  }
}
