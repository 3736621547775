import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';
import { endpoints } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class AgentConsultationCompanyService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.agentConsultationCompany;
  }

  getByAgentAndCompany(agentId: string, companyid: string) {
    return this.api.get(
      `${this.endpoint}/agent/${agentId}/entreprise/${companyid}`
    );
  }

  getByAgent(agentId: string) {
    return this.api.get(`${this.endpoint}/agent/${agentId}`);
  }
}
