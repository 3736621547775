<div class="hub-home-page-connected">

  <div class="w-100">
    <hub-encart-extranet [encart]="encart"></hub-encart-extranet>
  </div>

  <div id="lienEspaceRdeo" class="w-100">
    <button class="btn btn-primary btn-lg" routerLink="/espace-rdeo" routerLinkActive="active">{{ 'home-connected.espace-rdeo' | translate | ucfirst }}</button>
  </div>

  <h1 class="line">
    <span>{{ 'home-connected.title' | translate | ucfirst }}</span>
  </h1>
  <hub-search (search)="goToSearchPage($event)" (tab)="switchTab($event)"></hub-search>

  <div *ngIf="areConsultationsLoading" class="hub-search-spinner">
    <app-hub-spinner-dots></app-hub-spinner-dots>
  </div>
  <app-hub-tabs *ngIf="!areConsultationsLoading && tab === 'contact'">
    <hub-tab titre="{{ 'home-connected.last-consult' | translate | ucfirst }}">
      <div *ngFor="let date of dates">
        <h3 class="line">
          <span><b>{{ date | frenchdate:'x' }}</b></span>
        </h3>
        <div *ngFor="let consult of listConsultationByDay(date)">
          <hub-company-card [entity]="consult.company" [agent_structure]="agent_structure"
                            [agent_interlocutor]="agent_interlocutor" (clickPageButton)="goToCompanyPage($event)"></hub-company-card>
        </div>
      </div>
    </hub-tab>
    <!--
    <hub-tab titre="{{ 'home-connected.favorite' | translate | ucfirst }}">

    </hub-tab>-->
  </app-hub-tabs>
</div>
