import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SliderMenuService } from 'src/app/services/slider-menu.service';

import { UserType } from '../../../../constants/type-object-database';
import { SessionService } from '../../../../services/session.service';
import { AgentAvatarService } from '../../../../services/hub-api/agent-avatar.service';
import { defaultAvatarUrl } from '../../../../constants';
import { HeaderService } from '../../../../services/header.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-header-connected',
  templateUrl: './header.connected.component.html',
  styleUrls: ['./header.connected.component.scss']
})
export class HeaderConnectedComponent implements OnInit {
  @Input()
  isStaticPage = false;
  user: UserType;
  avatarUrl: string = defaultAvatarUrl;

  constructor(
    private authService: AuthService,
    private router: Router,
    private sliderMenuService: SliderMenuService,
    private sessionService: SessionService,
    private agentAvatarService: AgentAvatarService,
    private headerService: HeaderService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getCurrentUser();
    this.headerService.avatar.subscribe(avatar => {
      this.avatarUrl = avatar
        ? this.agentAvatarService.getFileUrl({ id: avatar })
        : defaultAvatarUrl;
    });
  }

  toggleNav() {
    this.sliderMenuService.toggle();
  }

  goToLogin() {
    return this.authService.goToLogin();
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  logout() {
    return this.authService.logout();
  }
}
