import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import * as moment from 'moment';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';

import {CompanyService} from '../../../services/hub-api/company.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyDetailsComponent implements OnInit {
  @Input() id: string;

  modalRef: BsModalRef;
  companyData: any = {};
  companySiteData: any = {};
  extraitsRCS: any = {};
  mandataireSociaux: any = {};
  exercices: any = {};
  tvaIntracommunautaire: any = {};
  siret: string;

  constructor(
    private modalService: BsModalService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.companyService
      .getOne(this.id)
      .then((company: any) => {
        this.siret = company.identifier;
        Promise.all(this.getCompanyData()).then(() => {});
      })
      .catch(err => {});
  }

  /**
   * On va ici chercher les données dans l'api entreprise. On retourne un tableau de promise
   * pour être sur d'avoir tout récupérer avant de faire le traitement de ces données.
   */
  getCompanyData() {
    const allRequestsPromises = [];
    const siren = this.companyService.getSIRENFromSIRET(this.siret);

    /*
     * Lancement de l'appel entreprises
     */
    allRequestsPromises.push(
      Promise.resolve(
        this.companyService
          .getOneCompanyBySiren(siren)
          .then((response1: any) => {
            let companyName = {};
            // companyName initialisé en fonction du type (personne physique ou morale)
            if (response1.data.type === 'personne_morale') {
              companyName = { companyName: response1.data.personne_morale_attributs.raison_sociale };
            } else if (response1.data.type === 'personne_physique') {
              companyName = { companyName: this.constructionRaisonSocialePersonnePhysique(
                response1.data.personne_physique_attributs.pseudonyme,
                response1.data.personne_physique_attributs.prenom_1,
                response1.data.personne_physique_attributs.prenom_2,
                response1.data.personne_physique_attributs.prenom_3,
                response1.data.personne_physique_attributs.prenom_4,
                response1.data.personne_physique_attributs.nom_usage,
                response1.data.personne_physique_attributs.nom_naissance
              )};
            }
            this.companyData = Object.assign(
              this.companyData,
              response1.data
            );
            this.companyData = Object.assign(this.companyData, companyName);
          })
          .catch(err => {})
      )
    );

    /*
     * Lancement de l'appel etablissements
     */
    allRequestsPromises.push(
      Promise.resolve(
        this.companyService
          .getOneCompanyBySiret(this.siret)
          .then((response3: any) => {
            this.companySiteData = Object.assign(
              this.companySiteData,
              response3.data
            );
          })
          .catch(err => {})
      )
    );

    /*
     * Lancement de l'appel exercices
     */
    allRequestsPromises.push(
      Promise.resolve(
        this.companyService
          .getOneExerciceBySiret(this.siret)
          .then((response12: any) => {
            this.exercices = Object.assign(this.exercices, response12);
          })
          .catch(err => {})
      )
    );

    /*
     * Lancement de l'appel mandataires_sociaux
     */
    allRequestsPromises.push(
      Promise.resolve(
        this.companyService
          .getMandataireSociaux(siren)
          .then((response15: any) => {
            this.mandataireSociaux = Object.assign(
              this.mandataireSociaux,
              response15
            );
          })
          .catch(err => {})
      )
    );

    /*
     * Lancement de l'appel extraits_RCS
     */
    allRequestsPromises.push(
      Promise.resolve(
        this.companyService
          .getExtraitsRCS(siren)
          .then((response16: any) => {
            this.extraitsRCS = Object.assign(
              this.extraitsRCS,
              response16.data
            );
          })
          .catch(err => {})
      )
    );

    /*
     * Lancement de l'appel tva_intracommunautaire
     */
    allRequestsPromises.push(
      Promise.resolve(
        this.companyService
          .getTVAIntracommunautaire(siren)
          .then((response16: any) => {
            this.tvaIntracommunautaire = Object.assign(
              this.tvaIntracommunautaire,
              response16.data
            );
          })
          .catch(err => {})
      )
    );
    return allRequestsPromises;
  }

  getClass(accordion, panelId) {
    return accordion.isExpanded(panelId)
      ? 'fa-caret-up fa right red'
      : 'fa-caret-down fa right';
  }

  /**
   * Prends le timestamp et retourne la date au format jj/mm/aaaa
   *
   * @param dateString timestamp
   */
  formatDateFromTimestamp(dateString: number) {
    if (dateString !== null) {
      const date = moment.unix(dateString);
      return date.isValid() ? date.format('L') : '';
    }
  }

  /**
   * En v3 de l'API Entreprise, l'appel Entreprise voit apparaitre la distinction entre personne physique et personne moral
   * Cette fonction a pour but de construire la raison sociale des personnes physiques
   *
   * @param pseudonyme pseudonyme de la personne physique
   * @param prenom1 premier prenom de la personne physique
   * @param prenom2 deuxième prenom de la personne physique
   * @param prenom3 troisième prenom de la personne physique
   * @param prenom4 quatrième prenom de la personne physique
   * @param nomUsage nom d'usage de la personne physique
   * @param nomNaissance nom de naissance de la personne physique
   */
  constructionRaisonSocialePersonnePhysique(pseudonyme, prenom1, prenom2, prenom3, prenom4, nomUsage, nomNaissance) {
    let result = nomNaissance + '*';
    if (nomUsage !== null) {
      result = result + nomUsage;
    }
    result = result + '/';
    if (prenom1 !== null) {
      result = result + prenom1 + ' ';
    } if (prenom2 !== null) {
      result = result + prenom2 + ' ';
    } if (prenom3 !== null) {
      result = result + prenom3 + ' ';
    } if (prenom4 !== null) {
      result = result + prenom4 + ' ';
    } if (pseudonyme !== null) {
      result = result + pseudonyme + ' ';
    }
    result = result + '/';
    return result;
  }
}
