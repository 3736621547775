import { Component, Input, OnInit } from '@angular/core';
import * as isUUID from 'is-uuid';
import { PlanFileService } from 'src/app/services/hub-api/plan-file.service';
import validUrl from 'valid-url';
import {mimeTypes} from "../../constants";
import {HttpClient} from "@angular/common/http";
import { saveAs } from 'file-saver';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
})
export class DownloadButtonComponent implements OnInit {
  @Input() title: string;
  @Input() titleAccessibility: string;
  @Input() link: any = {};
  @Input() fileMimeType: string;
  @Input() updatedAt: number;
  fileName = '';
  fileUrl: any = false;
  faClass: string;

  constructor(private pdfPlanService: PlanFileService, private http: HttpClient) {}

  ngOnInit() {
    if (this.link && this.link.id && isUUID.anyNonNil(this.link.id)) {
      this.fileName = this.link.originalname;
      this.fileUrl = this.pdfPlanService.getFileUrl(this.link);
    }
    switch(this.fileMimeType) {
      case mimeTypes.pdf:
        this.faClass = 'fa-file-pdf';
        break;
      case mimeTypes.xls:
      case mimeTypes.xlsx:
      case mimeTypes.ods:
        this.faClass = 'fa-file-excel';
        break;
      case mimeTypes.doc:
      case mimeTypes.docx:
      case mimeTypes.odt:
        this.faClass = 'fa-file-word';
        break;
      case mimeTypes.ppt:
      case mimeTypes.pptx:
      case mimeTypes.odp:
        this.faClass = 'fa-file-powerpoint';
        break;
      case 'url':
        this.faClass= 'fa-link';
        break;
      default:
        this.faClass = 'file';
    }
  }

  isValidUri(uri) {
    return uri != null && uri !== '' && validUrl.isUri(uri) !== undefined;
  }

  saveFile() {
    this.http.get(this.fileUrl, {
      responseType: 'blob'
    }).toPromise().then(res => {
      const file = new Blob([res], { type: this.fileMimeType });
      saveAs(file, this.fileName);
    });
  }
}
