import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { interlocutorRoles } from '../constants';
import { InterlocutorService } from '../services/hub-api/interlocutor.service';
import { SessionService } from '../services/session.service';

@Injectable()
export class ReferentGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private sessionService: SessionService,
    private interlocutorService: InterlocutorService
  ) {
    super(router, keycloakService);
  }

  /**
   * If the user connected is not referent, we force the redirection to the home page '/'
   * Else all is ok :)
   */
  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async resolve => {
      if (!this.authenticated) {
        return resolve(true);
      }
      this.sessionService
        .getAgent()
        .then(agent => {
          this.interlocutorService
            .getinterlocutorRolesByEmail(agent.email)
            .then((interlocutorRolesValue: any) => {
              if (interlocutorRolesValue.includes(interlocutorRoles.referent)) {
                return resolve(true);
              } else {
                this.router.navigate(['/']);
                return resolve(false);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    });
  }
}
