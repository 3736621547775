<div class="hub-profile-page-container" *ngIf="currentAgent && currentInterlocutor">
  <h3 class="line">
    <span>{{ 'my-profile.title' | translate | ucfirst }}</span>
  </h3>
  <div class="hub-profile--main-content col-md-11">
    <!-- Ligne avatar + identité -->
    <div class="row mb-4">
      <!-- Avatar de l'utilisateur -->
      <div class="col-md-2 offset-md-1 avatar text-center">
        <div id="avatar-wrapper">
          <img [src]="agentAvatarUrl" id="agent-avatar" alt="Avatar de l'agent non trouvé">
        </div>
        <i class="fa fa-camera fa-2x" id="camera-icon" (click)="avatarUploader.click()"></i>
        <input type="file" id="agent-avatar-input" accept="image/png, image/jpeg" #avatarUploader
               [style.display]="'none'" (change)="updateAgentAvatar($event)">
        <div *ngIf="fileTooLarge" class="row">
          <div class="col-auto">
            <div class="alert alert-danger">
              {{ 'file-too-large' | translate | ucfirst }} {{ maxFileSize / 1000000}} Mo
            </div>
          </div>
        </div>
      </div>
      <!-- Identité de l'utilisateur (Nom prénom téléphone) -->
      <div class="col-md-8 offset-md-1 identity bordered orange pb-3">
        <div
          class="firstname-lastname mb-2 font-weight-bold">{{ currentAgent.firstname | ucfirst }} {{ currentAgent.lastname | uppercase }}</div>
        <div class="phone" *ngIf="currentAgent.phoneNumber || currentAgent.phoneNumber2">
          <i id="phone-icon" class="fa fa-volume-control-phone fa-3x orange mr-2"></i>
          <div class="number">
            <span *ngIf="currentAgent.phoneNumber">{{ currentAgent.phoneNumber | mask: '00 00 00 00 00' }}</span>
            <span *ngIf="currentAgent.phoneNumber2">{{ currentAgent.phoneNumber2 | mask: '00 00 00 00 00' }}</span>
          </div>
        </div>
        <button
          class="btn btn-hub btn-primary float-right"
          id="btn-modify-identity"
          [disabled]="!isModifyIdentityButtonActive"
          (click)="onClickModifyIdentityButton()">
          {{ 'my-profile.modify' | translate | ucfirst }}
        </button>
      </div>
    </div>
    <!-- Gestion de son statut (Absence) -->
    <div class="row mb-4">
      <div class="col-11 offset-1 bordered orange pb-3">
        <div class="main-color-text contact-me-title mb-2">{{ 'my-profile.my-state' | translate | uppercase }}</div>

        <div class="row">
          <!-- Etat (Active/Absence) -->
          <div class="col-2">
            <div *ngIf="isAbsent(); else notAbsent">
              <i class="far fa-times-circle missing pr-2"></i>
              <span>{{'my-profile.absence.missing' | translate | ucfirst}}</span>
            </div>
            <ng-template #notAbsent>
              <i class="far fa-check-circle at-work pr-2"></i>
              <span>{{'my-profile.absence.at-work' | translate | ucfirst}}</span>
            </ng-template>
          </div>

          <div class="col-10">

            <!-- informations -->
            <div class="row">
              <div *ngIf="hasAbsence() && !modifyingAbsence">
                <div class="p-0">
                  <div>
                    {{'my-profile.absence.missing-from'  | translate | ucfirst}} <span
                    class="absence-info bold text">{{currentInterlocutor.debutAbsence}} </span>
                    {{'my-profile.absence.missing-to'  | translate }} <span
                    class="absence-info bold text">{{currentInterlocutor.finAbsence}} </span>
                  </div>
                  <div>
                    {{'my-profile.absence.backup'  | translate | ucfirst}}
                    <span *ngIf="currentInterlocutor.backup"
                          class="absence-info bold text">{{currentInterlocutor.backup.firstname}} {{currentInterlocutor.backup.lastname}} </span>
                    <span *ngIf="currentInterlocutor.telBackup"
                          class="absence-info bold text">{{currentInterlocutor.telBackup}}</span>
                  </div>
                </div>
              </div>
            </div>

            <form (ngSubmit)="isValidForm() && submitAbsenceForm()" [formGroup]="absenceForm">
              <!-- gestion -->
              <div class="row">

                <!-- form -->
                <div class="col-12">
                  <div *ngIf="modifyingAbsence">
                    <div class="row mt-2">
                      <div class="col">
                        <div class="bold">
                          {{'my-profile.absence.form.periode' | translate | ucfirst}}
                        </div>
                        <div class="row align-items-center mt-2">
                          <div
                            class="col-2 text-right text">{{'my-profile.absence.form.start-date' | translate | ucfirst}}</div>
                          <div class="col-4">
                         <span>
                             <i class="fa fa-2x fa-calendar pointer float-icon" (click)="sd.toggle()"></i>
                        </span>
                            <div class="side-input">
                              <input class="form-control date-input default-cursor" formControlName="startDate"
                                     ngbDatepicker #sd="ngbDatepicker" readonly>
                            </div>
                          </div>
                          <div class="col-2 text-right text">
                            {{'my-profile.absence.form.end-date' | translate | ucfirst}}
                          </div>
                          <div class="col-4">
                        <span>
                            <i class="fa fa-2x fa-calendar pointer float-icon" (click)="ed.toggle()"></i>
                        </span>
                            <div class="side-input">
                              <input class="form-control date-input default-cursor" formControlName="endDate"
                                     ngbDatepicker #ed="ngbDatepicker" readonly [minDate]="todayDate">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!isAfter()" class="row align-items-center justify-content-center mt-2">
                      <div class="col-6 text-center error-box">
                  <span
                    class="error-text text">{{'my-profile.absence.form.form-error.date-error' | translate | ucfirst}}</span>
                      </div>
                    </div>

                    <div class="row mt-2 mb-2">
                      <div class="col">
                        <div class="bold">
                          {{'my-profile.absence.form.backup' | translate | ucfirst}}
                        </div>

                        <div class="row align-items-baseline mt-2">
                          <div class="col-3">
                            <label for="physical" class="radio-button-container text">
                              {{'my-profile.absence.form.physical' | translate | ucfirst}}
                              <input type="radio" class="pointer" id="physical" formControlName="physical"
                                     [value]="true" (change)="onChangeRadioButton()">
                              <span class="radio-button-checkmark"></span>
                            </label>
                          </div>
                          <div class="col-4">
                            <label for="ownStructure" class="checkbox-container text"
                                   [ngClass]="{'default-cursor': !absenceForm.get('physical').value}">
                              {{'my-profile.absence.form.own-structure' | translate | ucfirst}}
                              <input type="checkbox" class="pointer" id="ownStructure" value="true"
                                     formControlName="ownStructure"
                                     (change)="onChangeOwnStructureCheckbox($event)">
                              <span class="checkmark"
                                    [ngClass]="{'checkmark-unclickable': !absenceForm.get('physical').value}"></span>
                            </label>
                          </div>
                          <div class="col-5">
                            <ng-select [items]="interlocutorsList" [multiple]="false" [closeOnSelect]="true"
                                       [hideSelected]="true"
                                       class="ng-select"
                                       bindLabel="lastname"
                                       bindValue="id" placeholder="Prénom NOM" formControlName="backup"
                                       clearAllText="{{ 'form.none' | translate | ucfirst }}">
                              <ng-template ng-option-tmp let-item="item">
                                <div
                                  title="{{ item.firstname }} {{ item.lastname }}">{{ item.firstname }} {{ item.lastname }}</div>
                              </ng-template>
                              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-icon left" (click)="clear(item)"
                                              aria-hidden="true">×</span>
                                <div
                                  title="{{ item.firstname }} {{ item.lastname }}">{{ item.firstname }} {{ item.lastname }}</div>
                              </ng-template>
                            </ng-select>
                          </div>
                        </div>
                        <div class="row align-items-baseline mt-2">
                          <div class="col-6">
                            <label for="phone" class="radio-button-container text">
                              {{'my-profile.absence.form.phone-number' | translate | ucfirst}}
                              <input type="radio" class="pointer" id="phone" formControlName="physical"
                                     [value]="false" (change)="onChangeRadioButton()">
                              <span class="radio-button-checkmark"></span>
                            </label>
                          </div>
                          <div class="col-6">
                            <input type="tel" class="phone-input" formControlName="telBackup"
                                   placeholder="00 00 00 00 00"
                                   pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}"
                                   maxlength="14"
                                   [ngClass]="{'default-cursor': absenceForm.get('physical').value}"
                                   [dropSpecialCharacters]="false"
                                   mask="00 00 00 00 00">
                            <div *ngIf="absenceForm.get('telBackup').dirty && absenceForm.get('telBackup').invalid"
                                 class="ml-2 error-box">
                                    <span
                                      class="error-text text">{{'my-profile.absence.form.form-error.tel-error' | translate | ucfirst}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- bouton -->
              <div class="row justify-content-end pr-3">
                <button *ngIf="modifyingAbsence" type="button" class="btn btn-hub btn-secondary mr-2"
                        (click)="cancelModification()">
                  {{'my-profile.cancel' | translate | ucfirst}}
                </button>
                <input *ngIf="modifyingAbsence"
                       type="submit"
                       class="btn btn-hub btn-warning"
                       [disabled]="!isValidForm()"
                       value="Enregistrer"
                >
                <button *ngIf="!modifyingAbsence && hasAbsence()" type="button" class="btn btn-link link-color"
                        (click)="removeAbsencePeriode()">
                  {{'my-profile.absence.remove-absence' | translate | ucfirst}}
                </button>
                <button *ngIf="!modifyingAbsence" type="button"
                        [disabled]="!isModifyAbsenceButtonActive"
                        class="btn btn-hub btn-primary"
                        (click)="onClickOnModification()">
                  {{'my-profile.modify' | translate | ucfirst}}
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>

    <!-- Ligne structure + poste -->
    <div class="row mb-4">
      <!-- Structure de l'utilisateur -->
      <div class="col-md-5 offset-md-1 structure bordered grey p-4">
        <div class="mb-4 font-italic">{{ currentInterlocutor.structure?.name }}</div>
        <div><i class="fa fa-envelope mr-4"></i><span class="font-italic">{{ currentAgent.email }}</span></div>
      </div>
      <!-- Poste de l'utilisateur -->
      <div class="col-md-5 offset-md-1 occupation bordered orange pl-4 pt-4 pb-3">
        <div
          class="mb-2 main-color-text my-occupation-title">{{ 'my-profile.my-occupation' | translate | uppercase }}</div>
        <div *ngIf="sectionsState.occupation === STATES.READ">
          <div>{{ currentInterlocutor.occupation?.label }}</div>
          <div class="float-right">
            <button
              id="btn btn-modify-occupation"
              class="btn btn-hub btn-primary"
              [disabled]="!isModifyOccupationButtonActive"
              (click)="onClickModifyOccupationButton()">
              {{ 'my-profile.modify' | translate | ucfirst }}
            </button>
          </div>
        </div>
        <div *ngIf="sectionsState.occupation === STATES.MODIFY">
          <div>
            <ng-select
              [items]="occupations"
              [(ngModel)]="selectedOccupationId"
              [clearable]="false"
              bindLabel="label"
              bindValue="id">
              <ng-template ng-option-tmp let-item="item">
                <span title="{{ item.label }}">{{ item.label }}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="float-right">
            <button
              class="btn btn-hub btn-secondary mr-1"
              id="btn-cancel-occupation-modify"
              (click)="onClickCancelOccupationModification()">
              {{ 'my-profile.cancel' | translate | ucfirst }}
            </button>
            <button
              class="btn btn-hub btn-primary"
              id="btn-save-occupation"
              (click)="updateOccupation()">
              {{ 'my-profile.save' | translate | ucfirst }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact de l'utilisateur -->
    <div class="row mb-4">
      <div class="col-md-11 offset-md-1 bordered orange pb-3">
        <div class="main-color-text contact-me-title mb-2">{{ 'my-profile.contact-me' | translate | uppercase }}</div>
        <div *ngIf="sectionsState.contact === STATES.READ">
          <div class="mb-2"><img src="assets/icons/skype.png" class="mr-2">
            <span *ngIf="currentInterlocutor.skype; else noskype" class="mb-2">{{ currentInterlocutor.skype }}</span>
            <ng-template #noskype>{{ 'my-profile.not-defined' | translate | ucfirst }}</ng-template>
          </div>
          <div><img src="assets/icons/linkedin.png" style="vertical-align: bottom" class="mr-2">
            <span *ngIf="currentInterlocutor.linkedin; else nolinkedin">{{ currentInterlocutor.linkedin }}</span>
            <ng-template #nolinkedin>{{ 'my-profile.not-defined' | translate | ucfirst }}</ng-template>
          </div>
          <div class="float-right">
            <button
              id="btn-modify-contact"
              class="btn btn-hub btn-primary"
              [disabled]="!isModifyContactButtonActive"
              (click)="onClickModifyContactButton()">{{ 'my-profile.modify' | translate | ucfirst }}</button>
          </div>
        </div>
        <div *ngIf="sectionsState.contact === STATES.MODIFY">
          <form>
            <div class="form-group">
              <label for="skypeInput">Contact Skype</label>
              <input type="text" id="skypeInput" class="form-control shadow white col-md-5"
                     [(ngModel)]="skypeInputValue" [ngModelOptions]="{standalone: true}"/>
            </div>
            <div class="form-group">
              <label for="linkedinInput">URL Linkedin</label>
              <input type="text" id="linkedinInput" class="form-control shadow white col-md-5"
                     [(ngModel)]="linkedinInputValue" [ngModelOptions]="{standalone: true}"/>
            </div>
          </form>
          <div class="float-right">
            <button
              id="btn-cancel-contact-modify"
              class="btn btn-hub btn-secondary mr-1"
              (click)="onClickCancelContactModification()">
              {{ 'my-profile.cancel' | translate | ucfirst }}
            </button>
            <button
              id="btn-save-contact"
              class="btn btn-hub btn-primary"
              (click)="updateContactLinks()">
              {{ 'my-profile.save' | translate | ucfirst }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- A propos de l'utilisateur -->
    <div *ngIf="isReferent || isExpert" class="row mb-4">
      <div class="col-md-11 offset-md-1 bordered orange pt-1 pb-3">
        <div class="about-me-title main-color-text">{{ 'my-profile.my-specialities' | translate | uppercase }}</div>
        <div class="font-italic">{{ 'my-profile.my-specialities-explanations' | translate | ucfirst }}</div>
        <div
          class="font-italic mb-2">{{ 'my-profile.my-specialities-explanations-subtext' | translate | ucfirst }}</div>
        <div class="row">
          <div
            class="col-md-4"
            (click)="onLeaveAboutMeSection(aboutMeSections.SPECIALITIES)">
            <img class="about-me--img" [class.img-selected]="aboutMeSectionDisplayed === aboutMeSections.SPECIALITIES"
                 title="Domaines de compétences" src="assets/images/domaine-competences.jpg"/>
          </div>
          <div class="col-md-4"
               (click)="onLeaveAboutMeSection(aboutMeSections.ACTIVITY_AREAS)">
            <img class="about-me--img" [class.img-selected]="aboutMeSectionDisplayed === aboutMeSections.ACTIVITY_AREAS"
                 title="filières" src="assets/images/filieres.jpg"/>
          </div>
          <div
            class="col-md-4"
            (click)="onLeaveAboutMeSection(aboutMeSections.TERRITORIES)">
            <img class="about-me--img" [class.img-selected]="aboutMeSectionDisplayed === aboutMeSections.TERRITORIES"
                 title="territoires" src="assets/images/territoires.jpg"/>
          </div>
        </div>
        <div class="shadow white" *ngIf="aboutMeSectionDisplayed">

          <!-- Filières -->
          <div *ngIf="aboutMeSectionDisplayed === aboutMeSections.ACTIVITY_AREAS">
            <div *ngIf="activityAreasToDisplay && activityAreasToDisplay.length > 0;else noActivityAreasBlock"
                 class="d-flex flex-wrap align-content-around mb-3 list-items">
              <div *ngFor="let activityArea of activityAreasToDisplay" class="mr-3 list-item">
                <span class="mr-1">{{ activityArea.label }}</span>
              </div>
            </div>
            <ng-template #noActivityAreasBlock>
              <div class="mb-5">
                <span class="font-italic">{{ 'my-profile.no-activity-areas' | translate | ucfirst }}</span>
              </div>
            </ng-template>
            <div class="font-italic text-right">{{ 'my-profile.role-administrator' | translate | ucfirst }}</div>
          </div>

          <!-- Domaines de compétences -->
          <div *ngIf="aboutMeSectionDisplayed === aboutMeSections.SPECIALITIES">
            <div *ngIf="specialitiesToDisplay && specialitiesToDisplay.length > 0;else noSpecialitiesBlock"
                 class="d-flex flex-wrap align-content-around mb-3 list-items">
              <div *ngFor="let speciality of specialitiesToDisplay" class="mr-3 list-item">
                {{ speciality.label }}
              </div>
            </div>
            <ng-template #noSpecialitiesBlock>
              <div class="mb-5">
                <span class="font-italic">{{ 'my-profile.no-specialities' | translate | ucfirst }}</span>
              </div>
            </ng-template>
            <div class="font-italic text-right">{{ 'my-profile.role-administrator' | translate | ucfirst }}</div>
          </div>

          <!-- Territoires -->
          <div *ngIf="aboutMeSectionDisplayed === aboutMeSections.TERRITORIES">
            <div class="d-flex flex-wrap align-content-around mb-3 list-items">
              <div *ngFor="let territory of territoriesToDisplay" class="mr-3 list-item">
                ({{ territory.label }}) {{ territory.departments }}
                <span *ngIf="sectionsState.territory === STATES.MODIFY" class="remove-item-symbol" aria-hidden="true"
                      (click)="onRemoveTerritory(territory)">&times;</span>
              </div>
              <div class="col-md-3" style="align-self: center;" *ngIf="sectionsState.territory === STATES.MODIFY">
                <ng-select
                  *ngIf="territoriesSelect && territoriesSelect.length > 0"
                  [clearable]="false"
                  (change)="onAddTerritory($event)"
                  placeholder="Ajouter un territoire">
                  <ng-option *ngFor="let territory of territoriesSelect"
                             [value]="territory"
                  >({{ territory.label }}) {{ territory.departments }}
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ "Ajouter un territoire" }}</span>
                    </ng-template>
                  </ng-option>
                  <ng-template ng-option-tmp let-item="item">
                    <span title="({{ item.label }}) {{ item.departments }}">({{ item.label }}
                      ) {{ item.departments }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div *ngIf="territoriesSelect.length === 0 && sectionsState.territory === STATES.MODIFY">
              {{ 'my-profile.empty-territories-list' | translate | ucfirst }}
            </div>
            <div>
              <div class="d-flex flex-row-reverse" *ngIf="sectionsState.territory === STATES.READ">
                <button id="btn-modify-territories" class="btn btn-hub btn-primary"
                        [disabled]="!isModifyTerritoriesButtonActive"
                        (click)="onClickModifyTerritory()">{{ 'my-profile.modify' | translate | ucfirst }}</button>
              </div>
              <div class="d-flex flex-row-reverse" *ngIf="sectionsState.territory === STATES.MODIFY">
                <button
                  id="btn-save-territories"
                  class="btn btn-hub btn-primary"
                  (click)="saveTerritories()">
                  {{ 'my-profile.save' | translate | ucfirst }}
                </button>
                <button
                  id="btn-cancel-territories-modify"
                  class="btn btn-hub btn-secondary mr-1"
                  (click)="onClickCancelTerritoriesModification()">
                  {{ 'my-profile.cancel' | translate | ucfirst }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Rôles de l'utilisateur -->
    <div class="row">
      <div class="col-md-11 offset-md-1 bordered grey pb-2">
        <div class="my-role-title mb-2">{{ 'my-profile.my-role-in-the-hub' | translate | uppercase }}</div>
        <div class="font-italic">{{ 'my-profile.role-administrator' | translate | ucfirst }}</div>
        <div class="d-flex justify-content-around">
          <span><input type="checkbox" disabled
                       [checked]="isReferent"/> {{ 'my-profile.role.referent' | translate | uppercase }}</span>
          <span><input type="checkbox" disabled
                       [checked]="isExpert"/> {{ 'my-profile.role.expert' | translate | uppercase }}</span>
          <span><input type="checkbox" disabled
                       [checked]="isSpecialist"/> {{ 'my-profile.role.specialist' | translate | uppercase }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
