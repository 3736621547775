import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';
import { endpoints } from '../../constants/index';

@Injectable()
export class UserService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.user;
  }

  findByProjectId(projectId) {
    return this.api.get(`${this.endpoint}/project/${projectId}`);
  }
}
