<div class="hub-host container-fluid mt-3" *ngIf="areDataLoaded">
  <hub-company-card [agent_structure]="agent_structure" [entity]="entity" [showPageButton]="false">
  </hub-company-card>
  <!-- Onglets à afficher lorsque l'entité est une entreprise -->
  <app-hub-tabs theme="red" *ngIf="entity.type === entityTypes.company">
    <hub-tab id=0 *ngIf="!isJustActor" titre="{{ 'company-details.tabs.contacts' | translate | ucfirst }}">
      <div class="row justify-content-between">
        <div class="col-md-12">
          <hub-contact-details [siret]="entity.identifier" [(company)]="entity"></hub-contact-details>
        </div>
      </div>
    </hub-tab>

    <hub-tab id=1 titre="{{ 'company-details.tabs.company-data' | translate | ucfirst }}" [selected]="isJustActor">
      <div class="row justify-content-between">
        <div class="col-md-8">
          <hub-company-details [id]="id" *ngIf="id"></hub-company-details>
        </div>
        <div class="col-md-4">
          <hub-referer-card [referer]="interlocutor" title="{{ 'referent' | translate |ucfirst }}" [user]="agent">
          </hub-referer-card>
        </div>
      </div>
    </hub-tab>

    <hub-tab id=2 titre="{{ 'company-details.tabs.projects' | translate | ucfirst }}" [selected]="selectedTab === 2">
      <ng-container *ngTemplateOutlet="projectsTemplate"></ng-container>
    </hub-tab>

    <hub-tab id=3 *ngIf="!isJustActor && subventions"
             titre="{{ 'company-details.tabs.subventions' | translate | ucfirst }}">
      <div *ngFor="let subvention of subventions" class="mb-5">
        <app-hub-subvention-card [subvention]="subvention"></app-hub-subvention-card>
      </div>
      <div *ngIf="!subventions.length" class="text-center mt-5">
        {{ 'company-details.no-subventions' | translate | ucfirst }}
      </div>
    </hub-tab>

    <hub-tab id=4 titre="{{ 'company-details.tabs.navigation' | translate | ucfirst }}" [selected]="selectedTab === 4">
      <ng-container *ngTemplateOutlet="navigationHistoryTemplate"></ng-container>
    </hub-tab>

  </app-hub-tabs>

  <!-- Onglets à afficher lorsque l'entité est un porteur de projets -->
  <app-hub-tabs theme="red" *ngIf="entity.type === entityTypes.project">
    <hub-tab id=0 titre="{{ 'project-details.tabs.general-infos' | translate | ucfirst }}">
      <div class="row mt-4">
        <div class="col-6">
          <div class="mb-4">
            <h3 class="text-left mb-1 p-0">{{ 'project-details.project-holder-data' | translate | ucfirst }}</h3>
            <div><span class="bold">{{ 'project-details.identity' | translate | ucfirst }}</span>
              :  {{ gdiProjectOwner.lastName }} {{ gdiProjectOwner.firstName | capitalizeFirst }}</div>
            <div><span class="bold">{{ 'phone' | translate | ucfirst }}</span> : {{ gdiProjectOwner.phone }}</div>
            <div><span class="bold">{{ 'email' | translate | ucfirst }}</span> : {{ gdiProjectOwner.email }}</div>
          </div>
          <div>
            <h3 class="text-left mb-1 p-0">{{ 'project-details.project-data' | translate | ucfirst }}</h3>
            <div><span class="bold">{{ 'project-details.project-description' | translate | ucfirst }}</span>
              : {{ entity.name }}</div>
            <div><span class="bold">Filière(s)</span> : {{ concatActivityAreas() }}</div>
          </div>
        </div>
        <div class="col-4 offset-1" *ngIf="interlocutor">
          <hub-referer-card
            [referer]="interlocutor"
            [title]="'Référent'"
            [user]="agent">
          </hub-referer-card>
        </div>
      </div>
    </hub-tab>

    <hub-tab id=2 titre="{{ 'company-details.tabs.projects' | translate | ucfirst }}">
      <nng-container *ngTemplateOutlet="projectsTemplate"></nng-container>
    </hub-tab>

    <hub-tab id=4 titre="{{ 'company-details.tabs.navigation' | translate | ucfirst }}" [selected]="selectedTab === 4">
      <ng-container *ngTemplateOutlet="navigationHistoryTemplate"></ng-container>
    </hub-tab>

  </app-hub-tabs>

  <!-- Template pour éviter de dupliquer l'onglet des projets -->
  <ng-template #projectsTemplate>
    <div class="projects-container">
      <div *ngIf="projects && projects.length === 0 && !projectIsLoading" class="center">
        <span>{{ 'my-projects.no-project-saved' | translate | ucfirst }}</span>
      </div>
      <div *ngIf="projectIsLoading" class="hub-search-spinner">
        <app-hub-spinner-dots></app-hub-spinner-dots>
      </div>
      <div *ngIf="qualifiedProjects && qualifiedProjects.length > 0">
        <div>
          <h5 class="pointer" (click)="showQualifiedProjects = !showQualifiedProjects">
              <span>
                {{ 'my-projects.qualified-title' | translate | ucfirst }} ({{ qualifiedProjects.length }})
                <i [ngClass]="showQualifiedProjects ? 'fa-angle-up' : 'fa-angle-down'" class="fa right"></i>
              </span>
          </h5>
        </div>
        <div *ngIf="showQualifiedProjects">
          <div *ngFor="let project of qualifiedProjects" class="project-row">
            <div class="mb-4">
              <p>
                <span class="bold">{{ 'tree.my-project' | translate | ucfirst }} {{ project.name }}</span>
                <span *ngIf="project.updatedat" class="mr-4"> {{ 'my-projects.saved-at' | translate | ucfirst }}
                  {{ project.updatedat | dateFormat:dateFormat }}
                  {{ 'my-projects.step' | translate | ucfirst }} {{ formatStep(project) }}</span>
                <span class="pointer" *ngIf="project.isExistingActors">
                  <i class="fa fa-users fa-2x" title="{{ 'my-projects.team' | translate | ucfirst }}"></i>
                  <span class="project-team" title="{{ 'my-projects.team' | translate | ucfirst }}">
                    {{ 'my-projects.project-team' | translate | ucfirst }} {{ project.actors.length + 1 + ' ' }}
                    {{ 'my-projects.interlocutors' | translate }}
                  </span>
                  <hub-dropdown [classToAppend]="'actors-dropdown'" [key]="project.id">
              <div>
                <ul class="actors-list" style="padding: 0; list-style-type: none">
                  <li>
                    <div *ngIf="isReferent; else elseBlock">
                          <span>
                            {{ 'my-projects.you' | translate | ucfirst }}
                          </span>
                      <span class="italic">
                            {{ 'my-projects.referer' | translate | ucfirst }}
                          </span>
                    </div>
                    <ng-template #elseBlock>
                      <div>
                            <span>
                              {{ entity.interlocutor?.firstname }} {{ entity.interlocutor?.lastname }}
                            </span>
                        <span class="italic">
                              {{ 'my-projects.referer' | translate | ucfirst }}
                            </span>
                      </div>
                    </ng-template>
                  </li>

                  <li *ngFor="let actor of project.actors">
                    <span>{{ actor.firstname }} {{actor.lastname }}</span>
                    <i *ngIf="isReferent"
                       title="{{ 'my-projects.delete-actor' | translate | ucfirst }}"
                       class="far fa-times-circle fa-lg"
                       (click)="removeActor(project, actor)">
                    </i>
                  </li>
                </ul>
              </div>
              </hub-dropdown>
              </span>
              <span class="pull-right pointer" *ngIf="isReferent" (click)="onAddActorClick(project)">
                  <i class="fa fa-user-plus fa-2x"
                     title="{{ 'my-projects.add-interlocutor' | translate | ucfirst }}"></i>
                </span>
              </p>
            </div>
            <div *ngIf="project.description" class="mb-4 description-container">
              <p> {{project.description}}</p>
            </div>
            <div class="row project">
              <div class="col-md-6">
                <p class="bold center">{{ 'my-projects.summary' | translate | ucfirst }}</p>
                <hub-project-summary [steps]="getRun(project)"></hub-project-summary>
              </div>
              <div class="project-plans-separator col-md-1">
              </div>
              <div class="col-md-5 test">
                <p class="bold center">{{ 'my-projects.one-click-solutions' | translate | ucfirst }}</p>
                <div *ngFor="let plan of project.plans">
                  <hub-link-card name="current-projects-red" title="{{ plan.name }}" themes="red"
                                 caption="{{ plan.caption }}"></hub-link-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="inProgressProjects && inProgressProjects.length > 0">
        <div>
          <h5 class="pointer" (click)="showInProgressProjects = !showInProgressProjects">
              <span>
                {{ 'my-projects.in-progress-title' | translate | ucfirst }} ({{ inProgressProjects.length }})
                <i [ngClass]="showInProgressProjects ? 'fa-angle-up' : 'fa-angle-down'" class="fa right"></i>
              </span>
          </h5>
        </div>
        <div *ngIf="showInProgressProjects">
          <div *ngFor="let project of inProgressProjects" class="project-row">
            <div>
              <p>
                <span class="bold">{{ 'tree.my-project' | translate | ucfirst }} {{ project.name }}</span>
                <span *ngIf="project.updatedat" class="mr-4"> {{ 'my-projects.saved-at' | translate | ucfirst }}
                  {{ project.updatedat | dateFormat:dateFormat }}
                  {{ 'my-projects.step' | translate | ucfirst }} {{ formatStep(project) }}</span>
                <span class="pointer" *ngIf="project.isExistingActors">
                  <i class="fa fa-users fa-2x" title="{{ 'my-projects.team' | translate | ucfirst }}"></i>
                  <span class="project-team">
                    {{ 'my-projects.project-team' | translate | ucfirst }} {{ project.actors.length + 1 + ' ' }}
                    {{ 'my-projects.interlocutors' | translate }}
                  </span>
                  <hub-dropdown [classToAppend]="'actors-dropdown'" [key]="project.id">
              <div>
                <ul class="actors-list" style="padding: 0; list-style-type: none">
                  <li>
                    <div *ngIf="isReferent; else elseBlock">
                          <span>
                            {{ 'my-projects.you' | translate | ucfirst }}
                          </span>
                      <span class="italic">
                            {{ 'my-projects.referer' | translate | ucfirst }}
                          </span>
                    </div>
                    <ng-template #elseBlock>
                      <div>
                            <span>
                              {{ entity.interlocutor?.firstname }} {{ entity.interlocutor?.lastname }}
                            </span>
                        <span class="italic">
                              {{ 'my-projects.referer' | translate | ucfirst }}
                            </span>
                      </div>
                    </ng-template>
                  </li>

                  <li *ngFor="let actor of project.actors">
                    <span>{{ actor.firstname }} {{actor.lastname }}</span>
                    <i *ngIf="isReferent"
                       title="{{ 'my-projects.delete-actor' | translate | ucfirst }}"
                       class="far fa-times-circle fa-lg"
                       (click)="removeActor(project, actor)">
                    </i>
                  </li>
                </ul>
              </div>
              </hub-dropdown>
              </span>
              <span class="pull-right pointer" *ngIf="isReferent" (click)="onAddActorClick(project)">
                  <i class="fa fa-user-plus fa-2x"
                     title="{{ 'my-projects.add-interlocutor' | translate | ucfirst }}"></i>
                </span>
              </p>
            </div>
            <div class="row project">
              <div class="col-md-6">
                <p class="bold center">{{ 'my-projects.summary' | translate | ucfirst }}</p>
                <hub-project-summary [steps]="getRun(project)"></hub-project-summary>
              </div>
              <div class="project-plans-separator col-md-1">
              </div>
              <div class="col-md-5 test">
                <p class="bold center">{{ selectChoice(project).title }}</p>
                <div *ngFor="let topic of selectChoice(project).topics">
                  <hub-link-card
                    cssClass="no-pointer green-background inprogress"
                    name="current-projects-red" themes="red"
                    caption="{{ topic.title }}">>
                  </hub-link-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Template pour éviter de dupliquer l'onglet de l'historique de navigation -->
  <ng-template #navigationHistoryTemplate>
    <ngb-accordion #historyAccordion>
      <ngb-panel *ngFor="let historyItem of navigationHistory | keyvalue: historySortFunction; let i = index"
                 [id]="i.toString()">
        <ng-template ngbPanelTitle>
          <span>{{ historyItem.key | frenchdate:navigationHistoryDateItemFormat }}</span><i
          [ngClass]="getClass(historyAccordion, i.toString()) "></i>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="mb-1" *ngFor="let plan of historyItem.value">
            <hub-plan-card-search [plan]="plan"></hub-plan-card-search>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-template>
</div>
