import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { interlocutorRoles } from '../constants';
import { InterlocutorService } from '../services/hub-api/interlocutor.service';
import { SessionService } from '../services/session.service';
import {AgentService} from '../services/hub-api/agent.service';
import {Observable} from 'rxjs';

@Injectable()
export class CharteQualityGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    protected agentService: AgentService,
    protected interlocutorService: InterlocutorService,
    private sessionService: SessionService
  ) {
  }

  /**
   * If the user connected is a referent, and he has not validate quality charte we force the redirection to the quality charte
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const isLoggedIn: boolean = await this.keycloakService.isLoggedIn();
      if (!isLoggedIn) {
        return resolve(true);
      } else {
        const isCharteApproved = await this.isCharteApprovedForConnectedAgentAndIsReferent();

        if (!isCharteApproved) {
          await this.router.navigate(['/charte-qualite']);
          return resolve(false);
        } else {
          return resolve(true);
        }
      }
    });
  }

  async isCharteApprovedForConnectedAgentAndIsReferent() {
    const agentSession: any = await this.sessionService.getAgent();
    if (!agentSession) {
      const userInfo: any = await this.keycloakService.getKeycloakInstance().loadUserInfo();
      const keycloakId = userInfo.sub;
      const agentInDb : any = await this.agentService.getAgent(keycloakId);
      if (agentInDb) {
        if (!agentInDb.interlocutor) {
          const interlocutor : any = await this.interlocutorService.getInterlocutorByEmail(agentInDb.email);
          if (!interlocutor) {
            return false;
          } else {
            return this.charteMustNotBeShown(agentInDb, interlocutor);
          }
        }
        return this.charteMustNotBeShown(agentInDb, agentInDb.interlocutor);
      } else {
        return false;
      }
    } else {
      return !agentSession.isReferent || (agentSession.isCharteApproved && agentSession.isReferent);
    }
  }

  charteMustNotBeShown(agent, interlocutor) {
    return !interlocutor.referent || (interlocutor.referent && agent.isCharteApproved);
  }
}
