import { AfterContentInit, ContentChildren, EventEmitter, Input, Output, QueryList, Directive } from '@angular/core';
import {TabComponent} from 'src/app/components/tab/tab.component';
import {ContactTypeEnum} from '../../constants';

/**
 * Classe abstraite permettant plusieurs implémentation graphiques
 * de système d'onglet (notamment car il y a 2 styles très différents d'onglets)
 */
@Directive()
export abstract class AbstractTabs implements AfterContentInit {
  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;
  @Input()
  theme = '';
  @Input()
  isCompanyTabs = false;
  @Input()
  isAlreadyInitialized = false;
  @Input()
  currentTab : ContactTypeEnum = ContactTypeEnum.ALL_RESULTS;
  @Output()
  idSelected = new EventEmitter<number>();

  // contentChildren are set
  ngAfterContentInit() {
    // get all selected tabs
    const activeTabs = this.tabs.filter(tab => tab.selected);

    // if there is no selected tab set, activate the first
    if (activeTabs.length === 0) {
      if (!this.isCompanyTabs) {
        this.selectTab(this.tabs.first);
      } else {
        if (!this.isAlreadyInitialized) {
          this.selectTab(this.tabs.first);
        } else {
          // We need to set selected tab otherwise at every change in pagination the tabs are reload and set to the first one
          let selectedTab = this.tabs.filter(tab => tab.id === this.currentTab)[0];
          this.selectTab(selectedTab);
        }

      }
    }
  }

  selectTab(tab: TabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach(t => (t.selected = false));

    // activate the tab the user has clicked on.
    tab.selected = true;

    // On emet l'id du tab en cours pour savoir si besoin l'onglet selectionné
    this.idSelected.emit(tab.id);
  }
}
