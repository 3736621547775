import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {GDIService} from '../../../services/hub-api/g-d-i.service';
import {SessionService} from '../../../services/session.service';
import {InterlocutorService} from '../../../services/hub-api/interlocutor.service';
import {CompanyService} from '../../../services/hub-api/company.service';
import {UserService} from '../../../services/hub-api/user.service';
import {ToastrService} from 'ngx-toastr';
import {UpperCaseFirstPipe} from '../../../pipes/ucfirst.pipe';
import {TranslateService} from '@ngx-translate/core';
import {roles} from "../../../constants";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactDetailsComponent implements OnInit {
  @Input() siret = '';
  @Input() company: any = {};
  @Output() companyChange: EventEmitter<any> = new EventEmitter<any>();

  mandataires: Array<any> = [];
  collaborateurs: Array<any> = [];
  prestataires: Array<any> = [];
  isReferent = false;
  classPrivilegedContact = ' fa-star star fa-2x ';

  constructor(
    private keycloackService: GDIService,
    private sessionService: SessionService,
    private interlocutorService: InterlocutorService,
    private companyService: CompanyService,
    private userService: UserService,
    private toastr: ToastrService,
    private ucfirst: UpperCaseFirstPipe,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    // On va chercher pour la personne connecté ces entreprises referentes
    this.sessionService.getAgentInterlocutorId().then(interlocutorId => {
      this.interlocutorService
        .getReferentCompanies(interlocutorId)
        .then((companies: any) => {
          // Si on a des entreprises, alors on va regarder si c'est celle en cours de consultation pour savoir
          // s'il a le droit de changer le contact privilégié
          if (companies.find(item => item.identifier === this.siret)) {
            this.isReferent = true;
            this.classPrivilegedContact += 'pointer';
          }
        });
    });
    this.getMembres();
  }

  getClass(accordion, panelId) {
    return accordion.isExpanded(panelId)
      ? 'fa-caret-up fa right red'
      : 'fa-caret-down fa right';
  }

  /**
   * Return true if this is the priviliged contact, else false
   * @param email email
   */
  isPrivilegedContactByEmail(email: string) {
    return email === this.company.privilegedcontact.email;
  }

  changePrivilegedContact(mandataire: any) {
    if (this.isReferent) {
      // On va chercher l'user avec son email
      this.userService
        .get({email: mandataire.email})
        .then(user => {
          // Puis on met a jour les informations de l'entreprise avec le new contact priviligié
          this.companyService
            .put({
              ...this.company,
              contactname: `${mandataire.lastName} ${mandataire.firstName}`,
              privilegedcontact: user[0]
            })
            .then(company => {
              this.company = company;
              // On envoie la nouvelle version de la company via un double binding
              // pour que le parent change la valeur de this.company
              this.companyChange.emit(this.company);
              this.toastr.success(
                this.ucfirst.transform(
                  this.translateService.instant('contact-details.toastr.ok')
                )
              );
            })
            .catch(() => {
              this.toastr.error(
                this.ucfirst.transform(
                  this.translateService.instant('contact-details.toastr.nok')
                )
              );
            });
        })
        .catch(() => {
          this.toastr.error(
            this.ucfirst.transform(
              this.translateService.instant('contact-details.toastr.nok')
            )
          );
        });
    }
  }

  getMembres() {
    let membres = [];
    this.companyService.getMembres(this.company.id).then(
      async (response: any) => {
        if (!response.hasError) {
          membres = response;
          this.mandataires = membres.filter(membre => membre.role.name === roles.mandataire)
            .map(membre => membre.user);
          this.collaborateurs = membres.filter(membre => membre.role.name === roles.collaborateur)
            .map(membre => membre.user);
          this.prestataires = membres.filter(membre => membre.role.name === roles.prestataire)
            .map(membre => membre.user);
        }
      });
  }
}
