<h3><span>Nous contacter</span></h3>

<div class="row d-flex justify-content-between mb-4">
  <div class="box-shadowed col-5">

    <p>
      <span class="bold">{{ 'contact-us.admin.title' | translate | ucfirst }}</span>
    </p>

    <p>
      {{ 'contact-us.admin.description' | translate | ucfirst }} <a
      href="mailto:contact-hub@laregion.fr">{{ 'contact-us.click-here' | translate | ucfirst }}</a>
    </p>

    <div class="mx-auto center">
      <img src="assets/images/contact-admin.png">
    </div>
  </div>

  <div class="box-shadowed col-5">

    <p>
      <span class="bold">{{ 'contact-us.technical-support.title' | translate | ucfirst }}</span>
    </p>

    <p class="custom-bottom-margin">
      {{ 'contact-us.technical-support.description' | translate | ucfirst }} <a
      href="mailto:support-ext@laregion.fr">{{ 'contact-us.click-here' | translate | ucfirst }}</a>
    </p>
    <div class="mx-auto center">
      <img src="assets/images/contact-support.png">
    </div>

  </div>
</div>
