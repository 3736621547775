import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'siren' })
export class SirenPipe implements PipeTransform {
  /**
   * On rajoute des espaces après le 3ème et 6ème chiffre pour plus de lisibilité
   *
   * @param content le siren
   */
  transform(content) {
    const positionZero = 0;
    const positionThree = 3;
    const positionSix = 6;
    const positionNine = 9;

    if (content) {
      return content
        .slice(positionZero, positionThree)
        .concat(' ')
        .concat(content.slice(positionThree, positionSix))
        .concat(' ')
        .concat(content.slice(positionSix, positionNine));
    }

    return content;
  }
}
