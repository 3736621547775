import { Component, OnInit } from '@angular/core';
import {HubBreadCrumbsService} from "../../services/hub-breadcrumbs.service";
import {IBreadcrumb} from "../../interfaces/IBreadcrumb";

@Component({
  selector: 'hub-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  breadcrumbs: Array<IBreadcrumb>;
  constructor(private breadcrumbsService: HubBreadCrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbsService.breadcrumbs.subscribe(bc => this.breadcrumbs = bc);
  }



}
