import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {SessionService} from '../services/session.service';
import {AgentService} from '../services/hub-api/agent.service';
import {CompanyService} from '../services/hub-api/company.service';
import {InterlocutorService} from "../services/hub-api/interlocutor.service";

@Injectable()
export class StructureGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private sessionService: SessionService,
    private companyService: CompanyService,
    private agentService: AgentService,
    private interlocutorService: InterlocutorService
  ) {
    super(router, keycloakService);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async resolve => {

      return this.agentService
        .getOne(await this.sessionService.getAgentId())
        .then(async (agent: any) => {
          const emailAgent = agent.interlocutor.email;
          const structureId =
            agent.interlocutor.structure && agent.interlocutor.structure.id;
          if (
            emailAgent.toLowerCase().includes('@agence-adocc.com') ||
            emailAgent.toLowerCase().includes('@laregion.fr')
          ) {
            return resolve(true);
          } 
          // accès à la page de l'entreprise autorisé si on est acteur d'au moins un projet de celle-ci
          else {
            const interlocutorProjects = (await this.interlocutorService.getInterlocutorProjects(agent.interlocutor.id)) as Array<any>;
            const companyProjects = (await this.companyService.getAllProjects(route.params.id)) as Array<any>;
            const companyProjectsId = companyProjects.map(companyProject => companyProject.id);
            let isActor = false;

            interlocutorProjects.forEach(project => {
              if (companyProjectsId.includes(project.id)) {
                isActor = true;
              }
            });
            if (isActor === false) {
              return this.companyService
                .getById(route.params.id)
                .then((company: any) => {
                  const structureCompany = company.interlocutor.structure;
                  if (structureId !== structureCompany.id) {
                    this.router.navigate(['/']);
                    return resolve(false);
                  }
                  return resolve(true);
                });
            }
            else {
              return resolve(isActor);
            }
          }
        });
    });
  }
}
