import { Injectable } from '@angular/core';
import { SearchArray } from '../components/search-card/dependencies/search-array';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  _query: SearchArray;
  _results: {
    plans: Array<any>;
    events: Array<any>;
    actualities: Array<any>;
  } = {
    plans: [],
    events: [],
    actualities: []
  };

  constructor() {}

  get query(): SearchArray {
    return this._query;
  }

  set query(query: SearchArray) {
    this._query = query;
  }

  get results(): {
    plans: Array<any>;
    events: Array<any>;
    actualities: Array<any>;
  } {
    return this._results;
  }

  set results(results: {
    plans: Array<any>;
    events: Array<any>;
    actualities: Array<any>;
  }) {
    this._results = results;
  }
}
