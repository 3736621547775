import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { datetimeFormat } from '../constants';

@Pipe({ name: 'datetime' })
export class DatetimePipe implements PipeTransform {
  transform(content) {
    const date = moment(content);
    return date.isValid() ? date.format(datetimeFormat) : '';
  }
}
