<div class="hub-plan-card-search-container shadow pointer" (click)="onCardClick()">
    <div class="row justify-content-between hub-plan-card-search-container-wraper">
      <div class="col-md-5 col-sm-12 hub-plan-card-search-content-wrapper">
        <p class="hub-plan-card-search-content-element title">{{ plan.name }}</p>
        <p class="hub-plan-card-search-content-element updatedat">
          {{ updatedAtString | ucfirst }}
        </p>
        <div class="hub-plan-card-search-content-element caption" [innerHTML]="plan.caption | safeHtml" *ngIf="plan.caption">
        </div>
      </div>
  
      <div class="themes col-md-5">
        <div class="hub-plan-card-search-content-element-eligible-activity-areas">
          <span>{{ 'plan-card-search.eligible-activity-areas' | translate | ucfirst }} : </span>
        </div>
        <ul>
          <li class="hub-plan-card-search-content-element theme" *ngFor="let theme of activityAreasLabels | slice:0:3">
            {{ theme }}
          </li>
        </ul>
        <span *ngIf="activityAreasLabels.length > 3" class="hub-plan-card-search-content-element-modal-link" (click)="openActivityAreasModal($event)">
          {{ 'plan-card-search.see-all-eligible-activity-areas' | translate | ucfirst }}
        </span>
      </div>
    </div>
  </div>
