import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserType } from 'src/app/constants/type-object-database';
import { InterlocutorService } from 'src/app/services/hub-api/interlocutor.service';
import { SliderMenuService } from 'src/app/services/slider-menu.service';

import { interlocutorRoles, maxNotifs } from '../../../constants';
import { AuthService } from '../../../services/auth.service';
import { NotificationService } from '../../../services/hub-api/notification.service';
import { SessionService } from '../../../services/session.service';
import {HubBreadCrumbsService} from '../../../services/hub-breadcrumbs.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-slider-menu',
  templateUrl: './slider-menu.component.html',
  styleUrls: ['./slider-menu.component.scss'],
  animations: [
    trigger('pop', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(200, style({ opacity: 0 }))
      ])
    ]),
    trigger('slideFromRight', [
      transition(':enter', [
        style({
          transform: 'translate3d(100%, 0, 0)'
        }),
        animate(
          250,
          style({
            transform: 'translate3d(0, 0, 0)'
          })
        )
      ]),
      transition(':leave', [
        style({
          transform: 'translate3d(0, 0, 0)'
        }),
        animate(
          200,
          style({
            transform: 'translate3d(100%, 0, 0)'
          })
        )
      ])
    ])
  ]
})
export class SliderMenuComponent implements OnInit {
  isLoggedIn = false;
  isOpen: Observable<boolean>;
  isLoggedAsCompany = false;
  isLoggedAsMendataire = false;
  user: UserType;
  isReferent = false;
  numberNotificationsUnread = '0';
  numberMaxNotifications = maxNotifs;

  constructor(
    private authService: AuthService,
    private router: Router,
    private sliderMenuService: SliderMenuService,
    private interlocutorService: InterlocutorService,
    private notificationService: NotificationService,
    private sessionService: SessionService,
    private breadcrumbs: HubBreadCrumbsService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.authService.isLoggedIn();
    this.isOpen = this.sliderMenuService.status;

    if (this.isLoggedIn) {
      this.user = await this.authService.getCurrentUser();

      this.interlocutorService
        .getinterlocutorRolesByEmail(this.user.email)
        .then((interlocutorRolesValue: any) => {
          if (interlocutorRolesValue.includes(interlocutorRoles.referent)) {
            this.isReferent = true;
          }
        })
        .catch(() => {});
    }

    this.sliderMenuService.status.subscribe((status: boolean) => {
      if (status) {
        this.loadNumberUnreadNotifications();
      }
    });
  }

  loadNumberUnreadNotifications() {
    this.sessionService
      .getAgentInterlocutorId()
      .then(interlocutorId => {
        this.notificationService
          .getNumberUnreadNotifications(interlocutorId)
          .then((numberNotification: string) => {
            this.numberNotificationsUnread = numberNotification;
            // Si y a 100 notifs ou plus, on affiche '99+' juste !
            if (+numberNotification >= 100) {
              this.numberNotificationsUnread = this.numberMaxNotifications;
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
  }

  closeNav() {
    this.sliderMenuService.hide();
  }
}
