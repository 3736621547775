import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortEntities'
})
export class SortEntitiesPipe implements PipeTransform {
  transform(entities: Array<any>, entityType: string): any {
    if (!entityType) {
      return entities;
    }
    return entities.filter(entity => entity.type === entityType);
  }
}
