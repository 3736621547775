import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UpperCaseFirstPipe } from '../pipes/ucfirst.pipe';

@Injectable()
export class TitleService {
  prefix: string;

  constructor(
    private title: Title,
    private translate: TranslateService,
    private ucfirstPipe: UpperCaseFirstPipe
  ) {
    this.translate
      .get('siteTitle')
      .subscribe(prefix => (this.prefix = this.ucfirstPipe.transform(prefix)));
  }

  setPageTitle(title: string) {
    this.translate.get(title).subscribe(reponse => {
      this.title.setTitle(
        `${this.prefix} - ${this.ucfirstPipe.transform(reponse)}`
      );
    });
  }
}
