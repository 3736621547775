<div class="hub-event-card-search">
  <div class="hub-event-card-search-container shadow white">

    <div *ngIf="isRefused" class="row content">
      <div class="refused col-12 center">
        <i class="fas fa-exclamation-circle"></i>
        <span class="ml-1">{{ 'event-card.refused' | translate | ucfirst }}</span>
      </div>
    </div>

    <div class="row content d-flex align-items-center">
      <div class="event-image col-3">
        <img [src]="getLogoUrl(evenement)"/>
      </div>

      <div class="text col-7">
        <p class="title font-weight-bold mb-0">{{ evenement.title }}</p>
        <p class="lieu mb-0" *ngIf="evenement.lieu"><i class="fas fa-map-marker-alt"></i> {{evenement.lieu}}</p>
        <p class="date mb-0">
          {{evenement.datedebut | date: 'dd MMMM yyyy'}} - {{evenement.datefin | date: 'dd MMMM yyyy'}}
        </p>
        <span class="description" [innerHTML]="evenement.post_content_plain"></span>
      </div>

      <div class="col-2">
        <a class="btn-more mt-3" (click)="onClickEventUrl.emit(evenement.permalink)">
          {{ 'event-card.see-detail' | translate | ucfirst }}
        </a>
        <a class="btn-more" (click)="onClickModify.emit(evenement.id)">
          {{ 'event-card.modify' | translate | ucfirst }}
        </a>
      </div>
    </div>
  </div>
</div>
