import { Injectable } from '@angular/core';
import { entityTypes } from 'src/app/constants';

@Injectable()
export class SortService {
  constructor() {}

  /**
   * sorts the given collection according to the given field and order
   *
   * @param collection List of entreprise
   * @param field The field to sort by
   * @param order 'desc' or 'asc'
   * @returns a sorted copy of the array
   */
  sortByFieldAndOrder(collection, field: string, order?: string): Array<{}> {
    return collection.sort((a: {}, b: {}) => {
      if (typeof a[field] === 'string') {
        // js method that sorts strings depending on the locale language (treats all accents and stuff)
        return a[field].localeCompare(b[field]);
      } else {
        return order === 'desc'
          ? a[field] <= b[field]
            ? 1
            : -1
          : a[field] <= b[field]
          ? -1
          : 1;
      }
    });
  }

  /**
   * Sorts the list of enterprises (companies/projects) according to their type ('contactname' field for a project and 'name' for a company)
   *
   * @param collection List of entreprise
   * @returns The list sorted
   */
  sortByNameAndTypeCompanyAndOrder(collection): Array<{}> {
    return collection.sort((a: any, b: any) => {
      // js method that sorts strings depending on the locale language (treats all accents and stuff)
      return a[
        a.type === entityTypes.project ? 'contactname' : 'name'
      ].toString().localeCompare(
        b[b.type === entityTypes.project ? 'contactname' : 'name']
      );
    });
  }
}
