import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

import { EncryptorService } from './encryptor.service';

@Injectable()
export class SessionService {
  private agentSessionId = 'AGENT_SESSION_ID';
  public _agent: BehaviorSubject<{}> = new BehaviorSubject(undefined);

  constructor(
    private cookieService: CookieService,
    private encryptorService: EncryptorService
  ) {}

  setAgent(agent) {
    this._agent.next(agent);
    this.cookieService.set(
      this.agentSessionId,
      this.encryptorService.encrypt(agent)
    );
  }

  unsetAgent() {
    this.cookieService.delete(this.agentSessionId);
  }

  getAgent() {
    const agent = this.cookieService.get(this.agentSessionId);
    return agent
      ? Promise.resolve(this.encryptorService.decrypt(agent))
      : new Promise(resolve =>
          this._agent.subscribe(nextAgent => resolve(nextAgent))
        );
  }

  getAgentAsObservable() {
    return this._agent.asObservable();
  }

  getAgentId() {
    return this.getAgent().then(agent => agent.id);
  }

  getAgentInterlocutorId() {
    return this.getAgent().then(agent => agent.interlocutorid);
  }

  isAgentSet() {
    return this.cookieService.check(this.agentSessionId);
  }

  isSet() {
    return this.isAgentSet();
  }
}
