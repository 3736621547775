import {Component, OnInit} from '@angular/core';
import {SessionService} from "../../../services/session.service";
import {InterlocutorService} from "../../../services/hub-api/interlocutor.service";
import {EventService} from "../../../services/hub-api/eventService";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {UpperCaseFirstPipe} from "../../../pipes/ucfirst.pipe";
import {eventStatut, routesUrl} from "../../../constants";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfirmDeleteEventComponent} from "../../../components/confirm-delete-event/confirm-delete-event.component";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'evenements-edit',
  templateUrl: './evenements-edit.component.html'
})
export class EvenementsEditComponent implements OnInit {
  title = "Modifier un événment";
  event: any;
  isLoading: boolean;
  currentInterlocutor: any;
  currentAgent: any;

  constructor(
    private sessionService: SessionService,
    private eventService: EventService,
    private interlocutorService: InterlocutorService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private session: SessionService,
    private translateService: TranslateService,
    private ucfirst: UpperCaseFirstPipe,
    private modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe(async params => {
      const id = params.id;
      this.event = await this.eventService.getById(id);
      this.isLoading = false;
    });
    this.session.getAgent().then(async agent => {
      if (agent) {
        this.currentAgent = agent;
        this.currentInterlocutor = await this.interlocutorService
          .getById(this.currentAgent.interlocutorid);
      }
    });
  }

  updateEvent(event: any) {
    event.createur = {id: this.currentInterlocutor.id};
    this.eventService.put(event).then(() => {
      this.toastr.success(this.ucfirst.transform(
        this.translateService.instant('evenements-form.toastr.event-updated')
      ));
      this.router.navigate([routesUrl.EVENTS_LIST]);
    })
      .catch(() => {
        this.toastr.error(this.ucfirst.transform(
          this.translateService.instant('evenements-form.toastr.event-error')
        ));
        this.router.navigate([routesUrl.EVENTS_LIST]);
      });
  }

  redirectToList() {
    this.router.navigate([routesUrl.EVENTS_LIST]);
  }

  deleteEvent(id: any) {
    const initialState = {
      eventTitle: this.event.title
    };
    const modalRef = this.modalService.show(ConfirmDeleteEventComponent, {
      initialState,
      animated: true,
      class: 'modal-dialog-centered'
    });
    modalRef.content.onAccept = () => {
      this.eventService.delete(id).then(() => {
        this.toastr.success(this.ucfirst.transform(
          this.translateService.instant('evenements-form.toastr.event-deleted')
        ));
        modalRef.hide();
        this.router.navigate([routesUrl.EVENTS_LIST]);
      })
      .catch(() => {
        this.toastr.error(this.ucfirst.transform(
          this.translateService.instant('evenements-form.toastr.event-error')
        ));
        modalRef.hide();
        this.router.navigate([routesUrl.EVENTS_LIST]);
      });
    };

  }

  isRefused(libelle : string){
    return libelle === eventStatut.refuse
  }
}
