import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationInstance} from 'ngx-pagination';

@Component({
  selector: 'hub-pagination-menu',
  templateUrl: './pagination-menu.component.html',
  styleUrls: ['./pagination-menu.component.scss']
})
export class PaginationMenuComponent implements OnInit {

  @Input()
  configPagination: PaginationInstance;

  @Output() onPaginationClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onPageChange (pageNumber: number) {
    this.configPagination.currentPage = pageNumber;
    this.onPaginationClick.emit(this.configPagination);
  }

}
