import {Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from 'src/app/services/auth.service';
import {StructureLogoService} from 'src/app/services/hub-api/structure-logo.service';
import {UtilsFunctions} from '../../utils/utils';
import {AgentAvatarService} from '../../services/hub-api/agent-avatar.service';
import {defaultAvatarUrl} from '../../constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-referer-card',
  templateUrl: './referer-card.component.html',
  styleUrls: ['./referer-card.component.scss']
})
export class RefererCardComponent implements OnInit {
  @Input()
  referer: any;
  @Input()
  title = '';
  @Input()
  user: any = {};
  @Output()
  emitDelete = new EventEmitter();
  isLoading = true;
  isLoggedIn = false;
  toastr: any;
  checklink = UtilsFunctions.checklink;
  avatarUrl: string;

  @ViewChild('changeReferentTemplate') changeReferentTemplate: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private structureLogoService: StructureLogoService,
    private agentAvatarService: AgentAvatarService,
    injector: Injector
  ) {
    this.toastr = injector.get(ToastrService);
  }

  async ngOnInit() {
    this.isLoggedIn = await this.authService.isLoggedIn();
    this.avatarUrl = this.referer.avatar
      ? this.agentAvatarService.getFileUrl(this.referer.avatar)
      : defaultAvatarUrl;
  }

  refererLoaded() {
    return this.referer !== undefined;
  }

  hasReferer() {
    return this.referer && Object.keys(this.referer).length > 0;
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getLogoUrl(logo) {
    return this.structureLogoService.getFileUrl(logo);
  }
}
