import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-header-default',
  templateUrl: './header.default.component.html',
  styleUrls: ['./header.default.component.scss']
})
export class HeaderDefaultComponent implements OnInit {
  @Input()
  isStaticPage = false;

  constructor(private router: Router) {}

  ngOnInit() {}

  goToHome() {
    this.router.navigate(['/']);
  }
}
