import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {InterlocutorService} from "src/app/services/hub-api/interlocutor.service";
import {fromEvent, Observable, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {SortService} from "src/app/services/sort.service";
import {ProjectService} from "src/app/services/hub-api/project.service";
import {StructureLogoService} from "src/app/services/hub-api/structure-logo.service";

@Component({
  selector: 'app-project-actor',
  templateUrl: './add-project-actor.component.html',
  styleUrls: ['./add-project-actor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddProjectActorComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("actor", { static: true })
  private actorInput: ElementRef;
  private inputObservable: Observable<any>;
  interlocutors: Array<any> = [];
  selectedInterlocutors: Array<any> = [];
  private subscription: Subscription;
  isSearching = false;
  projectId: string;
  referer: any;
  existingActors: Array<any> = [];
  //le(s) rôle(s) des interlocuteurs
  roles = new Map<string, Array<string>>();
  hasSearched = false;

  onSuccess: () => void;
  onFail: () => void;

  constructor(
    public modalRef: BsModalRef,
    private interlocutorService: InterlocutorService,
    private sortService: SortService,
    private structureLogoService: StructureLogoService,
    private projectService: ProjectService) {
  }

  ngOnInit() {
    // la recherche d'interlocuteurs s'effectue lorque l'utilisateur ne tape plus depuis 1 seconde
    this.inputObservable = fromEvent(this.actorInput.nativeElement, 'keydown').pipe(debounceTime(1000));
    this.subscription = this.inputObservable.subscribe(async (e: KeyboardEvent) => {
      e.preventDefault();
      const value = (<HTMLInputElement>e.target).value;
      if (value.length > 0) {
        this.isSearching = true;
        this.hasSearched = true;
        const interlocutors = await this.interlocutorService.search(this.buildQueryParams(value));
        this.isSearching = false;
        //on affiche les interlocuteurs qui correspondent à la recherche moins le référent ou ceux qui seraient déjà acteur du projet
        this.interlocutors = (interlocutors as Array<any>).filter(interlocutor => {
          return !this.isSelectedOrIsActorOrIsReferer(interlocutor);
        });
        // on set les rôles des interlocuteurs
        this.interlocutors.forEach(async interlocutor => {
          if (!this.roles.has(interlocutor.id)) {
            const roles = (<any> await this.interlocutorService.getinterlocutorRolesById(interlocutor.id));
            interlocutor.roles = roles;
            this.roles.set(interlocutor.id, roles);
          }
        })

      }
    });
  }

  private isSelectedOrIsActorOrIsReferer(interlocutor: any): boolean {
    return this.isSelected(interlocutor) || this.isExistingActor(interlocutor) || this.isReferer(interlocutor);
  }

  private isSelected(interlocutor: any): boolean {
    return this.selectedInterlocutors.map(item => item.id).includes(interlocutor.id);
  }

  private isExistingActor(interlocutor: any): boolean {
    return this.existingActors.map(item => item.id).includes(interlocutor.id);
  }

  private isReferer(interlocutor: any): boolean {
    return this.referer.id == interlocutor.id;
  }

  private buildQueryParams(value: string): any {
    return {"lastname" : value};
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.actorInput.nativeElement.focus(), 0);
  }

  addInterlocutor(interlocutor: any): void {
    interlocutor.name = `${interlocutor.firstname} ${interlocutor.lastname}`;
    this.selectedInterlocutors = [...this.selectedInterlocutors, interlocutor];
    this.reset();
    this.hasSearched = false;
  }

  removeInterlocutor({value}): void {
    this.selectedInterlocutors = this.selectedInterlocutors.filter(interlocutor => interlocutor.id !== value.id);
    if (this.interlocutors.length > 0 || this.actorInput.nativeElement.value.length > 0) {
      this.interlocutors = [...this.interlocutors, value];
      this.interlocutors = this.sortService.sortByFieldAndOrder(this.interlocutors, 'lastname');
    }
  }

  removeAllSelectedInterlocutors(): void {
    if (this.interlocutors.length > 0) {
      this.interlocutors = [...this.interlocutors, ...this.selectedInterlocutors];
      this.interlocutors = this.sortService.sortByFieldAndOrder(this.interlocutors, 'lastname');
    }
    this.selectedInterlocutors = [];
  }

  reset() {
    this.interlocutors = [];
    this.actorInput.nativeElement.value = '';
  }

  onSubmit() {
    const allActors = [...this.selectedInterlocutors, ...this.existingActors];
    this.projectService.setActors(this.projectId, allActors.map(interlocutor => {
      return {
        id: interlocutor.id
      }
    })).then(() => {
      this.onSuccess();
    }).catch(() => {
      this.onFail();
    });
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  formatRoles(interlocutor) {
    let formatted = '';
    interlocutor.roles.forEach((role, index) => {
      formatted += role;
      if (index != interlocutor.roles.length - 1) {
        formatted += ', '
      }
    });
    return formatted;
  }

  getLogoUrl(logo) {
    return this.structureLogoService.getFileUrl(logo);
  }
}
