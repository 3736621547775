<div class="hub-interlocutor-card shadow white" [ngClass]="{'absent-card': isAbsent()}">
  <div class="row" *ngIf="isAbsent()">
    <div class="col">
      <span class="bold text">
        {{'interlocutor-card.absence.missing-from' | translate | ucfirst }} {{interlocutor.debutAbsence}}
        {{'interlocutor-card.absence.missing-to' | translate }} {{interlocutor.finAbsence}}
        {{'interlocutor-card.absence.backup' | translate | ucfirst}}
        <span *ngIf="interlocutor.backup">{{interlocutor.backup.firstname}} {{interlocutor.backup.lastname}} </span>
        <span *ngIf="interlocutor.telBackup">{{interlocutor.telBackup}}</span>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-5">
      <hub-information-card
        [interlocutor]="interlocutor"
        [isContact]="isContact"
        [isAbsent]="isAbsent()"
        (onStarClick)="starClick($event)">
      </hub-information-card>
    </div>
    <div *ngIf="teritories.length > 0 || interlocutor.activityareas.length > 0"
         class="col hub-interlocutor-card-tablecontainer">
      <div class="row">
        <div class="col-5 hub-interlocutor-card-head" [ngClass]="{'hub-interlocutor-card-head-deactivate':isAbsent()}">
          {{ 'interlocutor-card.specialities' | translate | ucfirst }}
        </div>
        <div class="col-3 hub-interlocutor-card-head" [ngClass]="{'hub-interlocutor-card-head-deactivate':isAbsent()}">
          {{ 'interlocutor-card.activityareas' | translate | ucfirst }}
        </div>
        <div class="col-3 hub-interlocutor-card-head" [ngClass]="{'hub-interlocutor-card-head-deactivate':isAbsent()}">
          {{ 'interlocutor-card.territories' | translate | ucfirst }}
        </div>
      </div>
      <div class="row hub-interlocutor-card-row-body">
        <div class="col-5 hub-interlocutor-card-body" [ngClass]="{'hub-interlocutor-card-body-deactivate':isAbsent()}">
          <div *ngIf='interlocutor.specialities.length > 0'>
            <div *ngFor='let specialitie of interlocutor.specialities'>
              {{specialitie.label}}
            </div>
          </div>
          <div *ngIf='interlocutor.specialities.length == 0'>
            -
          </div>
        </div>
        <div class="col-3 hub-interlocutor-card-body" [ngClass]="{'hub-interlocutor-card-body-deactivate':isAbsent()}">
          <div *ngIf='teritories.length > 0'>
            <div *ngFor='let territory of teritories'>
              {{territory.label}} - {{territory.departments}}
            </div>
          </div>
          <div *ngIf='teritories.length == 0'>
            -
          </div>
        </div>
        <div class="col-3 hub-interlocutor-card-body" [ngClass]="{'hub-interlocutor-card-body-deactivate':isAbsent()}">
          <div *ngIf='interlocutor.activityareas.length > 0'>
            <div *ngFor='let activityarea of interlocutor.activityareas'>
              {{activityarea.label}}
            </div>
          </div>
          <div *ngIf='interlocutor.activityareas.length == 0'>
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
