import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {entityTypes, notificationTypes} from 'src/app/constants';
import {UpperCaseFirstPipe} from 'src/app/pipes/ucfirst.pipe';
import {NotificationService} from 'src/app/services/hub-api/notification.service';
import {CompanyService} from 'src/app/services/hub-api/company.service';
import {GDIService} from 'src/app/services/hub-api/g-d-i.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RefuseReferentModalComponent} from '../refuse-referent-modal/refuse-referent-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {
  @Input()
  notification: any = {};
  company: any = {};
  companyApiData: any = {};
  activityAreas = '';
  isProjectHolder: boolean;
  titleNotification = '';
  newReferentType = notificationTypes.newReferent;
  privilegedContact: any;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private ucfirst: UpperCaseFirstPipe,
    private translateService: TranslateService,
    private GdiService: GDIService,
    private CompanyService: CompanyService,
    private modalService: NgbModal
  ) {
  }

  async ngOnInit() {
    this.company = this.notification.company || {};
    this.isProjectHolder = this.company.type === entityTypes.project;

    this.getTitleNotification();

    if (this.company.privilegedcontact) {
      await this.GdiService.getUserById(
        this.company.privilegedcontact.keycloakid
      ).then((data: any) => {
        this.privilegedContact = data.userInformation;
      });
    }

    // Appel API Entreprise
    if (this.notification.type === notificationTypes.newReferent) {
      await this.CompanyService.getOneCompanyBySiret(
        this.company.identifier
      ).then((data: any) => {
        this.companyApiData = data.data;
      });

      await this.CompanyService.getActivityAreasForAnUser(
        this.company.id
      ).then((data: any) => {
        for (let activity of data) {
          this.activityAreas = this.activityAreas + ", " + activity;
        }
        this.activityAreas = this.activityAreas.substr(2);
      });
    }
  }

  /**
   * Get the title of the notification with his type
   */
  getTitleNotification() {
    switch (this.notification.type) {
      case notificationTypes.newReferent:
        this.titleNotification = this.ucfirst.transform(
          this.translateService.instant('notification.choosen-as-referent')
        );
        break;

      case notificationTypes.clickedOnIHaveAProject:
        this.titleNotification = this.ucfirst.transform(
          this.translateService.instant(
            'notification.clicked-on-I-have-a-project'
          )
        );
        break;

      case notificationTypes.companyActivity:
        this.titleNotification = this.ucfirst.transform(
          this.translateService.instant(
            'notification.company-activity'
          )
        );
        break;

      case notificationTypes.projectToCompanyTransition:
        this.titleNotification = this.ucfirst.transform(
          this.translateService.instant(
            'notification.project-to-company'
          )
        );
        break;

      case notificationTypes.newProject:
        this.titleNotification = this.ucfirst.transform(
          this.translateService.instant(
            'notification.clicked-on-I-have-a-project'
          )
        );
        break;

      case notificationTypes.clickedOnFileSubmission:
        this.titleNotification = this.ucfirst.transform(
          this.translateService.instant(
            'notification.clicked-on-file-submission'
          )
        );
        break;
      default:
        break;
    }
  }

  getRightButtonText(): string {
    if (this.isProjectHolder) {
      return this.ucfirst.transform(
        this.translateService.instant('notification.access-project-holder-informations')
      );
    }
    return this.ucfirst.transform(
      this.translateService.instant('notification.access-company-informations')
    );
  }

  goToCompanyDetails() {
    if (this.notification.type === notificationTypes.clickedOnFileSubmission) {
      this.markNotificationAsRead();
      this.router.navigate([`/entite/${this.company.id}`], {
        queryParams: {
          tab: 'navigation-history'
        }
      });
    } else if (this.notification.type === notificationTypes.newProject) {
      this.markNotificationAsRead();
      this.router.navigate([`/entite/${this.company.id}`], {
        queryParams: {
          tab: 'projects'
        }
      });
    } else if (this.notification.type === notificationTypes.newReferent) {
      this.router.navigate([`/entite/${this.company.id}`]);
    } else {
      this.markNotificationAsRead();
      this.router.navigate([`/entite/${this.company.id}`]);
    }

  }

  markNotificationAsRead() {
    if (!this.notification.read) {
      this.notificationService
        .put({
          ...this.notification,
          read: true
        })
        .then(notification => {
          this.notification = notification;
        })
        .catch(() => {
        });
    }
  }

  /**
   * Method to open the update Modal

   */
  openRefuseReferentModale() {
    {
      const modalRef = this.modalService.open(RefuseReferentModalComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      });
      modalRef.componentInstance.notification = this.notification;
      modalRef.componentInstance.company = this.company;
      modalRef.componentInstance.privilegedContact = this.privilegedContact;

      modalRef.result.then(result => {
        if (result !== 'close') {
          this.notification = result;
        }
      });
    }
  }
}
