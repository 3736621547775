import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';

import { SessionService } from './session.service';

@Injectable()
export class AuthService {
  private _isLoggedIn$ = new Subject<boolean>();

  get isLoggedIn$() {
    // asObservable() -> permet de retirer la partie "subject" de l'api (next etc...) -> observable en lecture seule
    return this._isLoggedIn$.asObservable();
  }

  constructor(
    private keycloakService: KeycloakService,
    private sessionService: SessionService
  ) {}

  async isLoggedIn() {
    let isLoggedIn = false;

    await this.keycloakService
      .isLoggedIn()
      .then(loggedIn => {
        isLoggedIn = loggedIn;
        this._isLoggedIn$.next(isLoggedIn);
      })
      .catch(error => {
        console.error(error);
      });

    return isLoggedIn;
  }

  async goToLogin(redirectUri = '') {
    const isLoggedIn = await this.isLoggedIn();
    return isLoggedIn
      ? true
      : this.keycloakService.login({
          redirectUri: `${window.location.origin}${redirectUri}`
        });
  }

  newCreateLogoutUrl(options) {
    var kc = this.keycloakService.getKeycloakInstance();
    var url = kc.createLogoutUrl();
    // we obtain redirect_uri from old keycloak .js lib
    //we need instead post_logout_redirect_uri and id_token_hint
    url = url.replace("?redirect_uri=", "?post_logout_redirect_uri=");
    url += '&id_token_hint=' + encodeURIComponent(kc.idToken);

    return url;
  };

  logout(redirectUrl = '') {
    // pour que ça marche il faut passer keycloak.js version 18
       /*return this.keycloakService
      .logout(`${window.location.origin}${redirectUrl}`)
      .then(() => true);*/
    // A la place on surchage le logout
    var kc = this.keycloakService.getKeycloakInstance();
    //this.sessionService.unsetAgent();
    window.location.replace(this.newCreateLogoutUrl(redirectUrl));
  }


  getCurrentUser(forceReload = false) {
    return this.keycloakService.loadUserProfile(forceReload);
  }

  getToken(): Promise<string> {
    return new Promise(resolve =>
      this.keycloakService
        .isLoggedIn()
        .then(isLoggedIn =>
          isLoggedIn
            ? this.keycloakService.getToken().then(token => resolve(token))
            : resolve('')
        )
    );
  }

  isTokenExpired(minValidity?: number) {
    return this.keycloakService.isTokenExpired(minValidity);
  }
}
