import { Injectable, Injector } from '@angular/core';
import { endpoints } from '../../constants';
import { FileService } from './file.service';
import { ApiService } from './api.service';

@Injectable()
export class StructureLogoService extends FileService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.structureLogo;
  }
}
