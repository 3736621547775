import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EventService} from "../../../services/hub-api/eventService";
import {EventLogoService} from '../../../services/hub-api/event-logo.service';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {SessionService} from "../../../services/session.service";
import {InterlocutorService} from "../../../services/hub-api/interlocutor.service";
import {HoursMinutesSec, simpleDateComparisonFormat} from "../../../constants";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";
import * as moment from "moment";
import {environmentLoader} from "../../../../environments/environmentLoader";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'evenements-form',
  templateUrl: './evenements-form.component.html',
  styleUrls: ['./evenements-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EvenementsFormComponent implements OnInit {

  @Input()
  event: any = {
    logo: {},
    title: '',
    post_content_plain: '',
    post_content_html: '',
    lieu: '',
    permalink: '',
    datedebut: '',
    datefin: '',
    heure_debut: '08:00:00',
    heure_fin: '18:00:00',
    createur: undefined,
    defaultLogo: undefined
  };

  @Input()
  isRefused = false;

  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onCancel = new EventEmitter<any>();

  @Output()
  onDelete = new EventEmitter<any>();

  hasUploadError = false;
  fileErrorMessage: string;
  fileTooLarge = false;
  isSaving = false;
  formattedStartDate: NgbDateStruct;
  formattedEndDate: NgbDateStruct;
  formattedStartHour: NgbTimeStruct;
  formattedEndHour: NgbTimeStruct;
  now: NgbDateStruct;
  minDate: NgbDateStruct;
  currentAgent: any;
  // Détient les informations sur tout ce qui concerne la structure, le poste etc...
  currentInterlocutor: any;

  isSubmitIsAlreadyClicked = false;

  maxFileSize: number;

  constructor(private injector: Injector,
              private session: SessionService,
              private eventService: EventService,
              private interlocutorService: InterlocutorService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private eventLogoService: EventLogoService) {
    environmentLoader.then(env => {
      this.maxFileSize = env.settings.file.maxSize;
      this.maxFileSize = isNaN(this.maxFileSize) ? 0 : this.maxFileSize;
    });
  }

  async ngOnInit() {
    this.isSubmitIsAlreadyClicked = false;
    this.session.getAgent().then(async agent => {
      if (agent) {
        this.currentAgent = agent;
        await this.loadInterlocutorData();
      }
    });
    const momentStartDate = moment(this.event.datedebut, simpleDateComparisonFormat);
    this.formattedStartDate = {
      year: momentStartDate.year(),
      month: momentStartDate.month() + 1,
      day: momentStartDate.date()
    };

    const momentEndDate = moment(this.event.datefin, simpleDateComparisonFormat);
    this.formattedEndDate = {
      year: momentEndDate.year(),
      month: momentEndDate.month() + 1,
      day: momentEndDate.date()
    };

    const nowDate = moment();
    this.now = {
      year: nowDate.year(),
      month: nowDate.month() + 1,
      day: nowDate.date()
    };

    const momentStartHour = moment(this.event.heure_debut, HoursMinutesSec);
    this.formattedStartHour = momentStartHour.isValid() ? {
      hour: momentStartHour.hour(),
      minute: momentStartHour.minute(),
      second: 0
    } : undefined;

    const momentEndHour = moment(this.event.heure_fin, HoursMinutesSec);
    this.formattedEndHour = momentEndHour.isValid() ? {
      hour: momentEndHour.hour(),
      minute: momentEndHour.minute(),
      second: 0
    } : undefined;
    if (!this.event.id) {
      this.minDate = this.now;
    } else {
      const momentMinDate = momentStartDate.subtract(1, 'months');
      this.minDate = {
        year: momentMinDate.year(),
        month: momentMinDate.month() + 1,
        day: momentMinDate.date()
      };
    }
  }

  submit() {
    this.isSubmitIsAlreadyClicked = true;
    if (!(this.event.logo && this.event.logo.id)) {
      this.event.logo = this.currentInterlocutor.structure.logo;
      this.event.defaultLogo = true;
    } else {
      this.event.defaultLogo = false;
    }

    this.event.datedebut = `${this.formattedStartDate.year}-${this.formattedStartDate.month.toString().padStart
    (2, '0')}-${this.formattedStartDate.day.toString().padStart(2, '0')}`;
    this.event.datefin = `${this.formattedEndDate.year}-${this.formattedEndDate.month.toString().padStart
    (2, '0')}-${this.formattedEndDate.day.toString().padStart(2, '0')}`;
    this.event.heure_debut = this.formattedStartHour ? `${this.formattedStartHour.hour.toString().padStart
    (2, '0')}:${this.formattedStartHour.minute.toString().padStart(2, '0')}:00` : null;
    this.event.heure_fin = this.formattedEndHour ? `${this.formattedEndHour.hour.toString().padStart
    (2, '0')}:${this.formattedEndHour.minute.toString().padStart(2, '0')}:00` : null;
    this.event.post_content_html = this.event.post_content_plain;

    this.isSaving = true;

    this.onSubmit.emit(this.event);
  }


  uploadFile(event) {
    const target = event.target;
    const image = target.files[0];

    if (image.size < this.maxFileSize) {
      this.fileTooLarge = false;
      this.spinner.show();
      this.eventLogoService.upload(image).then(
        file => {
          this.spinner.hide();
          this.event.logo = file;
          this.hasUploadError = false;
        },
        () => {
          this.hasUploadError = true;
          this.spinner.hide();
        }
      );
    } else {
      this.fileTooLarge = true;
    }
  }

  getLogoUrl(event) {
    return this.eventService.getLogoUrl(event);
  }

  deleteLogo() {
    this.event.logo = {};
  }

  isValidUrl(url
               :
               string
  ) {
    return url && (url.startsWith('http://') || url.startsWith('https://'));
  }

  isNotNullUrl(url) {
    return url && url.length !== 0;
  }

  cancel() {
    this.onCancel.emit();
  }

  delete() {
    this.onDelete.emit(this.event.id);
  }

  async loadInterlocutorData() {
    await this.interlocutorService
      .getById(this.currentAgent.interlocutorid)
      .then(interlocutor => {
        this.currentInterlocutor = interlocutor;
      });
  }
}
