<div class="row mb-2 mt-2">
  <div class="col">
    <h5 class="pointer" (click)="showProject = !showProject">
      <span>{{subvention.dossierProgos.libelle | ucfirst}}</span>
      <i [ngClass]="showProject ? 'fa-angle-up' : 'fa-angle-down'" class="fa right"></i>
    </h5>
  </div>
</div>

<div *ngIf="showProject">

  <!-- Encadré gris contenant le financement concerné et la date de création -->
  <div class="row mb-2 mt-2 justify-content-center information-box">
    <div class="col-8">
      <span>{{'company-details.submitted.financement' |translate | ucfirst }} :</span>
      <span> {{ subvention.dossierProgos.financement }}</span>
    </div>
    <div class="col-4">
      <span>{{'company-details.submitted.date-demande' |translate | ucfirst }} :</span>
      <span> {{ getDisplayableDate(subvention.dossierProgos.dateDemandeDossier) }}</span>
    </div>
  </div>

  <div class="row specific-row-workaround justify-content-center">

    <!-- Transmission -->
    <div class="col encart">
      <div class="center mb-2">
        <span class="bold">{{'company-details.submitted.transmission.title' |translate | ucfirst }}</span>
      </div>

      <div class="body">
        <div>
          <span>{{ 'company-details.submitted.transmission.was-received' | translate | ucfirst }}</span>
        </div>
        <div>
          <span class="bold valid-color">{{ 'company-details.submitted.transmission.yes' | translate | ucfirst }}</span>
        </div>
        <div>
          <span>{{ 'company-details.submitted.transmission.date' | translate | ucfirst }}</span>
        </div>
        <div>
          <span class="bold valid-color">{{getDisplayableDate(subvention.dossierProgos.dateCreationDossier)}}</span>
        </div>
      </div>


      <div class="center">
        <i class="fas fa-2x fa-check-circle valid-color"></i>
      </div>

      <div class="arrow-position arrow-color-validate">
        <i class="fas fa-lg fa-play"></i>
      </div>
    </div>

    <!-- Etude -->
    <div class="col encart">
      <div class="center mb-2">
        <span class="bold">{{'company-details.submitted.on-going.title' |translate | ucfirst }}</span>
      </div>
      <div class="body">
        <div>
          <span>{{ 'company-details.submitted.on-going.admissibility' | translate | ucfirst }}</span>
        </div>
        <div>
          <span class="bold" [ngClass]="{'valid-color': isAdmitted(), 'refused-color': !isAdmissible()}">{{admissibility | uppercase}}</span>
        </div>
      </div>

      <div class="center" *ngIf="isAdmissible(); else notAdmissible">
        <i *ngIf="isAdmitted()" class="fas fa-2x fa-check-circle valid-color"></i>
      </div>

      <ng-template #notAdmissible>
        <div class="center">
          <i class="fas fa-2x fa-times-circle refused-color"></i>
        </div>
      </ng-template>

      <div class="arrow-position arrow-color-default" [ngClass]="{'arrow-color-validate': isAdmitted()}">
        <i class="fas fa-lg fa-play"></i>
      </div>
    </div>

    <!-- deliberation -->
    <div class="col encart" [ngClass]=" {'not-active': !isAdmitted()}">
      <div class="center mb-2">
        <span class="bold">{{'company-details.submitted.decision.title' |translate | ucfirst }}</span>
      </div>

      <div class="body" [ngClass]="{'scrollable scroll-style': subvention.dossierProgos.decisions.length > 1}">
        <div *ngIf="!isAdmitted(); else isAdmit">
          <div>
            <span>{{ 'company-details.submitted.decision.date' | translate | ucfirst }}</span>
          </div>
          <div >
            <span class="bold"> --- </span>
          </div>
        </div>
        <ng-template #isAdmit>
          <div *ngFor="let decision of subvention.dossierProgos.decisions" class="mb-4">
            <div>
              <span>{{ 'company-details.submitted.decision.date' | translate | ucfirst }}</span>
            </div>
            <div>
              <span class="bold" [ngClass]="{'valid-color': isLastDecisionValid()}">{{getDisplayableDate(decision?.dateDecision)}}</span>
            </div>
            <div>
              <span>{{ 'company-details.submitted.decision.eligible-amount' | translate | ucfirst }}</span>
            </div>
            <div>
              <span class="bold" [ngClass]="{'valid-color': isLastDecisionValid()}">{{decision?.montantVote ? (decision?.montantVote | currency: 'EUR':'symbol':undefined:'fr-FR') : '---' }}</span>
            </div>
          </div>

        </ng-template>
      </div>


      <div class="center" *ngIf="isLastDecisionValid()">
        <i class="fas fa-2x fa-check-circle valid-color"></i>
      </div>

      <div class="arrow-position arrow-color-default" [ngClass]="{'arrow-color-validate': isOneLiquidationNotEmpty()}">
      <i class="fas fa-lg fa-play"></i>
    </div>
    </div>

    <!-- Modalités de versement -->
    <div class="col encart" [class.not-active]="!isOneLiquidationNotEmpty()">
      <div class="center mb-2 encart-title">
        <span class="bold">{{'company-details.submitted.versements.title' | translate | ucfirst }}
          <i
            class="fas fa-info-circle"
            ngbTooltip="{{ 'company-details.submitted.versements.tooltip' | translate | ucfirst }}"
            placement="bottom"
            container="body"
            *ngIf="shownLiquidations && shownLiquidations.length > 0"></i>
        </span>
      </div>
      <div class="body scrollable scroll-style">
        <div *ngIf="!shownLiquidations || !shownLiquidations.length" class="text-center">
          <span class="bold"> --- </span>
        </div>
        <div *ngFor="let liquidation of shownLiquidations" class="mb-1">
          <div>
            <span>{{ 'company-details.submitted.result.amount' | translate | ucfirst }}</span> :
            <span class="bold" [class.valid-color]="areAllLiquidationsPaid">{{liquidation.montant | currency: 'EUR':'symbol':undefined:'fr-FR'}}</span>
          </div>
          <div>
            <span>{{ 'company-details.submitted.result.date' | translate | ucfirst }}</span> :
            <span class="bold" [class.valid-color]="areAllLiquidationsPaid">{{ displayLiquidationDate(liquidation.dateVersement) }}</span>
          </div>
        </div>
      </div>

      <div class="center" *ngIf="areAllLiquidationsPaid">
        <i class="fas fa-2x fa-check-circle valid-color"></i>
      </div>
    </div>
  </div>
</div>



