import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'confirm-delete-event',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ 'delete-event-modal.header' | translate | ucfirst }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ 'delete-event-modal.message' | translate:translationParam | ucfirst }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{ 'delete-event-modal.cancel' | translate | ucfirst }}</button>
      <button type="button" class="btn btn-danger" (click)="onSubmit()">{{ 'delete-event-modal.confirm' | translate | ucfirst }}</button>
    </div>
  `,
  styleUrls: ['./confirm-delete-event.component.scss']
})
export class ConfirmDeleteEventComponent implements OnInit {

  eventTitle: string;
  onAccept: () => void;
  translationParam: any;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.translationParam = { eventTitle: this.eventTitle };
  }

  onSubmit() {
    this.onAccept();
    this.bsModalRef.hide();
  }

}
