<div class="project-block">
  <div>
    <h3 class="text-left title">{{ 'tree.my-project' | translate | ucfirst }}</h3>
    <p class="steps-container">
      <span *ngFor="let step of steps" class="block step">
        <span class="block subject">{{ step.subject }}</span>
        <span class="block choice">
          <img
            src="assets/images/checkstepper-red.png"
            alt="{{ 'check' | translate | ucfirst }}"
          /> {{ step.choice?.title }}
        </span>
      </span>
    </p>
  </div>
</div>
