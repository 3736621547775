import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';

@Injectable()
export class ReferentRequestService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.referentRequest;
  }
}
