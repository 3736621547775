import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { defaultAvatarUrl, interlocutorRoles } from 'src/app/constants';
import { InterlocutorService } from 'src/app/services/hub-api/interlocutor.service';
import { StructureLogoService } from 'src/app/services/hub-api/structure-logo.service';
import { UtilsFunctions } from '../../utils/utils';
import { AgentAvatarService } from '../../services/hub-api/agent-avatar.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-information-card',
  templateUrl: './information-card.component.html',
  styleUrls: ['./information-card.component.scss']
})
export class InformationCardComponent implements OnInit {
  @Input()
  interlocutor: any;
  @Input()
  isContact = false;
  @Input()
  isAbsent = false;

  @Output()
  onStarClick = new EventEmitter<{'isToggled': boolean, 'interlocutor': any}>();
  interlocutorType: any;
  expertTxt = interlocutorRoles.expert;
  checklink = UtilsFunctions.checklink;
  agentAvatarUrl: string;

  constructor(
    private sanitizer: DomSanitizer,
    private interlocutorService: InterlocutorService,
    private structureLogoService: StructureLogoService,
    private agentAvatarService: AgentAvatarService
  ) {}

  ngOnInit() {
    this.interlocutorType = this.interlocutorService.getInterlocutorRolesFromES(
      this.interlocutor
    );
    this.agentAvatarUrl = this.interlocutor.avatar
      ? this.agentAvatarService.getFileUrl(this.interlocutor.avatar)
      : defaultAvatarUrl;
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getLogoUrl(logo) {
    return this.structureLogoService.getFileUrl(logo);
  }

  onSolidStarToggle() {
    this.onStarClick.emit({
      isToggled: false,
      interlocutor: this.interlocutor
    });
  }

  onRegularStarToggle() {
    this.onStarClick.emit({
      isToggled: true,
      interlocutor: this.interlocutor
    });
  }

  goToLink(link, otherTab) {
    if (!this.isAbsent) {
      if (otherTab) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }

    }
  }
}
