import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environmentLoader } from '../../../environments/environmentLoader';
import { ApiService } from './api.service';

@Injectable()
export class FileService {
  hubApiHost: string;
  endpoint: string;
  http: HttpClient;
  apiService: any = {};
  authorizedMimeTypes = [];

  constructor(injector: Injector) {
    this.http = injector.get(HttpClient);
    this.apiService = injector.get(ApiService);
  }

  upload(file) {
    const form = new FormData();
    form.append('file', file);

    return this.http
      .post(`${this.apiService.hubApiHost}/${this.endpoint}`, form)
      .toPromise();
  }

  delete(fileId) {
    return this.http.delete(
      `${this.apiService.hubApiHost}/${this.endpoint}/${fileId}`
    );
  }

  getFileUrl(file) {
    return `${this.apiService.hubApiHost}/${this.endpoint}/${file.id}`;
  }
}
