<div id="espace-rdeo">
  <div>
    <h1 class="line">
      <span>{{ 'espace-rdeo.title' | translate | ucfirst }}</span>
    </h1>
  </div>
  <div>
    <p>{{ 'espace-rdeo.description' | translate | ucfirst }}</p>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="table-responsive-sm">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th colspan="3" scope="colgroup">{{ 'espace-rdeo.table-document' | translate | ucfirst }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rdeoDoc of getObjectValuesItem(this.rdeoDocument)">
              <td><i class="fas fa-2x {{ rdeoDoc?.file ? getMimeTypeLogo(rdeoDoc?.file?.mimeType) : getMimeTypeLogo('url')}}"></i></td>
              <ng-container *ngIf="rdeoDoc?.file">
                <td *ngIf="rdeoDoc?.isFileUrl">
                  <a class="pointer" (click)="saveFileUpload(rdeoDoc?.file)" title="{{ rdeoDoc?.finalTitle }}">{{ rdeoDoc?.finalTitle }}</a>
                </td>
                <td *ngIf="!rdeoDoc?.isFileUrl" title="{{ rdeoDoc?.finalTitle }}">{{ rdeoDoc?.finalTitle }}</td>
              </ng-container>
              <ng-container *ngIf="!rdeoDoc?.file && rdeoDoc?.url">
                <td *ngIf="rdeoDoc?.isValidUrl">
                  <a class="pointer" href="{{ rdeoDoc?.url }}" target="_blank" title="{{ rdeoDoc?.finalTitle }}">{{ rdeoDoc?.finalTitle }}</a>
                </td>
                <td *ngIf="!rdeoDoc?.isValidUrl" title="{{ rdeoDoc?.finalTitle }}">{{ rdeoDoc?.finalTitle }}</td>
              </ng-container>
              <td *ngIf="!rdeoDoc?.file && !rdeoDoc?.url" title="{{ rdeoDoc?.finalTitle }}">{{ rdeoDoc?.finalTitle }}</td>
              <td *ngIf="rdeoDoc?.createdat">{{ 'espace-rdeo.date-création' | translate | ucfirst }} {{ rdeoDoc?.createdat | date:'dd/MM/y' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="table-responsive-sm">
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th colspan="3" scope="colgroup">{{ 'espace-rdeo.table-video' | translate | ucfirst }}</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rdeoVid of getObjectValuesItem(this.rdeoVideo)">
              <td><i class="fas fa-2x {{getMimeTypeLogo('url')}}"></i></td>
                <td *ngIf="rdeoVid?.isValidUrl">
                  <a class="pointer" href="{{ rdeoVid?.url }}" target="_blank" title="{{ rdeoVid?.finalTitle }}">{{ rdeoVid?.finalTitle }}</a>
                </td>
                <td *ngIf="!rdeoVid?.isValidUrl" title="{{ rdeoVid?.finalTitle }}">{{ rdeoVid?.finalTitle }}</td>
              <td *ngIf="rdeoVid?.createdat">{{ 'espace-rdeo.date-création' | translate | ucfirst }} {{ rdeoVid?.createdat | date:'dd/MM/y' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
