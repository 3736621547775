<div class="modal-wrapper">
  <div class="modal-header hub-modal-header">
    <h1 class="title">{{ 'plan-card-search.modal.title' | translate | ucfirst }}</h1>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ul>
    <li *ngFor="let activityArea of activityAreas">
      {{ activityArea }}
    </li>
  </ul>
</div>
