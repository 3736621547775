export const routesUrl = {
  HOME: '',
  ENTITY: 'entite/:id',
  NOTIFICATIONS: 'mes-notifications',
  SEARCH: 'recherche',
  MY_WALLET: 'mon-portefeuille',
  CONTACT_US: 'contact-us',
  PROFILE: 'profil',
  STATIC_PAGE: 'page/:slug',
  PLAN: 'financement/:slug',
  MES_CONTACTS: 'mes-contacts',
  DOSSIERS_PROJECTEURS: 'dossiers-projecteurs',
  EVENTS_LIST: 'evenements-list',
  EVENTS_ADD: 'evenements-add',
  EVENTS_EDIT: 'evenements-edit/:id',
  PLAN_SITE: 'plan-site',
  CHARTE_QUALITY: 'charte-qualite',
  MY_CONTACTS: 'mes-contacts',
  ESPACE_RDEO: 'espace-rdeo',
  NOT_FOUND: '**'
};

export const dateFormat = 'DD/MM/YYYY';
export const telFormat = '[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}';
export const entityTypes = {
  project: 'project',
  company: 'company'
};

export const autorizedStructures = {
  adocc: "AD'OCC",
  region: 'REGION OCCITANIE'
};

export const endpoints = {
  company: 'entreprises',
  plan: 'dispositifs',
  interlocutor: 'agents',
  project: 'projets',
  user: 'utilisateurs',
  favorite: 'favoris',
  dispositifSearch: '_search/dispositifs',
  consultationCompany: 'consultations-entreprises',
  companySearch: '_search/entreprises',
  activityArea: 'filieres',
  mail: 'email',
  referentRequest: 'demandes-referent',
  gdiConnection: 'connect',
  gdiCompany: 'entreprise',
  gdiProject: 'projet',
  directory: 'contacts',
  rightHolders: 'gestion',
  tree: 'arbre-de-choix',
  editos: 'editos',
  speciality: 'specialites',
  structure: 'structures',
  territory: 'territoires',
  interlocutorSearch: '_search/agents',
  structureLogo: 'logos-structures',
  interlocutorinterlocutorRoles: 'agents/interlocutorQuality',
  interlocutorAllExpertsOnly: 'agents/experts',
  interlocutorinterlocutorRolesByEmail: 'agents/interlocutorQualityByEmail',
  interlocutorByEmail: 'agents/email',
  companies: 'companies',
  notification: 'notifications',
  agentConsultationCompany: 'agents-consultations-entreprises',
  agent: 'utilisateurs-agents',
  subvention: 'subventions',
  companyLogo: 'logos-entreprises',
  occupations: 'occupations',
  agentAvatar: 'avatars-agents',
  gdi: 'hub-entreprise/gdi',
  planFile: 'fichiers-dispositifs',
  planBySlug: 'dispositifs/slug',
  bpiFranceCreation: 'bpifrance-creation',
  progos: 'progos',
  encarts: 'encarts',
  event: 'evenements',
  eventLogo: 'logos-evenements',
  userConsultationsPlan: 'consultations',
  rdeoDocumentFile: 'fichier-rdeo-document',
  rdeoDocument: 'rdeo-document',
  rdeoVideo: 'rdeo-video'
};

export const userRoles = {
  representative: 'mandataire',
  rightHolder: 'ayant-droit'
};

export const encryptor = {
  passphrase: 'FVE64YGYR0RJFBTVRE7897RVV'
};

export const interlocutorRoles = {
  referent: 'Référent.e',
  expert: 'Expert.e Financement',
  specialist: 'Spécialiste'
};

export const typeSearch = {
  search: 'search',
  contact: 'contact',
  plan: 'plan'
};

export const datetimeFormat = 'DD/MM/YYYY HH:mm';
export const simpleDateComparisonFormat = 'YYYY-MM-DD';
export const simpleDateFormat = 'DD/MM/YYYY';
export const dateFormatProject = 'DD/MM/YYYY à HH[h]mm';
export const HoursMinutesSec = 'HH:mm:ss';
export const completeDateFormat = 'YYYY-MM-DD HH:mm:ss'

export const notificationTypes = {
  newProject: 'new-project',
  newReferent: 'new-referent',
  clickedOnIHaveAProject: 'clicked-on-I-have-a-project',
  companyActivity: 'company-activity',
  projectToCompanyTransition: 'project-to-company',
  clickedOnFileSubmission: 'clicked-on-file-submission'
};

export const recipientEmail = 'contact-hub@laregion.fr';
export const senderEmail = 'noreply@laregion.fr';

export const urlRoutes = {
  recherche: 'recherche',
  myWallet: 'mon-portefeuille',
  myNotification: 'mes-notifications'
};

export const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const searchDirectoryInputs = {
  lastname: 'lastname',
  specialities: 'specialities',
  territories: 'territories',
  activityAreas: 'activityAreas',
  structures: 'structures',
  plans: 'plans'
};

export const refuseReferentInputs = ['object', 'otherObject', 'motif', 'structures', 'referent', 'showStructure', 'showInterlocutor'];

export const refuseReferentObjectItems = [
  'Cette entreprise est hors périmètre d’intervention géographique de ma structure',
  'Cette entreprise est hors périmètre d’intervention de ma structure',
  'Cette entreprise ne relève pas de ma filière d’expertise',
  'Autres'
];

export const refuseReferentMail = {
  object: "Hub Entreprendre Occitanie : refus d'être référent d'une entreprise",
  refuse: "refuse d'être référent de l'entreprise",
  siret: "N° SIRET : ",
  departement: "Département : ",
  naf: "NAF : ",
  mandatory: 'dont le mandataire est',
  objectRefus: 'objet du refus : ',
  objectOtherRefus: 'Lequel : ',
  motifRefus: 'motif du refus : ',
  privilegedcontact: 'voici les informations concernant le contact privilégié de cette entreprise : ',
  privilegedcontactdetail: {
    contact: 'contact : ',
    phone: 'téléphone : ',
    mail: 'mail : '
  },
  structure: 'L’utilisateur indique la (les) structure(s) suivante(s) : ',
  referent: 'L’utilisateur indique le référent suivant : ',
  errorApiEntreprise: 'Nous rencontrons des difficultés, l\'api entreprise ne répond pas.',
  informationRefus: 'Informations concernant le refus',
  informationCompany: 'Informations de l\'entreprise',
};

export const ajoutActeurMail = {
  object: "Hub Entreprendre Occitanie : ajout comme membre de l'équipe projet d'une entreprise",
  body: {
    part1: 'Le référent',
    part2: 'vous a ajouté comme membre de l\'équipe projet de l\'entreprise',
    part3: 'Pour en savoir plus sur cette entreprise, veuillez consulter l\'extranet en URLFrontAgent',
    part4: 'A très bientôt.'
  }
};

export const mailHub = {
  emailFrom: 'noreply@laregion.fr',
  emailTo: 'contact-hub@laregion.fr'
};

export const defaultAvatarUrl = 'assets/icons/avatarprofile.png';

export const staticPages = {
  charte: {
    title: 'charte'
  },
  'plan-site': {
    title: 'sitemap'
  },
  'donnees-personnelles': {
    title: 'personal-data'
  }
};

export const maxNotifs = '99+';

export const projectSteps = {
  started: 1,
  inProgress: 2,
  done: 3,
};

export const departments = [
  {number: '01', name: 'Ain'},
  {number: '02', name: 'Aisne'},
  {number: '03', name: 'Allier'},
  {number: '04', name: 'Alpes de Haute Provence'},
  {number: '05', name: 'Hautes-Alpes'},
  {number: '06', name: 'Alpes-Maritimes'},
  {number: '07', name: 'Ardèche'},
  {number: '08', name: 'Ardennes'},
  {number: '09', name: 'Ariège'},
  {number: '10', name: 'Aube'},
  {number: '11', name: 'Aude'},
  {number: '12', name: 'Aveyron'},
  {number: '13', name: 'Bouches-du-Rhône'},
  {number: '14', name: 'Calvados'},
  {number: '15', name: 'Cantal'},
  {number: '16', name: 'Charente'},
  {number: '17', name: 'Charente-Maritime'},
  {number: '18', name: 'Cher'},
  {number: '19', name: 'Corrèze'},
  {number: '2A', name: 'Corse-du-sud'},
  {number: '2B', name: 'Haute-Corse'},
  {number: '21', name: 'Côte-d\'Or'},
  {number: '22', name: 'Côtes-d\'Armor'},
  {number: '23', name: 'Creuse'},
  {number: '24', name: 'Dordogne'},
  {number: '25', name: 'Doubs'},
  {number: '26', name: 'Drôme'},
  {number: '27', name: 'Eure'},
  {number: '28', name: 'Eure-et-Loir'},
  {number: '29', name: 'Finistère'},
  {number: '30', name: 'Gard'},
  {number: '31', name: 'Haute-Garonne'},
  {number: '32', name: 'Gers'},
  {number: '33', name: 'Gironde'},
  {number: '34', name: 'Hérault'},
  {number: '35', name: 'Ille-et-Vilaine'},
  {number: '36', name: 'Indre'},
  {number: '37', name: 'Indre-et-Loire'},
  {number: '38', name: 'Isère'},
  {number: '39', name: 'Jura'},
  {number: '40', name: 'Landes'},
  {number: '41', name: 'Loir-et-cher'},
  {number: '42', name: 'Loire'},
  {number: '43', name: 'Haute-Loire'},
  {number: '44', name: 'Loire-Atlantique'},
  {number: '45', name: 'Loiret'},
  {number: '46', name: 'Lot'},
  {number: '47', name: 'Lot-et-Garonne'},
  {number: '48', name: 'Lozère'},
  {number: '49', name: 'Maine-et-Loire'},
  {number: '50', name: 'Manche'},
  {number: '51', name: 'Marne'},
  {number: '52', name: 'Haute-marne'},
  {number: '53', name: 'Mayenne'},
  {number: '54', name: 'Meurthe-et-Moselle'},
  {number: '55', name: 'Meuse'},
  {number: '56', name: 'Morbihan'},
  {number: '57', name: 'Moselle'},
  {number: '58', name: 'Nièvre'},
  {number: '59', name: 'Nord'},
  {number: '60', name: 'Oise'},
  {number: '61', name: 'Orne'},
  {number: '62', name: 'Pas-de-Calais'},
  {number: '63', name: 'Puy-de-Dôme'},
  {number: '64', name: 'Pyrénées-Atlantiques'},
  {number: '65', name: 'Hautes-Pyrénées'},
  {number: '66', name: 'Pyrénées-Orientales'},
  {number: '67', name: 'Bas-Rhin'},
  {number: '68', name: 'Haut-Rhin'},
  {number: '69', name: 'Rhône'},
  {number: '70', name: 'Haute-Saône'},
  {number: '71', name: 'Saône-et-Loire'},
  {number: '72', name: 'Sarthe'},
  {number: '73', name: 'Savoie'},
  {number: '74', name: 'Haute-Savoie'},
  {number: '75', name: 'Paris'},
  {number: '76', name: 'Seine-Maritime'},
  {number: '77', name: 'Seine-et-Marne'},
  {number: '78', name: 'Yvelines'},
  {number: '79', name: 'Deux-sèvres'},
  {number: '80', name: 'Somme'},
  {number: '81', name: 'Tarn'},
  {number: '82', name: 'Tarn-et-Garonne'},
  {number: '83', name: 'Var'},
  {number: '84', name: 'Vaucluse'},
  {number: '85', name: 'Vendée'},
  {number: '86', name: 'Vienne'},
  {number: '87', name: 'Haute-vienne'},
  {number: '88', name: 'Vosges'},
  {number: '89', name: 'Yonne'},
  {number: '90', name: 'Territoire de Belfort'},
  {number: '91', name: 'Essonne'},
  {number: '92', name: 'Hauts-de-Seine'},
  {number: '93', name: 'Seine-Saint-Denis'},
  {number: '94', name: 'Val-de-marne'},
  {number: '95', name: ' Val-d\'oise'},
  {number: '971', name: 'Guadeloupe'},
  {number: '972', name: 'Martinique'},
  {number: '973', name: 'Guyane'},
  {number: '974', name: 'La réunion'},
  {number: '976', name: 'Mayotte'}

];

export const planTypes = {
  permanent: 'Permanent',
  exceptionnel: 'Exceptionnel',
  appelAProjet: 'Appel à projets'
};

export const mimeTypes = {
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png: 'image/png',
  jpg: 'image/jpeg',
  gif: 'image/gif',
  ico: 'image/x-icon',
  svg: 'image/svg+xml',
  tiff: 'image/tiff',
  webp: 'image/webp',
  mp4: 'video/mp4',
  mp3: 'audio/mpeg',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  ppt: 'application/vnd.ms-powerpoint',
  pptx:'application/vnd.openxmlformats-officedocument.presentationml.presentation'
};

export const subvention = {
  avisInstructionsCode: {
    eligible: 'OFAV',
    irrecevable: 'NDEF',
    ineligible: 'NDEF1',
    attente: 'ATT'
  },
  avisInstructionDisplay: {
    oui: 'Oui',
    non: 'Non',
    attente: 'A l\'etude'
  },
  noDisplay: '---'
};

export const eventInputs =
  ['title', 'postcontentplain', 'image', 'permalink', 'datedebut', 'datefin', 'heuredebut', 'heurefin'];


export const eventStatut = {
  attente_validation: 'En attente de validation',
  valide: 'Validé',
  refuse: 'Refusé'
};

export enum ContactTypeEnum {
  ALL_RESULTS = -1,
  COMPANIES = 1,
  PROJECTS = 2
}

export const nameTabSearchPage = {
  contact: 'contact',
  plan: 'plan',
  interlocutor: 'interlocutor'
};

export const roles = {
  mandataire: 'Mandataire social',
  collaborateur: 'Collaborateur',
  prestataire: 'Prestataire externe',
  lienNonVerifie: 'Lien non vérifié',
  porteurDeProjet: 'Porteur de projet'
};

export const ITEMS_PER_PAGE = 10;
export const INITIAL_PAGE_PAGINATION = 1;
export const INITIAL_TOTAL_ITEMS = 0;

