<div class="charte-qualite">
  <div class="charte-qualite-title" [style.width.px]="widthBlur" #titleContent>
    <div class="title-content">
      <div class="title-logo-container">
        <img class="title-logo" src="assets/images/logo.png" alt="{{ 'header.picture' | translate | ucfirst }}"/>
      </div>
      <div class="title-text">
        <h1>
          {{'charte-qualite.title' | translate | ucfirst}}
        </h1>
        <h2>
          {{'charte-qualite.subtitle' | translate | ucfirst}}
        </h2>
      </div>
    </div>
  </div>
  <div class="charte-qualite-body" (scroll)="onScroll($event)" #charteQualite>
    <div class="charte-qualite-text" [style.margin-bottom.px]="marginBottomCharteText">
      <div class="charte-qualite-text-part-1">
        <h2>Préambule</h2>
        <p>La Région Occitanie / Pyrénées - Méditerranée a élaboré sa Stratégie Régionale pour l’Emploi et la Croissance
          qui constitue une démarche globale, reflet d’une vision transversale et cohérente du projet de l’Occitanie en
          matière de développement économique. Mais une Région stratège n’est pas une Région seule à la manoeuvre. En
          suivant les différentes concertations, elle a fédéré, dans une action de proximité au bénéfice de tous les
          porteurs de projets et des entreprises, un partenariat étendu dans lequel tous les échelons territoriaux ont
          leur place, chacun selon ses compétences : le Réseau des Développeurs Economiques.</p>

        <p>Ce réseau est composé de structures réparties sur l’ensemble du territoire et dont le métier est
          l’accompagnement des créateurs, repreneurs et chefs d’entreprises.</p>

        <p>Les développeurs peuvent être généralistes ou spécialisés selon les catégories suivantes : création ou
          reprise, innovation, développement, export, transmission, structuration financière, attractivité des
          territoires.</p>

        <p>Le réseau est constitué des collaborateurs généralistes et spécialisés suivants :</p>
        <ul class="list-arrow">
          <li>La Région</li>
          <li>Etablissements Publics de Coopération Intercommunale (EPCI)</li>
          <li>Réseau des Chambres de Commerce et d’Industrie</li>
          <li>Réseau des Chambres de Métiers et de l’Artisanat</li>
          <li>L’agence régionale de développement économique Ad’Occ</li>
          <li>Pépinières et incubateurs</li>
          <li>Réseaux spécialisés</li>
        </ul>

        <p>La structuration du Réseau des Développeurs Economiques d’Occitanie répond aux ambitions suivantes :</p>
        <ul class="list-arrow">
          <li>Réunir en un réseau intégré des professionnels de l’accompagnement, en assurant un maillage territorial
            optimisé
          </li>
          <li>Proposer un accompagnement des projets de développement des entreprises de l’ante-création à la
            transmission et des actions au service de l’attractivité des territoires
          </li>
          <li>Créer au sein du réseau les synergies et collaborations nécessaires entre les acteurs afin de simplifier
            et de fluidifier le parcours de l’entrepreneur
          </li>
          <li>Créer le lien entre cet accompagnement humain et l’utilisation de la plateforme numérique « Hub
            Entreprendre Occitanie »
          </li>
        </ul>

        <p>En intégrant le réseau, chaque structure signe la Charte de partenariat du Réseau des Développeurs
          Economiques et s’engage à diffuser, au sein de sa structure, la démarche qualité. Les engagements contenus
          dans cette démarche permettent de garantir la satisfaction des entrepreneurs du territoire, dans un processus
          d’amélioration continue.</p>

        <p>La présente Charte qualité, signée par chaque membre du Réseau des Développeurs Economiques, permet de
          définir les engagements réciproques de la Région et de ses partenaires et les modalités d’utilisation de la
          plateforme Hub Entreprendre Occitanie.</p>

        <p>Le Hub Entreprendre Occitanie répond aux objectifs suivants :</p>

        <ul class="list-triangle">
          <li>Accompagner les créateurs, les repreneurs et les chefs d’entreprises à travers un parcours
            d’accompagnement qui couvre tous leurs projets : création ou reprise d'entreprises, implantation,
            croissance, innovation, internationalisation, transmission
          </li>
          <li>Simplifier les démarches des entrepreneurs
          </li>
        </ul>

        <h3>Rappel des définitions et rôles dans le Hub Entreprendre Occitanie :</h3>

        <ul class="list-hyphen">
          <li>
            <span class="text-underline">Simple interlocuteur</span> : Collaborateur sans rôle spécifique auprès
            des
            entreprises mais dont les coordonnées
            sont uniquement visibles par les membres du Réseau des Développeurs Economiques dans l’extranet Agents &
            Partenaires (ex un manager)
          </li>
          <li><span class="text-underline">Référent</span> : Au moment de la création de son compte, une entreprise
            choisit un référent. Ce référent est un
            membre du Réseau des Développeurs Economiques. Son rôle consiste à être un interlocuteur privilégié de
            l’entreprise ou du porteur de projet. Il est en charge de créer le lien avec les autres interlocuteurs
            spécialistes dans le but de simplifier les démarches de l’entreprise ou du porteur de projet pour un suivi
            plus efficace et concerté
          </li>
          <li><span class="text-underline">Expert d'un financement</span> : Un expert de financement est un agent qui
            maitrise l’ensemble des règles
            d’interventions d’un dispositif d’aide aux entreprises. Un expert est désigné par dispositif. Il est le
            point d’entrée des demandes de renseignement des référents sur les critères d’éligibilité requis et sur les
            modalités de dépôt d’un dossier sur ce dispositif. L’expert pourra, le cas échéant, réorienter la demande
            vers un spécialiste filière ou territoire. Les coordonnées de l’expert de financement sont uniquement
            accessibles aux membres du Réseau des Développeurs Economiques via l’extranet Agents & Partenaires
          </li>
          <li><span class="text-underline">Spécialiste</span> : un spécialiste a une filière d’expertise ou une
            compétence, qu’il va pouvoir mettre à
            disposition des référents pour accompagner certains projets. Les coordonnées du spécialiste sont uniquement
            accessibles aux membres du Réseau des Développeurs Economiques via l’extranet Agents & Partenaires
          </li>
          <li><span class="text-underline">Groupe projet</span> : pour chaque projet d’entreprise, le référent peut
            s’entourer de compétences spécifiques et
            complémentaires en constituant un groupe projet. Le référent invite un ou plusieurs spécialistes à intégrer
            le groupe projet
          </li>
        </ul>
      </div>
      <div class="charte-qualite-text-part-2">
        <h2>LA CHARTE QUALITÉ</h2>
        <p class="bold">La démarche qualité s’articule autour de 2 axes et 6 engagements :</p>
        <ol class="list-style-none">
          <li class="list-roman-I"><span class="bold">Simplifier et sécuriser les démarches des entrepreneurs</span>
            <ol>
              <li>Portail unique co-construit et évolutif</li>
              <li>Simplification du parcours d’accompagnement</li>
              <li>Transparence et confidentialité</li>
            </ol>
          </li>
          <li class="list-roman-II"><span class="bold">Assurer une prestation de qualité & de proximité</span>
            <ol>
              <li value="4">Une offre de services complète</li>
              <li value="5">Un contact qualifié et disponible</li>
              <li value="6">Un référent au coeur de la relation</li>
            </ol>
          </li>
        </ol>

        <h3>SIMPLIFIER ET SECURISER LES DEMARCHES DES ENTREPRENEURS</h3>
        <hr>

        <h4>ENGAGEMENT N°1 : Un portail unique co-construit et évolutif</h4>
        <ul class="list-triangle">
          <li><span class="text-underline">Contenu de l’engagement</span> :</li>
        </ul>

        <p>La Région Occitanie met à disposition un portail Web unique réunissant les informations utiles à la vie de
          l’entreprise, de la création à la transmission : aides, actualités, événements…</p>

        <p>Le Hub Entreprendre Occitanie est développé, dans un processus d’amélioration continue du service, selon la
          méthode Agile.</p>

        <table>
          <colgroup>
            <col span="1" style="width: 50%;">
            <col span="1" style="width: 50%;">
          </colgroup>

          <thead>
          <th>Actions menées par la Région Occitanie</th>
          <th>Actions menées par les partenaires</th>
          </thead>
          <tbody>
          <tr>
            <td>
              <ul class="list-check">
                <li>Animer des ateliers de cadrage avec les partenaires, maquettage…</li>
                <li>Intégrer les remontées des besoins d’améliorations/évolutions des utilisateurs à l’administrateur
                </li>
                <li>Présenter les propositions d’évolutions, toutes les 3 semaines, à l’occasion des séances de
                  priorisation
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-check">
                <li>Participer aux évolutions par retours d’expérience</li>
                <li>Répondre aux enquêtes de satisfaction</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>

        <h4>ENGAGEMENT N°2 : Simplification du parcours d’accompagnement</h4>
        <p>Le Hub Entreprendre Occitanie réunit les informations de différentes plateformes afin que les entrepreneurs
          n’aient pas à multiplier leurs démarches.</p>

        <table>
          <colgroup>
            <col span="1" style="width: 50%;">
            <col span="1" style="width: 50%;">
          </colgroup>

          <thead>
          <th>Actions menées par la Région Occitanie</th>
          <th>Actions menées par les partenaires</th>
          </thead>
          <tbody>
          <tr>
            <td>
              <ul class="list-check">
                <li>Mettre en place des flux de données entre les différentes plateformes : Convention Etat/Région pour
                  l’exploitation des données API de l’Aide Publique Simplifiée, mise en place d’un SSO entre les
                  différentes plateformes, Porte- document à disposition
                </li>
                <li>Rendre accessibles les données aux collaborateurs du Réseau des Développeurs Economiques sur
                  l’extranet Agents & Partenaires du Hub Entreprendre Occitanie
                </li>
                <li>Demander à 100% des entreprises qui déposent une aide directe à la Région de créer un compte
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-check">
                <li>Demander à 100% des entreprises qui déposent une aide directe à la Région de créer un compte</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>

        <h4>ENGAGEMENT N°3 : Transparence et confidentialité</h4>
        <p>Toutes les informations (données personnelles et données entreprise) relatives aux utilisateurs sont
          strictement personnelles et font l’objet d’une confidentialité absolue.</p>

        <table>
          <colgroup>
            <col span="1" style="width: 50%;">
            <col span="1" style="width: 50%;">
          </colgroup>

          <thead>
          <th>Actions menées par la Région Occitanie</th>
          <th>Actions menées par les partenaires</th>
          </thead>
          <tbody>
          <tr>
            <td>
              <ul class="list-check">
                <li>Appliquer strictement et rigoureusement les règles instaurées par le RGPD & la législation afférente
                  au secret des affaires
                </li>
                <li>Mettre en place des mesures techniques et organisationnelles pour garantir la sécurité du site
                </li>
                <li>Former des collaborateurs sur le cadre légal et réglementaire
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-check">
                <li>Signer la Charte du Réseau des Développeurs Economiques et la Charte qualité</li>
                <li>S’engager à une totale confidentialité</li>
                <li>Ne pas échanger de données confidentielles communiquées par les entreprises</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>

        <h3>ASSURER UNE PRESTATION DE QUALITE & DE PROXIMITE</h3>
        <hr>

        <h4>ENGAGEMENT N°4 : Une offre de services complète</h4>
        <p>Le Hub Entreprendre Occitanie rassemble et donne accès à des informations sur les aides et accompagnements
          proposés par la Région et également sur l’offre des principaux partenaires du développement économique
          (Bpifrance, France Active, etc.)<br>Il fournit une information claire, simple, synthétique et concrète
          permettant aux entreprises de disposer des
          éléments pour mener à bien leurs projets.
        </p>

        <table>
          <colgroup>
            <col span="1" style="width: 50%;">
            <col span="1" style="width: 50%;">
          </colgroup>

          <thead>
          <th>Actions menées par la Région Occitanie</th>
          <th>Actions menées par les partenaires</th>
          </thead>
          <tbody>
          <tr>
            <td>
              <ul class="list-check">
                <li>Mettre en ligne l’ensemble des dispositifs d’aides directes à destination des entreprises de la
                  Région Occitanie (toutes directions confondues) et les synthétiser
                </li>
                <li>Mettre en ligne l’ensemble des dispositifs d’aides indirectes à destination des entreprises de la
                  Région Occitanie (toutes directions confondues) et des aides directes des partenaires (BPI France…),
                  et les synthétiser
                </li>
                <li>Mettre en ligne du contenu complet et à jour : Mémento récapitulatif, FAQ, Webinaire, tutoriels…
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-check">
                <li>S’assurer de la mise à jour des dispositifs et outils, sous un délai maximal de 5 jours</li>
                <li>Publier 100% des évènements liés au développement économique à J-30 en précisant le territoire et la
                  thématique
                </li>
                <li>Modifier sans délai tout évènement si des changements ont lieu</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>

        <h4>ENGAGEMENT N°5 : Un contact qualifié et disponible</h4>
        <p>Le Hub Entreprendre Occitanue met à disposition des informations actualisées et exhaustives.</p>

        <table>
          <colgroup>
            <col span="1" style="width: 50%;">
            <col span="1" style="width: 50%;">
          </colgroup>

          <thead>
          <th>Actions menées par la Région Occitanie</th>
          <th>Actions menées par les partenaires</th>
          </thead>
          <tbody>
          <tr>
            <td>
              <ul class="list-check">
                <li>Mettre à jour continuellement les informations
                </li>
                <li>Mettre à jour tous les référents (entrées/sorties) par les administrateurs du Hub 2 fois/an suite à
                  une campagne mail
                </li>
                <li>Former trimestriellement les nouveaux agents Région et membres du Réseau des développeurs
                </li>
                <li>Répondre aux mails adressés à <a href="mailto:contact-hub@laregion.fr">contact-hub@laregion.fr</a>
                  sous 24h pour signifier la bonne prise en
                  compte de la demande
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-check">
                <li>Informer l’administrateur du Hub dans la semaine qui suit les mouvements de personnel ou le départ /
                  arrivée d’un référent : communiquer le/les référent.s qui vont prendre le relai sur le portefeuille
                </li>
                <li>Remonter des informations du profil à la Région via <a href="mailto:contact-hub@laregion.fr">contact-hub@laregion.fr</a>
                  pour MAJ du BO par les
                  administrateurs du Hub
                </li>
                <li>Signaler leurs absences et désigner un Back-up</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>

        <h4>ENGAGEMENT N°6 : Un référent au coeur de la relation</h4>
        <p>Le Hub Entreprendre Occitanie permet à l’entreprise de choisir son interlocuteur privilégié : le référent. Il
          l’oriente, l’aide à qualifier son projet et lui propose un accompagnement adapté à ses besoins.</p>

        <table>
          <colgroup>
            <col span="1" style="width: 50%;">
            <col span="1" style="width: 50%;">
          </colgroup>

          <thead>
          <th>Actions menées par la Région Occitanie</th>
          <th>Actions menées par les partenaires</th>
          </thead>
          <tbody>
          <tr>
            <td>
              <ul class="list-check">
                <li>Informer et former les membres du réseau des développeurs économiques des nouveaux dispositifs,
                  actualités économiques, les évolutions des fonctionnalités du Hub…
                </li>
                <li>Actualiser et diffuser les contacts et les référents et leurs compétences au service des entreprises
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-check">
                <li>Répondre aux notifications de choix de référent sous 48H</li>
                <li>Indiquer un suppléant en cas de refus
                </li>
                <li>Contacter sous 7 jours ouvrés les entreprises qui qualifient un projet</li>
                <li>Constituer un groupe projet si besoin et l’animer
                </li>
                <li>Accompagner les entreprises de la faisabilité du projet à sa réalisation en passant par le montage
                  du dossier de financement
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="text-center mt-3"><p class="bold italic">6 ENGAGEMENTS POUR UN SERVICE DE QUALITE & SUR MESURE</p>
          <p>***</p></div>


      </div>

    </div>
    <div class="validation-button-container" [hidden]="!validationButtonIsShown">
      <button class="validation-button"
              (click)="openCharteQualitePopin()">{{'charte-qualite.read-and-approved' | translate | ucfirst}} <img
        class="button-icons-handshake"
        src="assets/icons/handshake.svg"></button>
    </div>
  </div>
  <div class="blur-content" [style.width.px]="widthBlur" [hidden]="hideBlur" #blurContent>
    <div class="blur">
      <div class="text-approve-charte">
        <span>{{'charte-qualite.scroll' | translate | ucfirst}}</span><img class="button-icons-arrow"
                                                                           src="assets/icons/arrow-right.svg">
      </div>
    </div>
  </div>
</div>

