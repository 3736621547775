<div class="hub-my-wallet-page container-fluid">
  <div class="hub-my-wallet-page-container">
    <h3 class="line">
      <span>{{ 'my-wallet.my-wallet' | translate | ucfirst }}</span>
    </h3>

    <app-hub-tabs *ngIf="!isLoading">
      <hub-tab titre="{{ 'my-wallet.all' | translate | ucfirst }} ({{ allCompanies.length }})">
        <div *ngFor="let company of sortByName(allCompanies)">
          <hub-company-card [agent_structure]="agent_structure" [entity]="company" [modifyTextButton]="true"
                            [agent_interlocutor]="agent_interlocutor" [showReferent]="false" [showSiret]="true" [showName]="false" [showStructure]="false"
                            (clickPageButton)="goToCompanyOrProjectPage($event)"></hub-company-card>
        </div>
      </hub-tab>
      <hub-tab titre="{{ 'my-wallet.enterprise' | translate | ucfirst }} ({{ companies.length }})">
        <div *ngFor="let company of sortByName(companies)">
          <hub-company-card [agent_structure]="agent_structure" [entity]="company" [modifyTextButton]="true"
                            [agent_interlocutor]="agent_interlocutor" [showReferent]="false" [showSiret]="true" [showName]="false" [showStructure]="false"
                            (clickPageButton)="goToCompanyOrProjectPage($event)"></hub-company-card>
        </div>
      </hub-tab>
      <hub-tab titre="{{ 'my-wallet.project' | translate | ucfirst }} ({{ projects.length }})">
        <div *ngFor="let project of sortByName(projects)">
          <hub-company-card [agent_structure]="agent_structure" [entity]="project" [modifyTextButton]="true"
                            [agent_interlocutor]="agent_interlocutor" [showReferent]="false" [showSiret]="true" [showName]="false" [showStructure]="false"
                            (clickPageButton)="goToCompanyOrProjectPage($event)"></hub-company-card>
        </div>
      </hub-tab>
    </app-hub-tabs>
    <div class="hub-search-spinner">
      <app-hub-spinner-dots *ngIf="isLoading"></app-hub-spinner-dots>
    </div>
  </div>
</div>
