import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-entity-header',
  templateUrl: './entity-header.component.html',
  styleUrls: ['./entity-header.component.scss']
})
export class EntityHeaderComponent {
  @Input()
  entity: any;

  constructor() {}
}
