<h1 class="line">
  <span>Modifier un événement</span>
</h1>

<div *ngIf="!isLoading">
  <evenements-form [event]="event"
                   (onCancel)="redirectToList()"
                   (onSubmit)="updateEvent($event)"
                   (onDelete)="deleteEvent($event)"
                   [isRefused]="isRefused(event.statut.libelle)"></evenements-form>
</div>
