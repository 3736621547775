import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';

import {SessionService} from '../services/session.service';
import {AgentService} from '../services/hub-api/agent.service';
import {InterlocutorService} from "../services/hub-api/interlocutor.service";

@Injectable()
export class AccessCharteQualityGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    protected agentService: AgentService,
    private sessionService: SessionService,
    private interlocutorService: InterlocutorService
  ) {
  }

  /**
   * If the user connected already approved charte quality or is not referenthe can't access charte quality
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const isLoggedIn: boolean = await this.keycloakService.isLoggedIn();
      if (!isLoggedIn) {
        await this.router.navigate(['/']);
        return resolve(false);
      } else {
        const isAgentAllowedToAccess = await this.isAgentAllowedToAccessCharte();
        if (isAgentAllowedToAccess) {
          return resolve(true);
        } else {
          await this.router.navigate(['/']);
          return resolve(false);
        }
      }
    });
  }

  async isAgentAllowedToAccessCharte() {
    const agentSession: any = await this.sessionService.getAgent();
    if (!agentSession) {
      const userInfo: any = await this.keycloakService.getKeycloakInstance().loadUserInfo();
      const keycloakId = userInfo.sub;
      const agentInDb: any = await this.agentService.getAgent(keycloakId);
      if (agentInDb) {
        const interlocutor: any = agentInDb.interlocutor ? agentInDb.interlocutor : await this.interlocutorService.getInterlocutorByEmail(agentInDb.email);
        return interlocutor.referent && !agentInDb.isCharteApproved;
      }
    } else {
      return agentSession.isReferent && !agentSession.isCharteApproved;
    }
  }
}
