import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from '../environments/environment';
import {AuthGuard} from './guards/auth.guard';
import {ReferentGuard} from './guards/referent.guard';
import {EntityComponent} from './pages/entity/entity.component';
import {HomeComponent} from './pages/home/home.component';
import {MyWalletComponent} from './pages/my-wallet/my-wallet.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {SearchComponent} from './pages/search/search.component';
import {StructureGuard} from './guards/structure.guard';
import {ProfileComponent} from './pages/profile/profile.component';
import {StaticPageComponent} from './pages/static-page/static-page.component';
import {DispositifComponent} from './pages/dispositif/dispositif.component';
import {ContactUsComponent} from './pages/contact/contact-us.component';
import {routesUrl} from "./constants";
import {DossiersProjecteursComponent} from "./pages/dossiers-projecteurs/dossiers-projecteurs.component";
import {EvenementsListComponent} from "./pages/evenements/evenements-list/evenements-list.component";
import {EvenementsAddComponent} from "./pages/evenements/evenements-add/evenements-add.component";
import {EvenementsEditComponent} from "./pages/evenements/evenements-edit/evenements-edit.component";
import {MyContactsComponent} from "./pages/my-contacts/my-contacts.component";
import {PlanSiteComponent} from "./pages/plan-site/plan-site.component";
import {EventEditionGuard} from "./guards/event-edition.guard";
import {CharteQualityGuard} from './guards/charte-quality.guard';
import {CharteQualiteComponent} from './pages/charte-qualite/charte-qualite.component';
import {AccessCharteQualityGuard} from './guards/access-charte-quality.guard';
import {EspaceRdeoComponent} from './pages/espace-rdeo/espace-rdeo.component';

const routes: Routes = [
  {
    path: routesUrl.HOME,
    component: HomeComponent,
    canActivate: [CharteQualityGuard]
  },
  {
    path: routesUrl.ENTITY,
    component: EntityComponent,
    canActivate: [AuthGuard, StructureGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.NOTIFICATIONS,
    component: NotificationsComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.SEARCH,
    component: SearchComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.MY_WALLET,
    component: MyWalletComponent,
    canActivate: [AuthGuard, ReferentGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.CONTACT_US,
    component: ContactUsComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.PROFILE,
    component: ProfileComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.STATIC_PAGE,
    component: StaticPageComponent,
    canActivate: [CharteQualityGuard]
  },
  {
    path: routesUrl.PLAN,
    component: DispositifComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.DOSSIERS_PROJECTEURS,
    component: DossiersProjecteursComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.MES_CONTACTS,
    component: MyContactsComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.ESPACE_RDEO,
    component: EspaceRdeoComponent,
    canActivate: [AuthGuard, CharteQualityGuard]
  },
  {
    path: routesUrl.PLAN_SITE,
    component: PlanSiteComponent,
    canActivate: [CharteQualityGuard]
  },
  {
    path: routesUrl.CHARTE_QUALITY,
    component: CharteQualiteComponent,
    canActivate: [AccessCharteQualityGuard]
  },
  {
    path: routesUrl.NOT_FOUND,
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: [
    {
      provide: 'isDevEnvironment',
      useValue: () => environment.production === false
    }
  ]
})
export class AppRoutingModule {
}
