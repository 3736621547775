<div class="modal-wrapper">
  <div class="modal-body hub-modal-body">
    <p class="modal-confirmation-text">
      {{'charte-qualite.modal-content' | translate | ucfirst}}</p>
    <button type="button" class="close-button" aria-label="Close" (click)="onPopinClosure()">
      <span aria-hidden="true">
          {{'charte-qualite.modal-button' | translate | ucfirst}}</span>
    </button>
  </div>
</div>
