import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SearchArray} from 'src/app/components/search-card/dependencies/search-array';
import {
  ContactTypeEnum,
  INITIAL_PAGE_PAGINATION,
  INITIAL_TOTAL_ITEMS,
  ITEMS_PER_PAGE,
  nameTabSearchPage
} from 'src/app/constants';
import {CompanyService} from 'src/app/services/hub-api/company.service';
import {HubBreadCrumbsService} from 'src/app/services/hub-breadcrumbs.service';
import {AuthService} from 'src/app/services/auth.service';
import {SessionService} from 'src/app/services/session.service';
import {InterlocutorService} from 'src/app/services/hub-api/interlocutor.service';
import {PlanService} from '../../services/hub-api/plan.service';
import {SearchService} from 'src/app/services/search.service';
import {PaginationInstance} from "ngx-pagination";
import {AgentService} from "../../services/hub-api/agent.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  paramValues: SearchArray;

  entities = [];
  companies = [];
  projects = [];
  plans = [];
  interlocutors = [];

  isFavoriteRequestRunning: boolean;

  query = '';

  agent: any;
  agentContacts: any = [];
  agent_structure: any = {};
  agent_interlocutor: any = {};
  isLoadingAgentStructure = true;
  selectedSearch = '';
  tab = nameTabSearchPage.contact;

  totalNumberOfProjects = INITIAL_TOTAL_ITEMS;
  totalNumberOfCompanies = INITIAL_TOTAL_ITEMS;
  totalNumberOfCompaniesAndProjects = INITIAL_TOTAL_ITEMS;

  // Variable used to avoid having tabs changing in every data update
  tabsAlreadyInitialized = false;

  isSearchingCompanies = false;
  isSearchingProjects = false;
  isSearchingCompaniesAndProjects = false;
  isSearchingPlansOrInterlocutor = false;

  hasSearchedOnceProjects = false;
  hasSearchedOnceCompanies = false;
  hasSearchedOnceCompaniesAndProjects = false;
  hasSearchedOncePlansOrInterlocutor = false;

  // Pagination configuration
  configPaginationCompaniesAndProjects: PaginationInstance = {
    id: "pagination_all_results",
    itemsPerPage: ITEMS_PER_PAGE,
    currentPage: INITIAL_PAGE_PAGINATION,
    totalItems: 0
  };

  configPaginationCompanies: PaginationInstance = {
    id: "pagination_companies",
    itemsPerPage: ITEMS_PER_PAGE,
    currentPage: INITIAL_PAGE_PAGINATION,
    totalItems: 0
  };

  configPaginationProjects: PaginationInstance = {
    id: "pagination_projects",
    itemsPerPage: ITEMS_PER_PAGE,
    currentPage: INITIAL_PAGE_PAGINATION,
    totalItems: 0
  };

  configPaginationPlanOrInterlocutor: PaginationInstance = {
    id: "pagination_plan_or_interlocutor",
    itemsPerPage: ITEMS_PER_PAGE,
    currentPage: INITIAL_PAGE_PAGINATION
  };

  currentPageCompanies = INITIAL_PAGE_PAGINATION;
  currentPageProjects = INITIAL_PAGE_PAGINATION;
  currentPageCompaniesAndProjects = INITIAL_PAGE_PAGINATION;

  selectedTab: ContactTypeEnum = ContactTypeEnum.ALL_RESULTS;

  public tabs = nameTabSearchPage;
  public companyTypeEnum = ContactTypeEnum;
  public searchValues: SearchArray;

  constructor(
    private companyService: CompanyService,
    private planService: PlanService,
    private breadcrumbs: HubBreadCrumbsService,
    private translateService: TranslateService,
    private router: Router,
    private sessionService: SessionService,
    private interlocutorService: InterlocutorService,
    private authService: AuthService,
    private searchService: SearchService,
    private agentService: AgentService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has('tab')) {
        this.selectedSearch = params.get('tab');
      } else {
        this.selectedSearch = nameTabSearchPage.contact;
      }
      this.tab = this.selectedSearch;
      this.paramValues = this.searchService.query;
      // Ne pas lancer de recherche si par exemple on vient du plan du site
      if (!params.has('notSearch') && this.paramValues) {
        this.search({ type: this.selectedSearch, search: this.paramValues });
      }
    });
    this.sessionService.getAgentAsObservable().subscribe(async (agent: any) => {
      if (agent) {
        this.agent = agent;
        await this.agentService.getContacts(agent.id).then(contacts => {
          this.agentContacts = contacts;
        });
        this.interlocutorService
          .getById(agent.interlocutorid)
          .then((interlocutor: any) => {
            this.agent_interlocutor = interlocutor;
            this.agent_structure = interlocutor.structure;
            this.isLoadingAgentStructure = false;
          });
      }
    });

    this.breadcrumbs.add([
      {
        label: this.translateService.instant('breadcrumbs.my-search'),
        url: '/recherche',
        params: []
      }
    ]);
  }

  switchTab(tab) {
    this.clearAll();
    this.tab = tab;
  }

  search(searchObject: { type: string; search: SearchArray }) {
    this.selectedSearch = searchObject.type;
    this.searchValues = searchObject.search;

    if (!this.authService.isTokenExpired()) {
      this.clearAll();
      if (searchObject) {
        if (searchObject.type === nameTabSearchPage.contact) {
          this.clearAll();
          this.updateProjectAndCompanies(this.currentPageCompanies, ContactTypeEnum.COMPANIES);
          this.updateProjectAndCompanies(this.currentPageProjects, ContactTypeEnum.PROJECTS);
          this.updateProjectAndCompanies(this.currentPageCompaniesAndProjects, ContactTypeEnum.ALL_RESULTS);
        } else if (searchObject.type === nameTabSearchPage.plan) {
          this.isSearchingPlansOrInterlocutor = true;
          this.searchPlans(searchObject.search);
        } else if (searchObject.type === nameTabSearchPage.interlocutor) {
          this.isSearchingPlansOrInterlocutor = true;
          this.searchInterlocutors(searchObject.search);
        }
      }
      this.searchService.query = searchObject.search;
      this.searchService.query.tab = searchObject.type;
    } else {
      this.authService.goToLogin();
    }
  }

  searchPlans(searchArray: SearchArray) {
    this.planService
      .search(searchArray)
      .then((response: any) => {
        this.hasSearchedOncePlansOrInterlocutor = true;
        if (response.length > 0) {
          this.plans = response;
        }
        this.isSearchingPlansOrInterlocutor = false;
      })
      .catch(() => {
        this.hasSearchedOncePlansOrInterlocutor = true;
        this.isSearchingPlansOrInterlocutor = false;
      });
  }

  searchInterlocutors(searchArray: SearchArray) {
    this.interlocutorService
      .search(this.interlocutorService.buildQueryParams(searchArray))
      .then((response: any) => {
        this.hasSearchedOncePlansOrInterlocutor = true;
        this.interlocutors = response || [];
        this.isSearchingPlansOrInterlocutor = false;
      })
      .catch(() => {
      this.hasSearchedOncePlansOrInterlocutor = true;
      this.isSearchingPlansOrInterlocutor = false;
    });
  }

  clearAll() {
    this.entities = [];
    this.companies = [];
    this.projects = [];
    this.plans = [];
    this.interlocutors = [];

    this.configPaginationCompaniesAndProjects.currentPage = INITIAL_PAGE_PAGINATION;
    this.configPaginationCompaniesAndProjects.totalItems = INITIAL_TOTAL_ITEMS;

    this.configPaginationCompanies.currentPage = INITIAL_PAGE_PAGINATION;
    this.configPaginationCompanies.totalItems = INITIAL_TOTAL_ITEMS;

    this.configPaginationProjects.currentPage = INITIAL_PAGE_PAGINATION;
    this.configPaginationProjects.totalItems = INITIAL_TOTAL_ITEMS;

    this.configPaginationPlanOrInterlocutor.currentPage = INITIAL_PAGE_PAGINATION;
    this.configPaginationPlanOrInterlocutor.totalItems = INITIAL_TOTAL_ITEMS;

    this.hasSearchedOnceCompaniesAndProjects = false;
    this.hasSearchedOnceProjects = false;
    this.hasSearchedOnceCompanies = false;
    this.hasSearchedOncePlansOrInterlocutor = false;

    this.currentPageProjects = INITIAL_PAGE_PAGINATION;
    this.currentPageCompaniesAndProjects = INITIAL_PAGE_PAGINATION;
    this.currentPageCompanies = INITIAL_PAGE_PAGINATION;
  }

  onTabChange(contactTypeSelected: number) {
    if (contactTypeSelected !== 0) {
      // We initialize the variable tabsAlreadyInitialized to avoid updating tabs everytime we change page
      this.tabsAlreadyInitialized = true;

      this.selectedTab = contactTypeSelected;
      switch (this.selectedTab) {
        case ContactTypeEnum.COMPANIES:
          this.configPaginationCompanies.currentPage = this.currentPageCompanies;
          this.configPaginationCompanies.totalItems = this.totalNumberOfCompanies;
          break;
        case ContactTypeEnum.PROJECTS:
          this.configPaginationProjects.currentPage = this.currentPageProjects;
          this.configPaginationProjects.totalItems = this.totalNumberOfProjects;
          break;
        case ContactTypeEnum.ALL_RESULTS:
          this.configPaginationCompaniesAndProjects.currentPage = this.currentPageCompaniesAndProjects;
          this.configPaginationCompaniesAndProjects.totalItems = this.totalNumberOfCompaniesAndProjects;
          break;
      }
    }
  }

  goToPlanDetails(slug) {
    this.router.navigate(['financement', slug]);
  }

  handlePageChange(configPagination: PaginationInstance) {
    this.updateProjectAndCompanies(configPagination.currentPage, this.selectedTab);
  }

  onClickPageButton(entityId) {
    this.router.navigate(['entite', entityId]);
  }

  updateProjectAndCompanies(pageNumber: number, contactType: ContactTypeEnum = ContactTypeEnum.ALL_RESULTS) {
    if (this.searchValues) {
      let type = null;
      switch (contactType) {
        case ContactTypeEnum.COMPANIES:
          this.configPaginationCompanies.currentPage = pageNumber;
          this.currentPageCompanies = this.configPaginationCompanies.currentPage;
          this.isSearchingCompanies = true;
          type = 'company';
          break;
        case ContactTypeEnum.PROJECTS:
          this.configPaginationProjects.currentPage = pageNumber;
          this.currentPageProjects = this.configPaginationProjects.currentPage;
          this.isSearchingProjects = true;
          type = 'project';
          break;
        case ContactTypeEnum.ALL_RESULTS:
          this.configPaginationCompaniesAndProjects.currentPage = pageNumber;
          this.currentPageCompaniesAndProjects = this.configPaginationCompaniesAndProjects.currentPage;
          this.isSearchingCompaniesAndProjects = true;
          break;
      }

      this.companyService
        .searchCompaniesAndProjects(this.searchValues, type, pageNumber, ITEMS_PER_PAGE)
        .then((page: any) => {
          let content = page.content;
          switch (contactType) {
            case ContactTypeEnum.COMPANIES:
              this.companies = content;
              this.totalNumberOfCompanies = page.totalElements;
              this.isSearchingCompanies = false;
              this.hasSearchedOnceCompanies = true;
              this.configPaginationCompanies.totalItems = this.totalNumberOfCompanies;
              break;
            case ContactTypeEnum.PROJECTS:
              this.projects = content;
              this.totalNumberOfProjects = page.totalElements;
              this.isSearchingProjects = false;
              this.hasSearchedOnceProjects = true;

              this.configPaginationProjects.totalItems = this.totalNumberOfProjects;
              break;
            case ContactTypeEnum.ALL_RESULTS:
              this.entities = content;
              this.totalNumberOfCompaniesAndProjects = page.totalElements;
              this.isSearchingCompaniesAndProjects = false;
              this.hasSearchedOnceCompaniesAndProjects = true;
              this.configPaginationCompaniesAndProjects.totalItems = this.totalNumberOfCompaniesAndProjects;
              break;
          }

        })
        .catch(() => {
          switch (contactType) {
            case ContactTypeEnum.COMPANIES:
              this.isSearchingCompanies = false;
              this.hasSearchedOnceCompanies = true;
              break;
            case ContactTypeEnum.PROJECTS:
              this.isSearchingProjects = false;
              this.hasSearchedOnceProjects = true;
              break;
            case ContactTypeEnum.ALL_RESULTS:
              this.isSearchingCompaniesAndProjects = false;
              this.hasSearchedOnceCompaniesAndProjects = true;
              break;
          }
        });
    }
  }

  getLength() {
    switch (this.tab) {
      case nameTabSearchPage.plan:
        return this.plans.length;
      case nameTabSearchPage.contact:
        return this.totalNumberOfCompaniesAndProjects;
      case nameTabSearchPage.interlocutor:
        return this.interlocutors.length;
      default:
        return -1;
    }
  }

  isInterlocutorContact(interlocutor) {
    return this.agentContacts.map(contact => contact.id).includes(interlocutor.id);
  }

  async onStarClick(event: {isToggled: boolean, interlocutor: any}) {
    let message;
    if (!this.isFavoriteRequestRunning) {
      this.isFavoriteRequestRunning = true;
      if (event.isToggled) {
        await this.agentService.addAgentContact(this.agent.id, event.interlocutor.id);
        this.agentContacts = [...this.agentContacts, event.interlocutor];
        message = this.translateService.instant('my-contacts.add-contact-success');
      } else {
        await this.agentService.deleteAgentContact(this.agent.id, event.interlocutor.id);
        this.agentContacts = this.agentContacts.filter(contact => contact.id !== event.interlocutor.id);
        message = this.translateService.instant('my-contacts.delete-contact-success');
      }
      message = message.replace('{firstname}', event.interlocutor.firstname);
      message = message.replace('{lastname}', event.interlocutor.lastname);
      this.toastr.success(message);
      this.isFavoriteRequestRunning = false;
    }
  }
}
