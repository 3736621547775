import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/hub-api/notification.service';
import { HubBreadCrumbsService } from 'src/app/services/hub-breadcrumbs.service';
import { SessionService } from 'src/app/services/session.service';
import { InterlocutorService } from '../../services/hub-api/interlocutor.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: Array<{}> = [];
  sub: Subscription;
  isLoading: boolean;

  constructor(
    private sessionService: SessionService,
    private interlocutorService: InterlocutorService,
    private breadcrumbs: HubBreadCrumbsService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.breadcrumbs.add([
      {
        label: this.translateService.instant('breadcrumbs.my-notifications'),
        url: '/mes-notifications',
        params: []
      }
    ]);

    this.sub = this.sessionService
      .getAgentAsObservable()
      .subscribe((agent: any) => {
        if (agent) {
          this.notificationService
            .getNotificationsByInterlocutor(agent.interlocutorid)
            .then((notifications: Array<{}>) => {
              notifications.sort((a: any, b: any) =>
                a.date > b.date ? -1 : 1
              );
              this.notifications = notifications;
              this.isLoading = false;
            })
            .catch(() => this.isLoading = false);
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
