<div class="hub-data-card shadow">
  <div class="hub-data-card--icon">
    <img
      src="/assets/icons/{{ icon }}.png"
      alt="icon {{ titleAccessibility | ucfirst }}"
    />
  </div>

  <div class="hub-data-card--title">
    <span>{{ title | ucfirst }}</span>
  </div>

  <div class="hub-data-card--list">
    <ul *ngFor="let list of list">
      <li>{{ list }}</li>
    </ul>
  </div>
</div>
