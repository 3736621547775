import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';

@Injectable()
export class GDIService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.gdi;
  }

  /**
   * Returns a list of mandators users retrieved from the group "/{siret}/mandataire"
   * @param siret Siret de l'entreprise
   */
  getMandatairesBySiret(siret: string) {
    return this.api.get(`${this.endpoint}/getMandatairesBySiret/${siret}`);
  }

  /**
   * Returns a list of authorized users retrieved from the group "/{siret}/ayant-droit"
   * @param siret Siret de l'entreprise
   */
  getAyantsDroitBySiret(siret: string) {
    return this.api.get(`${this.endpoint}/getAyantsDroitBySiret/${siret}`);
  }

  getUserById(userId: string) {
    return this.api.get(`${this.endpoint}/user/${userId}`);
  }
}
