<div class="hub-contact-details">
  <div class="hub-contact-details-container col-md-12">
    <div class="info-text">
      <span>{{ 'contact-details.info-text' | translate | ucfirst }}</span>
    </div>
    <div class="accordion">
      <ngb-accordion class="contact-details-accordion" #contactDataAccordion="ngbAccordion">
        <ngb-panel id="mandataires">
          <ng-template ngbPanelTitle><span>{{ 'contact-details.mandataires' | translate | ucfirst }} <i
            [ngClass]=" getClass(contactDataAccordion, 'mandataires') "></i></span>
          </ng-template>
          <ng-template ngbPanelContent>
            <span *ngIf="!mandataires.length">
              {{ 'contact-details.empty-mandataires' | translate | ucfirst }}
            </span>
            <div *ngIf="mandataires.length">
              <ng-container
                [ngTemplateOutlet]="tableContact"
                [ngTemplateOutletContext]="{datas:mandataires}">
              </ng-container>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="collaborateurs">
          <ng-template ngbPanelTitle><span>{{ 'contact-details.collaborateurs' | translate | ucfirst }} <i
            [ngClass]=" getClass(contactDataAccordion, 'collaborateurs') "></i></span>
          </ng-template>
          <ng-template ngbPanelContent>
            <span
              *ngIf="!collaborateurs.length">
              {{ 'contact-details.empty-collaborateurs' | translate | ucfirst }}
            </span>
            <div *ngIf="collaborateurs.length">
              <ng-container
                [ngTemplateOutlet]="tableContact"
                [ngTemplateOutletContext]="{datas:collaborateurs}">
              </ng-container>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="prestataires-externes">
          <ng-template ngbPanelTitle><span>{{ 'contact-details.prestataires-externes' | translate | ucfirst }} <i
            [ngClass]=" getClass(contactDataAccordion, 'prestataires-externes') "></i></span>
          </ng-template>
          <ng-template ngbPanelContent>
            <span
              *ngIf="!prestataires.length">
              {{ 'contact-details.empty-prestataires-externes' | translate | ucfirst }}
            </span>
            <div *ngIf="prestataires.length">
              <ng-container
                [ngTemplateOutlet]="tableContact"
                [ngTemplateOutletContext]="{datas:prestataires}">
              </ng-container>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>

<ng-template #tableContact let-datas="datas">
  <div class="table-responsive">
    <table class="table">
      <tbody>
      <tr *ngFor="let data of datas">
        <td>
          <i [ngClass]="[isPrivilegedContactByEmail(data.email) ? 'fas' + classPrivilegedContact : 'far' + classPrivilegedContact]"
             (click)="changePrivilegedContact(data)"></i>
        </td>
        <td>{{ data.firstName }}</td>
        <td>{{ data.lastName }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>
