<div class="hub-search-card">
  <app-hub-tabs theme="red" (idSelected)="changeSelectedTab($event)">
    <hub-tab [id]="0" titre="{{ 'search.contact' | translate | ucfirst }}" orientation="end"
             background="background" [selected]="selectedTab === nameTabSearchPage.contact" class="hub-search-tab">
      <div class="search--content">
        <form (ngSubmit)="emitSearch()" [formGroup]="SimpleFormCompany" class="form-group">
          <div class="search-inputs row justify-content-between mb-2">
            <div class="group col-md-6">
              <label for="identity">{{ 'search.identity' | translate | ucfirst }}</label>
              <input type="text" id="identity" placeholder="{{ 'search.identity-hint' | translate | ucfirst }}"
                     formControlName="identity" name="identity"
                     title="{{ 'search.identity-hint' | translate | ucfirst }}"/>
            </div>
            <div class="group col-md-2">
              <label for="siret">{{ 'search.siret' | translate | ucfirst }}</label>
              <input type="text" id="siret" placeholder="{{ 'search.siret-hint' | translate | ucfirst }}"
                     formControlName="siret"
                     name="siret"/>
            </div>
            <div class="group col-md-4">
              <label for="interlocutor">{{ 'search.interlocutor' | translate | ucfirst }}</label>
              <input type="text" id="interlocutor" placeholder="{{ 'search.interlocutor-hint' | translate | ucfirst }}"
                     formControlName="interlocutor" name="interlocutor"/>
            </div>
          </div>
          <div class="search-inputs row justify-content-between mb-2">
            <div class="group col-md-6">
              <label for="structure">{{ 'search.structure' | translate | ucfirst }}</label>
              <ng-select [items]=structures [multiple]="false" [closeOnSelect]="true" [hideSelected]="true"
                         bindLabel="name"
                         placeholder="{{ 'search.structure-hint' | translate | ucfirst }}" formControlName="structure"
                         name="structure">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.name }}">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{ item.name }}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="group col-md-3">
              <label type="text" for="territory">{{ 'search.territory' | translate | ucfirst }}</label>
              <ng-select [items]="territories" [multiple]="false" [closeOnSelect]="true" [hideSelected]="true"
                         bindLabel="label"
                         formControlName="territory" name="territory"
                         placeholder="{{ 'search.territory-hint' | translate | ucfirst }}">
                <ng-template ng-option-tmp let-item="item">
                  <div title="({{ item.label }}) {{ item.departments }}">
                    ({{ item.label }}) {{ item.departments }}
                  </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">({{ item.label }}) {{ item.departments }}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="group col-md-3">
              <label type="text" for="department">{{ 'search.department' | translate | ucfirst }}</label>
              <ng-select [items]="departments" [multiple]="false" [closeOnSelect]="true" [hideSelected]="true"
                         bindLabel="number"
                         formControlName="department"
                         name="department"
                         placeholder="{{ 'search.department-hint' | translate | ucfirst }}">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.number }} - {{item.name}}">{{ item.number }} - {{item.name}}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{ item.number }} - {{item.name}}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row justify-content-end" style="padding-right: 5px">
            <p (click)="clearCompany()" class="clear-search pointer">{{ 'search.clear' | translate | ucfirst }}</p>
            <button type="submit" class="btn-warning" [ngClass]="isValidFormCompany() ? 'pointer' : ''"
                    [disabled]="!isValidFormCompany()">{{ 'search.search' | translate | ucfirst }}</button>
          </div>
        </form>
      </div>
    </hub-tab>


    <hub-tab [id]="1" titre="{{ 'search.plan' | translate | ucfirst }}" orientation="start"
             background="background" [selected]="selectedTab === nameTabSearchPage.plan" class="hub-search-tab">
      <div class="search--content">
        <form (ngSubmit)="searchPlan()" [formGroup]="SimpleFormPlan" class="form-group">
          <div class="search-inputs row justify-content-between">
            <div class="group col-md-6">
              <label for="libelle">{{ 'search.libelle' | translate | ucfirst }}</label>
              <input type="text" id="libelle" placeholder="{{ 'search.libelle-hint' | translate | ucfirst }}"
                     formControlName="libelle" name="libelle"
                     title="{{ 'search.libelle-hint' | translate | ucfirst }}"/>
            </div>

            <div class="group col-md-3">
              <label for="financers">{{ 'search.financer' | translate | ucfirst }}</label>
              <ng-select [items]="financers" [multiple]="true" [closeOnSelect]="true" [hideSelected]="true"
                         bindLabel="name"
                         formControlName="financers" id="financers"
                         placeholder="{{ 'search.financer' | translate | ucfirst }}">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.name }}">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="display-structures">{{ item.name }}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="group col-md-3">
              <label for="activityAreas">{{ 'search.activityArea' | translate | ucfirst }}</label>
              <ng-select [items]=activityAreas [multiple]="true" [closeOnSelect]="true" [hideSelected]="true"
                         bindLabel="label"
                         formControlName="activityAreas" id="activityAreas"
                         placeholder="{{ 'search.activityArea' | translate | ucfirst }}">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="display-activityAreas">{{ item.label }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row justify-content-end" style="padding-right: 5px">
            <p (click)="clearPlan()" class="clear-search pointer">{{ 'search.clear' | translate | ucfirst }}</p>
            <button type="submit" class="btn-warning" [ngClass]="isValidFormPlan() ? 'pointer' : ''"
                    [disabled]="!isValidFormPlan()">{{ 'search.search' | translate | ucfirst }}</button>
          </div>
        </form>
      </div>
    </hub-tab>
    <hub-tab [id]="2" class="hub-search-tab" titre="{{ 'search.interlocutor-tab' | translate | ucfirst }}"
             [selected]="selectedTab === nameTabSearchPage.interlocutor">
      <div class="search--content">
        <form [formGroup]="SimpleFormInterlocutor" (ngSubmit)="searchInterlocutor()"
              class="form-group justify-content-end">
          <div class="search-inputs row justify-content-between mb-2">
            <div class="lastname-group group col-md-6">
              <label>Nom</label>
              <input type="text" id="lastname"
                     placeholder="{{ 'my-directory.form.lastname' | translate | ucfirst }}" formControlName="lastname"
                     name="lastname"
                     title="{{ 'my-directory.form.lastname' | translate | ucfirst }}"/>
            </div>

            <div class="structure group col-md-3">
              <label class="label-input">{{ 'my-directory.form.structure' | translate | ucfirst }}</label>
              <ng-select [items]="structures" [closeOnSelect]="true" [hideSelected]="true" bindLabel="name"
                         placeholder="{{ 'my-directory.form.structure' | translate | ucfirst }}"
                         clearAllText="
              {{ 'form.none' | translate | ucfirst }}" formControlName="structures" [multiple]="true">
                <ng-template ng-header-tmp>
                  <a (click)="selectAll('structures')" class="select-all">{{ 'form.all' | translate | ucfirst }}</a>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.name }}">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="display-structures">{{ item.name }}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="activity-area group col-md-3">
              <label class="label-input">{{ 'my-directory.form.activityArea' | translate | ucfirst }}</label>
              <ng-select [items]="activityAreas" [closeOnSelect]="true" [hideSelected]="true" bindLabel="label"
                         placeholder="{{ 'my-directory.form.activityArea' | translate | ucfirst }}"
                         clearAllText="
              {{ 'form.none' | translate | ucfirst }}" formControlName="activityAreas" [multiple]="true">
                <ng-template ng-header-tmp>
                  <a (click)="selectAll('activityAreas')" class="select-all">{{ 'form.all' | translate | ucfirst }}</a>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="display-activityAreas">{{ item.label }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="search-inputs row">
            <div class="territory group col-md-2">
              <label class="label-input">{{ 'my-directory.form.territory' | translate | ucfirst }}</label>
              <ng-select [items]="territories" [searchFn]="filterTerritories" [closeOnSelect]="true"
                         [hideSelected]="true" bindLabel="label"
                         placeholder="{{ 'my-directory.form.territory' | translate | ucfirst }}"
                         clearAllText="
            {{ 'form.none' | translate | ucfirst }}" formControlName="territories" [multiple]="true">
                <ng-template ng-header-tmp>
                  <a (click)="selectAll('territories')" class="select-all">{{ 'form.all' | translate | ucfirst }}</a>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div title="({{ item.label }}) {{ item.departments }}">({{ item.label }}) {{ item.departments }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="display-territories">({{ item.label }}) {{ item.departments }}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="speciality group col-md-4">
              <label class="label-input">{{ 'my-directory.form.speciality' | translate | ucfirst }}</label>
              <ng-select [items]="specialities" [closeOnSelect]="true" [hideSelected]="true" bindLabel="label"
                         placeholder="{{ 'my-directory.form.speciality-hint' | translate | ucfirst }}"
                         clearAllText="
              {{ 'form.none' | translate | ucfirst }}" formControlName="specialities" [multiple]="true">
                <ng-template ng-header-tmp>
                  <a (click)="selectAll('specialities')" class="select-all">{{ 'form.all' | translate | ucfirst }}</a>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="display-specialities">{{ item.label }}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="plan group col-md-6">
              <label class="label-input">{{ 'my-directory.form.plan' | translate | ucfirst }}</label>
              <ng-select [items]="plans" [closeOnSelect]="true" [hideSelected]="true" bindLabel="name"
                         placeholder="{{ 'my-directory.form.plan' | translate | ucfirst }}"
                         clearAllText="
              {{ 'form.none' | translate | ucfirst }}" formControlName="plans" [multiple]="true">
                <ng-template ng-header-tmp>
                  <a (click)="selectAll('plans')" class="select-all">{{ 'form.all' | translate | ucfirst }}</a>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.name }}">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="display-plans">{{ item.name }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row justify-content-end" style="padding-right: 5px">
            <p (click)="clearInterlocutor()" class="clear-search pointer">{{ 'search.clear' | translate | ucfirst }}</p>
            <button type="submit" class="btn-warning" [ngClass]="isValidFormInterlocutor() ? 'pointer' : ''"
                    [disabled]="!isValidFormInterlocutor()">{{ 'search.search' | translate | ucfirst }}</button>
          </div>
        </form>
      </div>
    </hub-tab>
  </app-hub-tabs>
  <div class="search--edito" *ngIf="selectedTab === 'contact'">
    <i class="fa fa-comments"></i>
    <div class="text">
      <span>{{ 'search.edito' | translate | ucfirst }}</span>
    </div>
  </div>
</div>
