import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';
import { endpoints } from '../../constants';

@Injectable()
export class SpecialityService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.speciality;
  }
}
