import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  isLoading = true;

  constructor(private authService: AuthService) {}

  async ngOnInit() {
    this.authService.isLoggedIn$.subscribe(async isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      this.isLoading = false;
    });
  }
}
