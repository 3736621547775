import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-plan-activity-areas-modal',
  templateUrl: './plan-activity-areas-modal.component.html',
  styleUrls: ['./plan-activity-areas-modal.component.scss']
})
export class PlanActivityAreasModalComponent implements OnInit {
  activityAreas: Array<any>;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}
}
