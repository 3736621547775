import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { simpleDateFormat } from '../constants';

@Pipe({ name: 'dateFormatDDMMYYYY' })
export class DatePipe implements PipeTransform {
  transform(content, format = 'YYYY-MM-DD') {
    const date = moment(content, format);
    return date.isValid() ? date.format(simpleDateFormat) : '';
  }
}
