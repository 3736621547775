import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {eventStatut} from '../constants';
import {InterlocutorService} from '../services/hub-api/interlocutor.service';
import {SessionService} from '../services/session.service';
import {EventService} from "../services/hub-api/eventService";

@Injectable()
export class EventEditionGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private sessionService: SessionService,
    private eventService: EventService,
    private interlocutorService: InterlocutorService
  ) {
    super(router, keycloakService);
  }

  /**
   * If the user connected is not from the same structure than the creator of the event
   * we force the redirection to the evenements-list page '/evenements-list'
   * Else all is ok :)
   */
  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async resolve => {
      if (!this.authenticated) {
        return resolve(true);
      }

      try {
        const agent = await this.sessionService.getAgent();
        const interlocutor = (await this.interlocutorService.getById(agent.interlocutorid)) as any;
        const agent_structure = interlocutor.structure.id;
        const id = route.params.id;
        const event = (await this.eventService.getOne(id)) as any;
        const event_structure = event.createur.structure.id;
        if (agent_structure == event_structure) {
          return resolve(true);
        } else {
          this.router.navigate(['/evenements-list']);
          return resolve(false);
        }
      }
      catch (e) {
        return resolve(false);
      }
    });
  }
}
