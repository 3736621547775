<ul class="{{ 'nav nav-tabs ' + theme }}">
  <li class="nav-item" *ngFor="let tab of tabs">
    <span
      class="nav-link {{ tab.orientation }} {{ tab.background }}"
      (click)="selectTab(tab)"
      [class.active]="tab.selected"
      >{{ tab.titre | translate | ucfirst }}</span
    >
  </li>
</ul>
<ng-content></ng-content>
