import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: ['./link-card.component.scss']
})
export class LinkCardComponent implements OnInit {
  @Input()
  name: string;
  @Input()
  title: string;
  @Input()
  caption: string;
  @Input()
  padding = '15px';
  @Input()
  themes = '';

  constructor() {}

  ngOnInit() {}
}
