import {Deserializable} from "./deserializable.model";


export class EmailParams implements Deserializable<EmailParams>{
    emailTo : string;

    userFirstname : string;
    userLastname : string;

    // Mandataire
    civilite : string;
    mandataireFirstname : string;
    mandataireLastname : string;


    structureName : string;
    emailInterlocutor : string;

    identifier : string; // if it's not set it's a project
    motif : string;
    precision : string;
    commentaire : string;
    refererFirstname : string;
    refererLastname : string;
    refererStructureName : string;

    // Company
    companyName : string;
    companyDepartment : string;
    companyNAF : string;
    companyLibelleNAF : string;
    contactName : string;
    privilegedContactEmail : string;
    privilegedContactPhone : string;
    codeDepartment : string;

    // Refus referent
    objectRefus : string;
    otherObjectRefus : string;
    structuresList : string;

    interlocutorId : string;

    deserialize(input: any): EmailParams {
        Object.assign(this, input);
        return this;
    }
}
