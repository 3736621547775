import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isLoggedIn = false;
  isAgentLoaded = false;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private spinner: NgxSpinnerService
  ) {}

  async ngOnInit() {
    this.spinner.show();
    this.authService
      .isLoggedIn()
      .then(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        this.sessionService
          .getAgent()
          .then(() => (this.isAgentLoaded = true))
          .catch(() => {});
        this.spinner.hide();
      })
      .catch(() => this.spinner.hide());
  }
}
