import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

const i18nValues = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Juin',
      'Juil',
      'Aou',
      'Sep',
      'Oct',
      'Nov',
      'Déc'
    ]
  }
};

@Injectable()
export class DatePickerService extends NgbDatepickerI18n {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return i18nValues[this.locale].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return i18nValues[this.locale].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }
}
