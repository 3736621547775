import { environment as defaultEnvironment } from './environment';

export const environmentLoader = new Promise<any>(resolve => {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', './assets/environments/environment.json', true);
  xmlhttp.onload = () =>
    resolve(
      xmlhttp.status === 200
        ? JSON.parse(xmlhttp.responseText)
        : defaultEnvironment
    );
  xmlhttp.send();
});
