import {Component, OnInit} from '@angular/core';
import {EventService} from "../../../services/hub-api/eventService";
import {SessionService} from "../../../services/session.service";
import {InterlocutorService} from "../../../services/hub-api/interlocutor.service";
import {NgxSpinnerService} from "ngx-spinner";
import {eventStatut} from "../../../constants";
import {Router} from "@angular/router";
import {HubBreadCrumbsService} from "../../../services/hub-breadcrumbs.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-evenements-list',
  templateUrl: './evenements-list.component.html',
  styleUrls: ['./evenements-list.component.scss']
})
export class EvenementsListComponent implements OnInit {

  evenementsAccepted: any;
  evenementsSubmitted: any;
  currentAgent: any;
  currentInterlocutor: any;
  isLoading: boolean;
  showEvenementsAccepted = false;
  showEvenementsSubmitted = true;

  constructor(private eventService: EventService,
              private session: SessionService,
              private interlocutorService: InterlocutorService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private breadcrumbs: HubBreadCrumbsService,
              private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.breadcrumbs.add([
      {
        label: this.translateService.instant('breadcrumbs.events'),
        url: '/evenements-list',
        params: []
      }
    ]);
    this.session.getAgentAsObservable().subscribe(async agent => {
      if (agent) {
        this.currentAgent = agent;
        this.currentInterlocutor = await this.interlocutorService
          .getById(this.currentAgent.interlocutorid);
          this.eventService.getEventsByStructure(this.currentInterlocutor.structure.id).then((events: any)  => {
          this.evenementsSubmitted = events.filter(event => event.statut.libelle !== eventStatut.valide);
          this.evenementsAccepted = events.filter(event => event.statut.libelle === eventStatut.valide);
          this.isLoading = false;
        });
      }
    });
  }


  goToModifyEvent(eventId: string){
    this.router.navigate(['evenements-edit', eventId])
  }

  goToEventUrl(url : string){
    window.open(url, '_blank');
  }

  isRefused(libelle : string){
    return libelle === eventStatut.refuse
  }
}
