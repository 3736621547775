export class UtilsFunctions {
  static checklink(link: string): string {
    return link.startsWith('https') ? link : 'https://' + link;
  }

  /**
   * Return a string without accents.
   * We use normalize('NFD') to decompose accent (Ex: è = e + `) and replace() with regex to replace with the good
   * character without accent.
   * @See https://stackoverflow.com/a/37511463 for more details
   *
   * @param word With accent
   */
  static replaceAccent(word: string) {
    return word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
