<div class="hub-notification-card row shadow white">

  <!-- Premiere Colonne Notification Card-->
  <div class="col-md-3">
    <div *ngIf="!notification.read" class="datetime">{{ 'notification.received-at' | translate | ucfirst }}
      {{ notification.date | datetime }}</div>
    <div *ngIf="notification.read" class="datetime">{{ 'notification.read-at' | translate | ucfirst }}
      {{ notification.updatedat | datetime }}</div>
    <div>
      <i [ngClass]="notification.read ? 'far fa-envelope-open' : 'fas fa-envelope'"
         class="fa-3x left envelope-icon"></i>
      <span [ngClass]="!notification.read ? 'bold' : ''">{{ titleNotification }}</span>

      <div *ngIf="notification.type === newReferentType" class="new-referent-type">
        <div *ngIf="!notification.read" class="not-accepted d-flex flex-column">
          <button type="button" class="btn-green pointer"
                  (click)="markNotificationAsRead()">{{ 'notification.accept' | translate | ucfirst }}</button>
          <div class="dont-want-to-be-referent">
            <span>{{ 'notification.dont-want-to-be-referent' | translate | ucfirst }}</span><a class="pointer"
                                                                                               (click)="openRefuseReferentModale()">{{ 'notification.click-here' | translate }}</a>
          </div>
        </div>
        <div *ngIf="notification.read" class="accepted d-flex align-items-center">
          <div *ngIf="!notification.reject">
            <i class="fa fa-check primary"></i> <span
            class="font-italic">{{ 'notification.have-accepted-the' | translate | ucfirst }}
            {{ notification.updatedat | datetime }}</span>
          </div>
          <div *ngIf="notification.reject" class="rejected d-flex align-items-center">
            <i class="fa fa-times primary"></i> <span
            class="font-italic">{{ 'notification.have-refused-the' | translate | ucfirst }}
            {{ notification.updatedat | datetime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Deuxieme Colonne Notification Card-->
  <div class="col-md-4">
    <div class="hub-entity-header">
      <span class="entity-title">{{ isProjectHolder ? company.contactname : company.name }}</span>
    </div>

    <div *ngIf="!isProjectHolder"><span class="bold">{{ 'company-card.siret' | translate | ucfirst }}
      </span><span>{{ company.identifier }}</span></div>
    <div *ngIf="notification.type === newReferentType"><span
      class="bold">{{ 'company-card.city' | translate | ucfirst }}
      </span><span>{{companyApiData?.adresse?.acheminement_postal?.l6}}</span></div>
    <div *ngIf="notification.type === newReferentType"><span class="bold">{{ 'company-card.naf' | translate | ucfirst }}
      </span><span>{{companyApiData?.activite_principale?.code}} - {{companyApiData?.activite_principale?.libelle}}</span></div>
    <div *ngIf="notification.type === newReferentType">

      <span class="bold">{{ 'company-card.activity-areas' | translate | ucfirst }}
      </span><span *ngIf="activityAreas">{{activityAreas}}</span>
      <span *ngIf="!activityAreas"
            class="italic">{{ 'company-card.activity-area-not-specified' | translate | ucfirst }}</span>

    </div>
    <div *ngIf="isProjectHolder">
      <span *ngIf="privilegedContact?.phone" class="bold">{{ 'company-card.phone' | translate | ucfirst }}
      </span><span>{{ privilegedContact?.phone }}</span>
      <div *ngIf="!privilegedContact?.phone">
        <span class="bold">{{ 'company-card.phone' | translate | ucfirst }} </span>
        <span class="italic">{{ 'notification.not-specified' | translate | ucfirst }}</span>
      </div>
    </div>
  </div>


  <!-- Troisieme Colonne Notification Card-->
  <div *ngIf="!isProjectHolder" class="col-md-3">
    <span class="bold">Contact : </span><span class="contact-name">{{ company.contactname }}</span>
    <div *ngIf="notification.type === newReferentType"><span
      class="bold">{{ 'company-card.mail' | translate | ucfirst }}
      </span><span>{{ privilegedContact?.email }}</span></div>
    <div *ngIf="privilegedContact?.phone"><span class="bold">{{ 'company-card.phone' | translate | ucfirst }}
      </span><span>{{ privilegedContact?.phone }}</span></div>
    <div *ngIf="!privilegedContact?.phone">
      <span class="bold">{{ 'company-card.phone' | translate | ucfirst }} </span>
      <span class="italic">{{ 'notification.not-specified' | translate | ucfirst }}</span>
    </div>
  </div>
  <div *ngIf="isProjectHolder" class="col-md-3"></div>

  <!-- Quatrieme Colonne Notification Card-->
  <div class="col-md-2">
      <button (click)="goToCompanyDetails()" class="btn btn-hub btn-primary btn-infos">
        {{ getRightButtonText() }}
      </button>
  </div>
</div>
