import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(content, format: string) {
    const date = moment(content);
    return date.isValid() ? date.format(format) : '';
  }
}
