import {Component, OnInit} from '@angular/core';
import {AgentService} from "../../services/hub-api/agent.service";
import {SessionService} from "../../services/session.service";
import {PaginationInstance} from "ngx-pagination";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {HubBreadCrumbsService} from "../../services/hub-breadcrumbs.service";

@Component({
  selector: 'app-my-contacts',
  templateUrl: './my-contacts.component.html',
  styleUrls: ['./my-contacts.component.scss']
})
export class MyContactsComponent implements OnInit {

  contacts: Array<any>;
  agentId: string;
  isLoading: boolean;
  itemsPerPage = 10;
  currentPage = 1;
  configPagination: Array<PaginationInstance> = [
    {
      id: 'contacts',
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage
    }
  ];

  constructor(private agentService: AgentService,
              private sessionService: SessionService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private breadcrumbs: HubBreadCrumbsService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.breadcrumbs.add([
      {
        label: this.translate.instant('breadcrumbs.my-contacts'),
        url: '/mes-contacts',
        params: []
      }
    ]);
    this.agentId = await this.sessionService.getAgentId();
    this.contacts = (await this.agentService.getContacts(this.agentId)) as Array<any>;
    this.isLoading = false;
  }

  async deleteContact(contact: any) {
    await this.agentService.deleteAgentContact(this.agentId, contact.id);
    this.contacts = this.contacts.filter(contactItem => contactItem.id !== contact.id);
    let message = this.translate.instant('my-contacts.delete-contact-success');
    message = message.replace('{firstname}', contact.firstname);
    message = message.replace('{lastname}', contact.lastname);
    this.toastr.success(message);
  }

}
