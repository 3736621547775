import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';
import { endpoints } from '../../constants/index';

@Injectable()
export class AgentService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.agent;
  }

  loadOrCreateAgent(keycloakid, payload) {
    const createAgent = resolve => {
      this.post({ keycloakid, ...payload }).then((response2: any) => {
        resolve(response2);
      });
    };

    return new Promise(resolve => {
      this.api
        .get(`${this.endpoint}/keycloakid/${keycloakid}`)
        .then((response: any) => {
          const agent = response;
          if (agent.id) {
            resolve(agent);
          } else {
            createAgent(resolve);
          }
        })
        .catch(error => {
          if (error.status === 404) {
            createAgent(resolve);
          }
        });
    });
  }

  getAgent(keycloakid) {
    return new Promise(resolve => {
      this.api
        .get(`${this.endpoint}/keycloakid/${keycloakid}`)
        .then((response: any) => {
          return resolve(response);
        })
        .catch(error => {
          if (error.status === 404) {
            return resolve(undefined);
          }
        });
    });
  }

  getContacts(agentId: string): Promise<any> {
    return this.api.get(`${this.endpoint}/${agentId}/contacts`);
  }

  addAgentContact(agentId: string, interlocutorId: string): Promise<any> {
    return this.api.post(`${this.endpoint}/${agentId}/contacts/${interlocutorId}`);
  }

  validateCharte(agentId: string): Promise<any> {
    return this.api.put(`${this.endpoint}/${agentId}/validate_charte`);
  }

  deleteAgentContact(agentId: string, interlocutorId: string): Promise<any> {
    return this.api.delete(`${this.endpoint}/${agentId}/contacts/${interlocutorId}`);
  }
}
