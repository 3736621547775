import { Injectable, Injector } from '@angular/core';

import { endpoints, interlocutorRoles } from '../../constants';
import { AbstractService } from './abstract.service';
import {SearchArray} from "../../components/search-card/dependencies/search-array";

@Injectable()
export class InterlocutorService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.interlocutor;
  }

  /**
   * Retourne un array avec Referent et ou Expert en fonction de l'interlocuteur
   *
   * @param id l'id de l'interlocuteur
   */
  getinterlocutorRolesById(id: string) {
    return this.api.get(`${endpoints.interlocutorinterlocutorRoles}/${id}`);
  }

  /**
   * Retourne la list des interlocutors qui sont experts, c'est-à-dire qui ont un lien
   * dans la table plan ('interlocutorid' set dans la table 'plan')
   */
  getAllExpertsOnly() {
    return this.api.get(`${endpoints.interlocutorAllExpertsOnly}`);
  }

  getAllReferents() {
    return this.api.get(`${endpoints.interlocutor}/referents/informations`);
  }

  /**
   * Retourne un array avec Referent et ou Expert en fonction de l'interlocuteur
   *
   * @paramid l'id de l'interlocuteur
   */
  getinterlocutorRolesByEmail(email: string) {
    return this.api.get(
      `${endpoints.interlocutorinterlocutorRolesByEmail}/${email}`
    );
  }

  getInterlocutorByEmail(email: string) {
    return this.api.get(`${endpoints.interlocutorByEmail}/${email}`);
  }

  /**
   * Return an array with the companies where the interlocutor is referent
   *
   * @param id l'id de l'interlocuteur
   */
  getReferentCompanies(id: string) {
    return this.api.get(
      `${endpoints.interlocutor}/${id}/${endpoints.companies}`
    );
  }

  /**
   * Return an array with the projects where the interlocutor takes part
   *
   * @param interlocutorId l'id de l'interlocuteur
   */
  getInterlocutorProjects(interlocutorId: string) {
    return this.api.get(`${endpoints.interlocutor}/${interlocutorId}/projects`)
  };

  /**
   * Search in ES
   *
   * @param query Request for ES
   */
  search(queryParams: any) {
    return this.api.get(endpoints.interlocutorSearch, queryParams);
  }

  /**
   * Return an array with 'Référent' if plans is not null and/or 'Expert' if territoriesreferent is not null
   *
   * @param interlocutor Interlocutor from ES
   */
  getInterlocutorRolesFromES(interlocutor: any): Array<string> {
    const rolesReturn: Array<string> = [];

    if (interlocutor.plans.length) {
      rolesReturn.push(interlocutorRoles.expert);
    }
    if (interlocutor.territoriesreferent.length) {
      rolesReturn.push(interlocutorRoles.referent);
    }

    if (interlocutor.territoriesspecialist.length) {
      rolesReturn.push(interlocutorRoles.specialist);
    }

    return rolesReturn;
  }

  setInterlocutorAbsencePeriode(interlocutorId, params: any) {
    return this.api.put(`${this.endpoint}/${interlocutorId}/absence`, params);
  }

  removeInterlocutorAbsencePeriode(interlocutorId) {
    return this.api.delete(`${this.endpoint}/${interlocutorId}/absence`);
  }

  getAllInterlocutorsSimple() {
    return this.api.get(`${this.endpoint}/allSimple`);
  }

  buildQueryParams(searchArray: SearchArray) {
    let query = {};

    const territoriesSelected = searchArray.territories;
    const activityAreasSelected = searchArray.activityAreas;
    const plansSelected = searchArray.plans;
    const specialitiesSelected = searchArray.specialities;
    const structuresSelected = searchArray.structures;
    const lastname = searchArray.lastname;

    if (lastname) {
      query["lastname"] = lastname;
    }

    if (territoriesSelected && territoriesSelected.length > 0) {
      query["territories"] = territoriesSelected;
    }
    if (activityAreasSelected && activityAreasSelected.length > 0) {
      query["activityareas"]= activityAreasSelected;
    }
    if (plansSelected && plansSelected.length > 0) {
      query["plans"] = plansSelected;
    }
    if (specialitiesSelected && specialitiesSelected.length > 0) {
      query["specialities"] = specialitiesSelected;
    }
    if (structuresSelected && structuresSelected.length > 0) {
      query["structures"] = structuresSelected;
    }

    return query;
  }


  /**
   * Return from an object list, a string representing a list of formatted arguments
   * ready to be added to an elastic search query
   *
   * @param item Array of ids and label
   */
  joinIds(items: Array<any>): string {
    return items.join(' OR ');
  }
}
