import { Injectable, Injector } from '@angular/core';
import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';

@Injectable()
export class ProjectService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.project;
  }
  
  /**
  * Ajoute à un projet les interlocuteurs selectionnés
  *
  * @param projectId l'id du projet
  * @param actors un Array d'interlocuteurs
  */
  setActors(projectId: string, actors: Array<any>) {
    return this.api.put(`${this.endpoint}/${projectId}/actors`, actors);
  }

  /**
  * Retourne la liste des interlocuteurs d'un projet
  *
  * @param projectId l'id du projet
  */
  getInterlocutorsByProjectId(projectId: string){
    return this.api.get(`${this.endpoint}/${projectId}/actors`);
  }

  /**
  * Retire un interlocuteur d'un projet
  *
  * @param projectId l'id du projet
  * @param actorId l'id de l'interlocuteur à retirer du projet
  */
  removeActorFromProject(projectId: string, actorId: string) {
    return this.api.delete(`${this.endpoint}/${projectId}/actor/${actorId}`);
  }


}
