import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventService} from "../../services/hub-api/eventService";

@Component({
  selector: 'hub-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Input() evenement: any = {};

  @Input() isRefused = false;

  @Output()
  onClickModify = new EventEmitter<string>();

  @Output()
  onClickEventUrl = new EventEmitter<string>();

  constructor(private eventService: EventService) {
  }

  ngOnInit() {
  }

  getLogoUrl(event) {
    return this.eventService.getLogoUrl(event);
  }
}
