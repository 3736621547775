import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-project-summary',
  templateUrl: './project-summary.html',
  styleUrls: ['./project-summary.scss']
})
export class ProjectSummaryComponent implements OnInit {
  @Input()
  steps: Array<any>;
  constructor() {}

  ngOnInit() {}
}
