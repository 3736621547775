import { Injectable, Injector } from '@angular/core';
import { endpoints } from 'src/app/constants';

import { ApiService } from './api.service';
import { FileService } from './file.service';

@Injectable()
export class EventLogoService extends FileService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.eventLogo;
    this.authorizedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  }
}
