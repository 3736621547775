<div class="hub-dispositif-page container-fluid">
  <div *ngIf="!notFound">
    <div class="row">
      <div class="col-md-12">
        <!-- /.before-title -->
        <h3 class="line">
          <span>{{ plan?.name }}</span>
        </h3>
        <h4 class="subtitle">{{ plan?.aidcategory | ucfirst }}</h4>
        <div *ngIf="plan.startdate || plan.enddate" class="validity-period">
          <i class="fa fa-calendar-check-o" aria-hidden="true"
             title="{{ 'dispositif-page.validity-period.title-icon' | translate | ucfirst }}"></i>
          <span
            *ngIf="plan.startdate && !plan.enddate">
            {{ 'dispositif-page.validity-period.valid-from' | translate | ucfirst }}{{ plan.startdate | dateFormat: 'DD/MM/YYYY' }}
          </span>
          <span
            *ngIf="!plan.startdate && plan.enddate">
            {{ 'dispositif-page.validity-period.valid-up-to' | translate | ucfirst }}{{ plan.enddate | dateFormat: 'DD/MM/YYYY' }}
          </span>
          <span
            *ngIf="plan.startdate && plan.enddate">
            {{ 'dispositif-page.validity-period.valid-du' | translate | ucfirst }}{{ plan.startdate | dateFormat: 'DD/MM/YYYY' }}
            {{ 'dispositif-page.validity-period.to-the' | translate | ucfirst }}{{ plan.enddate | dateFormat: 'DD/MM/YYYY' }}</span>
        </div>

        <div class="caption" [innerHTML]="plan?.caption | safeHtml" *ngIf="plan?.caption">
        </div>

        <div *ngIf="!isLoading" class="hub-dispositif-page--dataCards row">
          <div class="col-sm-6">
            <app-hub-data-card title="{{ 'dispositif-page.goals' | translate }}" titleAccessibility="{{
                'dispositif-page.goals-accessibility' | translate
              }}"
                               icon="dispositif-goals" [list]="getValuesForCard(plan.goals)"></app-hub-data-card>
          </div>
          <div class="col-sm-6">
            <app-hub-data-card title="{{ 'dispositif-page.recipients' | translate }}" titleAccessibility="{{
                'dispositif-page.recipients-accessibility' | translate
              }}"
                               icon="dispositif-recipients"
                               [list]="getValuesForCard(plan.recipients)"></app-hub-data-card>
          </div>
          <div class="col-sm-6">
            <app-hub-data-card title="{{ 'dispositif-page.eligible-expenses' | translate }}" titleAccessibility="{{
                'dispositif-page.eligible-expenses-accessibility' | translate
              }}"
                               icon="dispositif-eligible-expenses"
                               [list]="getValuesForCard(plan.eligibleexpenses)"
                               *ngIf="plan.plantype.label !== planTypes.exceptionnel; else elseBlock">
            </app-hub-data-card>
            <ng-template #elseBlock>
              <app-hub-data-card title="{{ 'dispositif-page.criterion' | translate }}" titleAccessibility="{{
                'dispositif-page.criterion-accessibility' | translate
              }}"
                                 icon="dispositif-eligible-expenses"
                                 [list]="getValuesForCard(plan.eligibleexpenses)">

              </app-hub-data-card>
            </ng-template>

          </div>
          <div class="col-sm-6">
            <app-hub-data-card title="{{ 'dispositif-page.aid-types' | translate }}" titleAccessibility="{{
                'dispositif-page.aid-types-accessibility' | translate
              }}"
                               icon="dispositif-aid-types" [list]="getValuesForCard(plan.aidtypes)"></app-hub-data-card>
          </div>
          <app-hub-tabs class="col-sm-12">
            <hub-tab titre="{{ 'dispositif-page.tabs.documents' | translate | ucfirst }}">
              <hub-download-button
                *ngFor="let fileLinkdescription of plan.fileLinkdescriptions"
                 title="{{ fileLinkdescription.title ? fileLinkdescription.title : 'dispositif-page.description-file' | translate | ucfirst }}"
                 titleAccessibility="{{ 'dispositif-page.detailed-card-accessibility' | translate }}"
                 fileMimeType="{{ fileLinkdescription.file ? fileLinkdescription.file.mimeType : 'dispositif-page.application-file-url' | translate }}"
                 [link]="fileLinkdescription.file ? fileLinkdescription.file : fileLinkdescription.url">
              </hub-download-button>
              <hub-download-button
                *ngFor="let document of plan.documents"
                title="{{ document.title ? document.title : document.file.originalname }}"
                titleAccessibility="{{ document.file.originalname }}"
                fileMimeType="{{ document.file.mimeType }}"
                updatedAt="{{ document.file.updatedat }}"
                [link]="document.file">
              </hub-download-button>
              <hub-download-button
                *ngFor="let link of plan.links"
                title="{{ link.title ? link.title : 'dispositif-page.application-assistance-file' | translate | ucfirst }}"
                titleAccessibility="{{ 'dispositif-page.application-file-accessibility' | translate }}"
                fileMimeType="{{ link.file ? mimeTypes.pdf : 'dispositif-page.application-file-url' | translate }}"
                [link]="link.file ? link.file : link.url">
              </hub-download-button>
            </hub-tab>
            <hub-tab titre="{{ 'dispositif-page.tabs.faq' | translate | ucfirst }}">
              <hub-download-button
                *ngFor="let faq of plan.faqs"
                title="{{ faq.originalname }}"
                titleAccessibility="{{ faq.originalname }}"
                fileMimeType="{{ faq.mimeType }}"
                updatedAt="{{ faq.updatedat }}"
                [link]="faq">
              </hub-download-button>
            </hub-tab>
            <hub-tab titre="{{ 'dispositif-page.tabs.video' |translate | ucfirst}}">
              <div *ngFor="let video of plan.planVideos">
                <span class="pointer" (click)="openVideoModal(video)">
                  <i class="fas fa-file-video "></i>
                  <span class="ml-2"> {{ video.title ? video.title : 'dispositif-page.video-modal.default-title' | translate | ucfirst }}</span>
                </span>
              </div>
            </hub-tab>
            <hub-tab titre="{{ 'dispositif-page.tabs.expert' | translate | ucfirst }}">
              <div class="d-flex justify-content-between flex-wrap">
                <div *ngFor="let expert of plan.experts" class="referent-card">
                  <hub-referer-card [referer]="expert">
                  </hub-referer-card>
                </div>
              </div>
              <div *ngIf="!plan.experts || !plan.experts.length">
                <span>{{ 'dispositif-page.tabs.not-expert-on' | translate | ucfirst }}</span>
              </div>
            </hub-tab>
          </app-hub-tabs>
        </div>
      </div>
      <!-- /col-md-8 -->
    </div>
    <!-- /row -->
  </div>
  <!-- /*ngIf="!notFound" -->
</div>
<!-- /hub-dispositif-page -->
