import { Injectable } from '@angular/core';
import { environmentLoader } from '../../environments/environmentLoader';

@Injectable()
export class GdiService {
  protected host: string;
  protected keycloakUrl: string;
  protected keycloakRealm: string;
  protected queryParam = { redirect_uri: 'redirect_uri', referrer: 'referrer' };

  constructor() {
    environmentLoader.then(env => {
      this.host = env.settings.auth.gdi.host;
      this.keycloakUrl = env.settings.auth.keycloak.url;
      this.keycloakRealm = env.settings.auth.keycloak.realm;
    });
  }

  getAccountUrl() {
    const encodedReferrer = encodeURIComponent(window.location.href);
    return `${this.keycloakUrl}/realms/${this.keycloakRealm}/account?${this.queryParam.referrer}=${encodedReferrer}`;
  }
}
