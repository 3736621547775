import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HubBreadCrumbsService} from 'src/app/services/hub-breadcrumbs.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private breadcrumbs: HubBreadCrumbsService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.breadcrumbs.add([
      {
        label: this.translate.instant('breadcrumbs.contact-us'),
        url: '/contact-us',
        params: []
      }
    ]);
  }
}
