<div>
  <h1 class="line">
    <span>{{ 'evenements-list.title' | translate | ucfirst }}</span>
  </h1>

  <div *ngIf="isLoading" class="text-center">
    <app-hub-spinner-dots></app-hub-spinner-dots>
  </div>
  <div *ngIf="!isLoading">
    <div class="row create">
      <div class="col">
        <div class="pull-right">
          <i class="fas fa-plus-circle fa-sm"></i>
          <a routerLink="/evenements-add">{{'evenements-list.create-event' | translate | ucfirst }}</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="pointer" (click)="showEvenementsAccepted = !showEvenementsAccepted">
              <span>
                {{ 'evenements-list.published-events' | translate | ucfirst }}
                <i [ngClass]="showEvenementsAccepted ? 'fa-angle-up' : 'fa-angle-down'" class="fa right"></i>
              </span>
        </h2>
      </div>
    </div>
    <div *ngIf="showEvenementsAccepted" class="row">
      <div class="col">
        <div *ngIf="evenementsAccepted && evenementsAccepted.length > 0; else elseBlockAccepted">
          <hub-event-card *ngFor="let event of evenementsAccepted" [evenement]="event"
                          (onClickEventUrl)="goToEventUrl($event)"
                          (onClickModify)="goToModifyEvent($event)"
          ></hub-event-card>
        </div>

        <ng-template #elseBlockAccepted>
          <div class="mb-5 italic">
            {{ 'evenements-list.no-published-events' | translate | ucfirst }}
          </div>
        </ng-template>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <h2 class="pointer" (click)="showEvenementsSubmitted = !showEvenementsSubmitted">
              <span>
                {{ 'evenements-list.submitted-events' | translate | ucfirst }}
                <i [ngClass]="showEvenementsSubmitted ? 'fa-angle-up' : 'fa-angle-down'" class="fa right"></i>
              </span>
        </h2>
      </div>
    </div>
    <div *ngIf="showEvenementsSubmitted" class="row">
      <div class="col">
        <div *ngIf="evenementsSubmitted && evenementsSubmitted.length > 0; else elseBlockSubmitted">
          <hub-event-card *ngFor="let event of evenementsSubmitted" [evenement]="event"
                          (onClickModify)="goToModifyEvent($event)"
                          (onClickEventUrl)="goToEventUrl($event)"
                          [isRefused]="isRefused(event.statut.libelle)"></hub-event-card>
        </div>

        <ng-template #elseBlockSubmitted>
          <div class="mb-5 italic">
            {{ 'evenements-list.no-submitted-events' | translate | ucfirst }}
          </div>
        </ng-template>
      </div>
    </div>

  </div>
</div>
