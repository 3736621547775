import {Component, Input, OnInit} from '@angular/core';
import {PaginationInstance} from 'ngx-pagination';
import {Router} from '@angular/router';

@Component({
  selector: 'hub-company-card-list',
  templateUrl: './company-card-list.component.html',
  styleUrls: ['./company-card-list.component.scss']
})
export class CompanyCardListComponent implements OnInit {

  @Input()
  companyList: any = [];

  @Input()
  isLoadingAgentStructure;

  @Input()
  configPagination: PaginationInstance;

  @Input()
  agent_structure: any = {};

  @Input()
  agent_interlocutor: any = {};

  @Input()
  hasSearchedOnce;


  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  onClickPageButton(entityId) {
    this.router.navigate(['entite', entityId]);
  }

}
