import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchArray } from 'src/app/components/search-card/dependencies/search-array';
import { simpleDateComparisonFormat } from 'src/app/constants';
import { ConsultationCompanyType } from 'src/app/constants/type-object-database';
import { AgentConsultationCompanyService } from 'src/app/services/hub-api/consultation-company.service';
import { SessionService } from 'src/app/services/session.service';
import { InterlocutorService } from 'src/app/services/hub-api/interlocutor.service';
import { SearchService } from 'src/app/services/search.service';
import { EncartService } from '../../../services/hub-api/encart.service';
import * as moment from 'moment';

@Component({
  selector: 'app-home-connected',
  templateUrl: './connected.component.html',
  styleUrls: ['./connected.component.scss']
})
export class HomeConnectedComponent implements OnInit {
  consultations: Array<any> = [];
  dates = [];
  agent: any = {};
  agent_structure: any = {};
  agent_interlocutor: any = {};
  areConsultationsLoading: boolean;
  tab = 'contact';
  encart: any;

  constructor(
    private router: Router,
    private consultationCompanyService: AgentConsultationCompanyService,
    private interlocutorService: InterlocutorService,
    private sessionService: SessionService,
    private searchService: SearchService,
    private encartService: EncartService
  ) {}

  async ngOnInit() {
    this.encart = (await this.encartService.getEncartExtranet());
    this.areConsultationsLoading = true;
    this.sessionService.getAgentAsObservable().subscribe(agent => {
      if (agent) {
        this.agent = agent;
        this.interlocutorService
          .getById(this.agent.interlocutorid)
          .then((interlocutor: any) => {
            this.agent_interlocutor = interlocutor;
            this.agent_structure = interlocutor.structure;
          });
        this.consultationCompanyService
          .getByAgent(this.agent.id)
          .then((response2: any) => {
            if (!response2.hasError) {
              this.consultations = response2;
              this.dates = this.getMostRecentConsultationsDate();
              this.areConsultationsLoading = false;
            }
          });
      }
    });
  }

  switchTab(tab) {
    this.tab = tab;
  }

  getMostRecentConsultationsDate(count: number = 3) {
    const dates = [];
    this.consultations.forEach(consultation => {
      dates.push(consultation.createdat);
    });
    const result = {};
    return dates
      .filter(function(item) {
        const date = moment(item, simpleDateComparisonFormat).format(
          simpleDateComparisonFormat
        );
        return result.hasOwnProperty(date) ? false : (result[date] = true);
      })
      .sort((date1: any, date2: any) => {
        if (
          moment(date1, simpleDateComparisonFormat) <=
          moment(date2, simpleDateComparisonFormat)
        ) {
          return 1;
        } else {
          return -1;
        }
      })
      .slice(0, count);
  }

  listConsultationByDay(date) {
    let consultations = [];
    this.consultations.forEach((consultation: ConsultationCompanyType) => {
      const consultDate = moment(moment(consultation.createdat).format(simpleDateComparisonFormat));
      const compareDate = moment(moment(date).format(simpleDateComparisonFormat));
      if (consultDate.isSame(compareDate)) {
        consultations = [...consultations, consultation];
        return consultations;
      }
    });
    const alreadyChecked = {};

    // Retirer les doublons des consultations de la même entreprise le même jour
    const consultationsWithoutDuplicates = consultations.filter(
      consultation => {
        const indexToAdd = this.agent.id + consultation.company.id;
        return alreadyChecked.hasOwnProperty(indexToAdd)
          ? false
          : (alreadyChecked[indexToAdd] = true);
      }
    );
    return consultationsWithoutDuplicates;
  }

  goToSearchPage(searchObject: { type: string; search: SearchArray }) {
    searchObject.search.tab = searchObject.type;
    this.searchService.query = searchObject.search;
    this.router.navigate(['recherche'], {
      queryParams: {
        tab: searchObject.type
      }
    });
  }

  goToCompanyPage(dataId) {
    const clicked =
      this.consultations.find(
        consultation => consultation.company.id === dataId
      ) || {};
    const { company = {} } = clicked;
    this.router.navigate(['entite', dataId]);
  }
}
