import { Injectable, Injector } from '@angular/core';

import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';

@Injectable()
export class StructureService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.structure;
  }

  getAllOrderByNameSimple() {
    return this.api.get(`${this.endpoint}/simple`);
  }

  getAllFinancersOrderByNameSimple() {
    return this.api.get(`${this.endpoint}/financers/simple`);
  }

  getAllStructureRDEO() {
    return this.api.get(`${this.endpoint}/RDEO`);
  }

}
