import { AfterContentInit, Component } from '@angular/core';
import { AbstractTabs } from './AbstractTabs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-hub-tabs',
  styleUrls: ['./tabs.component.scss'],
  templateUrl: './tabs.component.html'
})
export class TabsComponent extends AbstractTabs implements AfterContentInit {}
