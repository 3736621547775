import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { environmentLoader } from '../../../environments/environmentLoader';
import { AuthService } from '../auth.service';
import { HttpMethods } from 'src/app/constants';

@Injectable()
export class ApiService {
  hubApiHost = '';
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {
    environmentLoader.then(env => {
      this.hubApiHost = env.settings.hubApi.host;
      this.options.headers = this.options.headers.append('realm', env.settings.auth.keycloak.realm);
    });
  }

  get(endpoint: string, params: any = {}) {
    return this.sendRequest(
      HttpMethods.GET,
      `${this.hubApiHost}/${endpoint}?${stringify(params)}`
    );
  }

  post(endpoint: string, params: any = {}) {
    return this.sendRequest(
      HttpMethods.POST,
      `${this.hubApiHost}/${endpoint}`,
      params
    );
  }

  put(endpoint: string, params: any = {}) {
    return this.sendRequest(
      HttpMethods.PUT,
      `${this.hubApiHost}/${endpoint}`,
      params
    );
  }

  delete(endpoint: string) {
    return this.sendRequest(
      HttpMethods.DELETE,
      `${this.hubApiHost}/${endpoint}`
    );
  }

  getByUnencodedUrl(endpoint: string, params: any = {}) {
    return this.sendRequest(
      HttpMethods.GET,
      `${this.hubApiHost}/${endpoint}?${this.discardUrlEncode(
        stringify(params)
      )}`
    );
  }

  discardUrlEncode(url): string {
    const mapObject = { '%3A': ':', '%26': '&', '%2F': '/', '%3D': '=' };
    const regex = new RegExp(Object.keys(mapObject).join('|'), 'gi');

    return url.replace(regex, matchingElement => {
      return mapObject[matchingElement];
    });
  }

  sendRequest(method, url, params: any = {}) {
    return this.authService.getToken().then((token: string) => {
      switch (method) {
        case HttpMethods.GET:
          return this.httpResponseWrapper(this.http.get(url, this.options));
        case HttpMethods.POST:
          return this.httpResponseWrapper(
            this.http.post(url, params, this.options)
          );
        case HttpMethods.PUT:
          return this.httpResponseWrapper(
            this.http.put(url, params, this.options)
          );
        case HttpMethods.DELETE:
          return this.httpResponseWrapper(this.http.delete(url, this.options));
        default:
          break;
      }
    });
  }

  private httpResponseWrapper(httpResponse: Observable<any>) {
    return new Promise((resolve, reject) =>
      httpResponse.subscribe(
        response => resolve(response),
        error => {
          console.error(error);
          reject(error);
        }
      )
    );
  }
}
