import {Component, OnInit} from '@angular/core';
import {EventService} from "../../../services/hub-api/eventService";
import {SessionService} from "../../../services/session.service";
import {InterlocutorService} from "../../../services/hub-api/interlocutor.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {routesUrl} from "../../../constants";
import {TranslateService} from "@ngx-translate/core";
import {UpperCaseFirstPipe} from "../../../pipes/ucfirst.pipe";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'evenements-add',
  templateUrl: './evenements-add.component.html'
})
export class EvenementsAddComponent implements OnInit {
  title = 'Créer un événment';
  currentAgent: any;
  // Détient les informations sur tout ce qui concerne la structure, le poste etc...
  currentInterlocutor: any;
  event: any = {
    logo: {},
    title: '',
    post_content_plain: '',
    post_content_html: '',
    lieu: '',
    permalink: '',
    datedebut: '',
    datefin: '',
    heure_debut: '08:00:00',
    heure_fin: '18:00:00',
    createur: undefined,
    defaultLogo: undefined
  };

  constructor(private eventService: EventService,
              private session: SessionService,
              private interlocutorService: InterlocutorService,
              private toastr: ToastrService,
              private router: Router,
              private translateService: TranslateService,
              private ucfirst: UpperCaseFirstPipe
  ) {

  }

  ngOnInit() {
    this.session.getAgent().then(async agent => {
      if (agent) {
        this.currentAgent = agent;
        this.currentInterlocutor = await this.interlocutorService
          .getById(this.currentAgent.interlocutorid);
      }
    });
  }

  onSubmit(event: any) {
    event.createur = {id: this.currentInterlocutor.id};
    this.eventService.post(event).then(() => {
      this.toastr.success(this.ucfirst.transform(
        this.translateService.instant('evenements-form.toastr.event-created')
      ));
      this.router.navigate([routesUrl.EVENTS_LIST]);
    })
      .catch(() => {
        this.toastr.error(this.ucfirst.transform(
          this.translateService.instant('evenements-form.toastr.event-error')
        ));
        this.router.navigate([routesUrl.EVENTS_LIST]);
      });
  }

  redirectToList(){
    this.router.navigate([routesUrl.EVENTS_LIST]);
  }
}
