import {Component, OnInit} from '@angular/core';
import {HubBreadCrumbsService} from '../../services/hub-breadcrumbs.service';
import {TranslateService} from '@ngx-translate/core';
import {RdeoDocumentService} from '../../services/hub-api/rdeo-document.service';
import {RdeoVideoService} from '../../services/hub-api/rdeo-video.service';
import {RdeoDocumentFileService} from '../../services/hub-api/rdeo-document-file.service';
import { saveAs } from 'file-saver';
import validUrl from 'valid-url';
import {mimeTypes} from '../../constants';
import {HttpClient} from '@angular/common/http';
import * as isUUID from 'is-uuid';

@Component({
  selector: 'app-espace-rdeo',
  templateUrl: './espace-rdeo.component.html',
  styleUrls: ['./espace-rdeo.component.scss']
})
export class EspaceRdeoComponent implements OnInit {

  rdeoDocument: any = {};
  rdeoVideo: any = {};
  isLoading = true;

  constructor(
    private breadcrumbs: HubBreadCrumbsService,
    private translateService: TranslateService,
    private rdeoDocumentService: RdeoDocumentService,
    private rdeoVideoService: RdeoVideoService,
    private rdeoDocumentFileService: RdeoDocumentFileService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    await this.breadcrumbs.add([
      {
        label: this.translateService.instant('breadcrumbs.espace-rdeo'),
        url: '/espace-rdeo',
        params: []
      }
    ]);

    // Récupération des tous les Rdeo Document
    await this.rdeoDocumentService.getAllDto().then((data: any) => {
      if (!data.hasError) {
        for (const doc of data) {
          Object.assign(doc, {isFileUrl: this.isFileUrl(doc.file)});
          Object.assign(doc, {isValidUrl: this.isValidUri(doc.url)});
          Object.assign(doc, {finalTitle: this.constructFinalTileRdeoDoc(doc)});
        }
        this.rdeoDocument = data;
      }
    }).catch(() => { });

    // Récupération des tous les Rdeo Video
    this.rdeoVideoService.getAllDto().then((data: any) => {
      if (!data.hasError) {
        for (const vid of data) {
          Object.assign(vid, {isValidUrl: this.isValidUri(vid.url)});
          Object.assign(vid, {finalTitle: this.constructFinalTileRdeoVid(vid)});
        }
        this.rdeoVideo = data;
      }
    }).catch(() => {});
  }

  /**
   * Indiquation sur la validation d'une url
   * @param uri Url
   * @return boolean true or false
   */
  isValidUri(uri) {
    return uri != null && uri !== '' && validUrl.isWebUri(uri) !== undefined;
  }

  /**
   * Téléchargement d'un fichier
   * @param itemFile Lien BDD du fichier
   */
  saveFileUpload(itemFile) {
    this.http.get(this.getFileUrlByFile(itemFile), {
      responseType: 'blob'
    }).toPromise().then(res => {
      const file = new Blob([res], { type: itemFile.mimeType });
      saveAs(file, itemFile.originalname);
    });
  }

  /**
   * Récupération d'une url de fichier en fonction du lien BDD du fichier
   * @param link Lien BDD du fichier
   * @return url de fichier
   */
  getFileUrlByFile(link) {
    let fileUrl = null;
    if (link && link.id && isUUID.anyNonNil(link.id)) {
      fileUrl = this.rdeoDocumentFileService.getFileUrl(link);
    }
    return fileUrl;
  }

  /**
   * Indiquation sur la validation d'une url de fichier
   * @param link Lien BDD du fichier
   * @return boolean true or false
   */
  isFileUrl(link) {
    let fileUrl = false;
    if (link && link.id && isUUID.anyNonNil(link.id)) {
      if (this.rdeoDocumentFileService.getFileUrl(link).length > 0) {
        fileUrl = true;
      }
    }
    return fileUrl;
  }

  /**
   * Récupération de la classe css logo en fonction d'un type mime
   * @param fileMimeType Type mime
   * @return Classe de Logo Bootstrap
   */
  getMimeTypeLogo(fileMimeType) {
    let logoClass;
    switch (fileMimeType) {
      case mimeTypes.pdf:
        logoClass = 'fa-file-pdf';
        break;
      case mimeTypes.xls:
      case mimeTypes.xlsx:
      case mimeTypes.ods:
        logoClass = 'fa-file-excel';
        break;
      case mimeTypes.doc:
      case mimeTypes.docx:
      case mimeTypes.odt:
        logoClass = 'fa-file-word';
        break;
      case mimeTypes.ppt:
      case mimeTypes.pptx:
      case mimeTypes.odp:
        logoClass = 'fa-file-powerpoint';
        break;
      case 'url':
        logoClass = 'fa-link';
        break;
      default:
        logoClass = 'file';
    }
    return logoClass;
  }

  /**
   * Transformation d'un Objet en Array (ngFor)
   * @param myObject Objet
   * @return Array correspandante
   */
  getObjectValuesItem(myObject) {
    return Object.values(myObject);
  }

  /**
   * Création du titre final
   * @param data Objet RdeoLinkDocument
   * @return Titre final
   */
  constructFinalTileRdeoDoc(data) {
    if (data.title) {
      return data.title;
    } else {
      if (data.file) {
        return data.file.originalname;
      } else if (!data.file && data.url) {
        return data.url;
      } else {
        return 'Document/Lien RDEO';
      }
    }
  }

  /**
   * Création du titre final
   * @param data Objet RdeoLinkVideo
   * @return Titre final
   */
  constructFinalTileRdeoVid(data) {
    if (data.title) {
      return data.title;
    } else {
      if (data.url) {
        return data.url;
      } else {
        return 'Lien video RDEO';
      }
    }
  }
}
