import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  @Input()
  id: number;
  @Input()
  titre: string;
  @Input()
  selected = false;
  @Input()
  orientation: string;
  @Input()
  background: string;
}
