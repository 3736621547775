import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {simpleDateFormat} from "../constants";

@Pipe({ name: 'frenchdate' })
export class FrenchVerboseDatePipe implements PipeTransform {
  transform(content, format = simpleDateFormat) {
    const date = moment(content, format);
    return date.isValid() ? date.locale('fr').format('LL') : '';
  }
}
