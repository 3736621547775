import {Injectable, Injector} from "@angular/core";
import {AbstractService} from "./abstract.service";
import {endpoints} from "../../constants";

@Injectable()
export class UserConsultationPlanService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.userConsultationsPlan;
  }


  /**
   * Rechercher l'historique de navigation sur les dispositifs d'une entreprise sur les X derniers mois
   * @param companyId l'id de l'entreprise concernée
   * @param months le nombre de mois sur lequel effectuer la recherche
   * @returns Promise<any> l'historique de navigation sous forme de Promise
   */
  getNavigationHistoryByCompany(companyId: string, months: number): Promise<any> {
    return this.api.get(`${this.endpoint}/${companyId}/history/months/${months}`);
  }
}
