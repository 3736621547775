import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {UpperCaseFirstPipe} from '../../pipes/ucfirst.pipe';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subscription} from 'rxjs';
import {CompanyService} from 'src/app/services/hub-api/company.service';
import {AgentConsultationCompanyService} from 'src/app/services/hub-api/consultation-company.service';
import {HubBreadCrumbsService} from 'src/app/services/hub-breadcrumbs.service';
import {RoutingStateService} from 'src/app/services/routing-state.service';
import {SessionService} from 'src/app/services/session.service';
import {MailService} from '../../services/hub-api/mail.service';
import {
  ajoutActeurMail,
  entityTypes, mailHub,
  nameTabSearchPage,
  projectSteps,
  simpleDateComparisonFormat
} from 'src/app/constants';
import {BsModalService} from "ngx-bootstrap/modal";
import {dateFormatProject, urlRoutes} from '../../constants';
import {InterlocutorService} from 'src/app/services/hub-api/interlocutor.service';
import {GDIService} from "../../services/hub-api/g-d-i.service";
import * as slugify from 'slugify';
import {AddProjectActorComponent} from "../../components/add-project-actor/add-project-actor.component";
import {ProjectService} from "../../services/hub-api/project.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {UserConsultationPlanService} from "../../services/hub-api/user-consultation-plan.service";
import {NgbAccordion} from "@ng-bootstrap/ng-bootstrap";
import {first} from 'rxjs/operators';
import {KeyValue} from "@angular/common";
import {SubventionService} from "../../services/hub-api/subvention.service";
import {EmailParams} from '../../models/email-params.model';
import {TYPE_MAIL_ENUM} from '../../models/enum/type-mail.enum';

@Component({
  selector: 'app-entreprise',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntityComponent implements OnInit, OnDestroy {

  entity: any = {};
  agent: any = {};
  interlocutor: any = {};
  id: string;
  sub: Subscription;
  areDataLoaded: boolean;
  isReferent = false;
  projects: any = [];
  showQualifiedProjects = true;
  showInProgressProjects = true;
  subventions: Array<any>;
  dateFormat = dateFormatProject;
  navigationHistoryDateItemFormat = simpleDateComparisonFormat;
  projectIsLoading = true;
  agent_structure: any = {};
  entityTypes: any;
  gdiProjectOwner: any = {};
  qualifiedProjects: Array<any> = [];
  inProgressProjects: Array<any> = [];
  isJustActor = false;
  navigationHistory: Map<string, Array<any>> = new Map<string, Array<any>>();
  selectedTab = 0;

  constructor(
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private breadcrumbs: HubBreadCrumbsService,
    private spinner: NgxSpinnerService,
    private consultationCompanyService: AgentConsultationCompanyService,
    private routingStateService: RoutingStateService,
    private router: Router,
    private translate: TranslateService,
    private interlocutorService: InterlocutorService,
    private projectService: ProjectService,
    private modalService: BsModalService,
    private mailService: MailService,
    private toastr: ToastrService,
    private gdi: GDIService,
    private ucfirst: UpperCaseFirstPipe,
    private subventionService: SubventionService,
    private userConsultationPlanService: UserConsultationPlanService
  ) {
  }

  async ngOnInit() {

    this.entityTypes = entityTypes;
    const promises = [];
    this.areDataLoaded = false;
    this.sub = this.route.params.subscribe(params => (this.id = params['id']));

    const firstPromise = await Promise.resolve(
      this.companyService.getOne(this.id).then(async (company: any) => {
        this.entity = company;
        this.createBreadcrumbs();
        this.interlocutor = company.interlocutor;

        if (this.entity.type === entityTypes.company) {
          this.subventionService.getSubventionsBySiret(this.entity.identifier).then((subventions: any[]) => {
            this.subventions = subventions.sort((subvention1: any, subvention2: any) => {
              const moment1 = moment(subvention1.dossierProgos.dateDemandeDossier);
              const moment2 = moment(subvention2.dossierProgos.dateDemandeDossier);
              if (moment1.isBefore(moment2)) return 1;
              else if (moment1.isAfter(moment2)) return -1;
              else return 0;
            });
          }).catch(err => {
            if (err.error && err.error.message) {
              this.toastr.error(err.error.message);
            } else {
              this.toastr.error(this.translate.instant('company-details.default-error-fetch-subventions'));
            }
          });
        }

        this.navigationHistory = (await this.userConsultationPlanService.getNavigationHistoryByCompany(this.id, 6));
        await this.companyService
          .getAllProjects(this.entity.id)
          .then(async (projects: any = []) => {
            this.projects = projects;

            // récupération des acteurs pour chaque projet
            this.projects.forEach(async project => {
              await this.projectService.getInterlocutorsByProjectId(project.id).then(actors => {
                project.actors = actors;
                if (project.actors && project.actors.length > 0) {
                  project.isExistingActors = true;
                }
              });
            });


            await this.sessionService.getAgent().then(agent => {
              this.agent = agent;
              this.isReferent = this.entity.interlocutor.id === this.agent.interlocutorid;
              this.interlocutorService
                .getById(this.agent.interlocutorid)
                .then((interlocutor: any) => {
                  this.agent_structure = interlocutor.structure;
                });
              //si l'agent connecté n'est ni référent ni agent Région ou Adocc, on recupère uniquement les projets dont il est acteur
              if (!this.isReferent && !this.isAgentRegionOrAdOcc(this.agent)) {
                this.isJustActor = true;
                this.interlocutorService.getInterlocutorProjects(this.agent.interlocutorid).then((projects: any) => {
                  const projectsTmp = projects.map((project: any) => project.id);
                  this.projects = this.projects.filter(project => projectsTmp.includes(project.id));


                  //placer l'agent connecté en dernière position de la liste des acteurs s'il en fait partie
                  this.projects.forEach(async project => {
                    await this.projectService.getInterlocutorsByProjectId(project.id).then(actors => {
                      project.actors = actors;
                      const interlocutorToMove = project.actors.find(actor => actor.id === this.agent.interlocutorid);
                      if (interlocutorToMove !== undefined) {
                        project.actors.splice(project.actors.indexOf(interlocutorToMove), 1);
                        interlocutorToMove.lastname = "Vous";
                        interlocutorToMove.firstname = "";
                        project.actors.push(interlocutorToMove);
                      }
                    })
                  });

                  this.qualifiedProjects = this.projects && this.projects.filter(project => project.step && project.step === projectSteps.done);
                  this.inProgressProjects = this.projects && this.projects.filter(project => project.step && project.step !== projectSteps.done);
                  this.projectIsLoading = false;
                });
              }
              //sinon on recupère tous les projets
              else {
                this.qualifiedProjects = this.projects && this.projects.filter(project => project.step && project.step === projectSteps.done);
                this.inProgressProjects = this.projects && this.projects.filter(project => project.step && project.step !== projectSteps.done);
                this.projectIsLoading = false;
              }
            });
          });
      })
    ).catch(err => {
    });
    promises.push(firstPromise);

    // Une fois qu'on a retrouvé l'utilisateur et la ccompany on peut chercher les consultations
    // et récupérer la plus récente
    this.spinner.show();
    Promise.all(promises)
      .then(async () => {
        // Ajout d'une nouvelle consultation comme on arrive sur la page
        this.consultationCompanyService.post({
          company: {
            id: this.id
          },
          agent: {
            id: this.agent.id
          },
          date: moment.now()
        });

        if (this.entity.type === this.entityTypes.project) {
          this.gdiProjectOwner = (await this.gdi.getMandatairesBySiret(this.entity.identifier))[0].userInformation;
        }
        this.areDataLoaded = true;
        const queryParams = await this.route.queryParamMap.pipe(first()).toPromise();
        if (queryParams.get('tab') && queryParams.get('tab') === 'navigation-history') {
          this.selectedTab = 4;
        } else if (queryParams.get('tab') && queryParams.get('tab') === 'projects') {
          this.selectedTab = 2;
        }
        this.spinner.hide();
      })
      .catch(() => {
        this.areDataLoaded = true;
        this.spinner.hide();
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /*
   * Créer dynamiquement le breadcrumbs avec la page précedente si elle existe, sinon on affiche juste
   * l'élement courant.
   * Ex :
   *  - Si on arrive directement sur /entreprise/xx => "Accueil > Page d'informations entreprise"
   *  - Si on arrive depuis la recherche => "Accueil > Ma recherche > Page d'informations entreprise"
   */
  private createBreadcrumbs() {
    const breadcrumbsItems = [];
    // On va chercher l'url précédente du user, si on a une url, on l'injecte dans le breadcrumbs
    const previousUrl = this.routingStateService.getPreviousUrl();

    // On enlève le slash et on prends que la 1ère partie de l'url
    // pour aller faire la recherche de la traduction dans i18n après
    const previousUrlWithoutSlash = previousUrl.substr(1);

    // Si on a une page précente, on la rajoute
    if (
      previousUrlWithoutSlash &&
      previousUrlWithoutSlash.includes(urlRoutes.recherche)
    ) {
      breadcrumbsItems.push({
        label: 'breadcrumbs.'.concat(urlRoutes.recherche),
        url: '/'.concat(urlRoutes.recherche),
        params: {tab: nameTabSearchPage.contact}
      });
    } else if (
      previousUrlWithoutSlash &&
      previousUrlWithoutSlash === urlRoutes.myWallet
    ) {
      breadcrumbsItems.push({
        label: 'breadcrumbs.'.concat(previousUrlWithoutSlash),
        url: '/'.concat(urlRoutes.myWallet),
        params: []
      });
    } else if (
      previousUrlWithoutSlash &&
      previousUrlWithoutSlash === urlRoutes.myNotification
    ) {
      breadcrumbsItems.push({
        label: 'breadcrumbs.'.concat(previousUrlWithoutSlash),
        url: '/'.concat(urlRoutes.myNotification),
        params: []
      });
    }

    // Puis on place l'élement courant de la page!
    breadcrumbsItems.push({
      label: 'breadcrumbs.company-details-page',
      url: '/entite/' + this.id,
      params: []
    });

    // setTimeout est ici pour éviter une erreur dans la console
    setTimeout(() => this.breadcrumbs.add(breadcrumbsItems));
  }

  getRun(project) {
    return JSON.parse(project.run);
  }

  selectChoice(project) {
    const run = this.getRun(project);
    const choice = run[run.length - 1].choice;
    return choice;
  }

  getUrlPathImage(nameProject: string) {
    return `/assets/images/link-card/${slugify.default(nameProject.toLowerCase(), {
      remove: /[*+~.()'"!:@{}|/=°^#&]/g,
    })}.png`;
  }

  formatStep(project) {
    // const finalStep = (project.run ? JSON.parse(project.run).length : 0) + 1;
    const step = project.step;
    const finalStep = projectSteps.done;
    return `${step}/${finalStep}`;
  }

  goToPlan(planId) {
    this.router.navigateByUrl(`/dispositif/${planId}`);
  }

  concatActivityAreas() {
    let activityAreasString = '';
    this.entity.activityareas.forEach((activityArea, index) => {
      activityAreasString += activityArea.label;
      if (index !== this.entity.activityareas.length - 1) {
        activityAreasString += ', '
      }
    });
    return activityAreasString
  }

  onAddActorClick(project) {
    const initialState = {projectId: project.id, existingActors: project.actors, referer: this.interlocutor}
    const modalRef = this.modalService.show(AddProjectActorComponent, Object.assign({}, {
      initialState,
      animated: true,
      class: 'hub-modal-actors'
    }));

    modalRef.content.onSuccess = async () => {
      const succesMessage = this.translate.instant('my-projects.actors-added-message');
      this.toastr.success(succesMessage);
      const newActorsList = await this.projectService.getInterlocutorsByProjectId(project.id);
      project.actors = newActorsList;
      project.isExistingActors = true;

      // Send mail only to new actors
      const userConnected = await this.sessionService.getAgent();
      for (let actor of project.actors) {
        if (!initialState.existingActors.some(existingActor => existingActor.email === actor.email)) {
          this.sendEmailToProjectActors(project, userConnected, actor.id);
        }
      }
    }
    modalRef.content.onFail = () => {
      const errorMessage = this.translate.instant('my-projects.error-message');
      this.toastr.error(errorMessage);
    }
  }

  removeActor(project, actor) {
    this.projectService.removeActorFromProject(project.id, actor.id).then(() => {
      project.actors = project.actors.filter(actorItem => actorItem.id !== actor.id);
      const deleteMessage = this.translate.instant('my-projects.delete-message');
      this.toastr.success(`${actor.firstname} ${actor.lastname} ${deleteMessage} ${project.name}`);
      if (project.actors && project.actors.length === 0) {
        project.isExistingActors = false;
      }
    }).catch(e => {
      this.toastr.error(`${e.message}`);
    });
  }

  private isAgentRegion(agent: any): boolean {
    return agent.email.includes('@laregion.fr');
  }

  private isAgentAdOcc(agent: any): boolean {
    return agent.email.includes('@agence-adocc.com');
  }

  private isAgentRegionOrAdOcc(agent: any): boolean {
    return this.isAgentRegion(agent) || this.isAgentAdOcc(agent);
  }

  getClass(accordion: NgbAccordion, panelId) {
    return accordion.isExpanded(panelId)
      ? 'fa-caret-up fa float-right'
      : 'fa-caret-down fa float-right';
  }

  historySortFunction(item1: KeyValue<string, Array<any>>, item2: KeyValue<string, Array<any>>) {
    // La clé correspond à la date regroupant les consultations. On veut mettre la plus récente en 1er.
    return item2.key > item1.key ? 1 : -1;
  }

  sendEmailToProjectActors(project, userConnected, actorId) {

    const emailParams = new EmailParams();

    emailParams.userFirstname = userConnected.firstname;
    emailParams.userLastname = userConnected.lastname.toLocaleUpperCase();
    emailParams.structureName = this.agent_structure?.name;
    emailParams.companyName = this.entity.name;
    emailParams.codeDepartment = this.entity?.inseeCode?.substr(0, 2);
    emailParams.interlocutorId = actorId;

    // Send mail
    this.mailService
      .sendMailForType(
        TYPE_MAIL_ENUM.ajout_membre_equipe_projet,
        emailParams
      ).then(() => {
    })
      .catch(() => {
      });
  }
}
