import {Component} from '@angular/core';
import {interlocutorRoles, nameTabSearchPage, routesUrl} from "../../constants";
import {Router} from "@angular/router";
import {UserType} from "../../constants/type-object-database";
import {AuthService} from "../../services/auth.service";
import {InterlocutorService} from "../../services/hub-api/interlocutor.service";

@Component({
  selector: 'app-plan-site',
  templateUrl: './plan-site.component.html',
  styleUrls: ['./plan-site.component.scss']
})
export class PlanSiteComponent {

  routes = routesUrl;
  tabs = nameTabSearchPage;
  isLoggedIn = false;
  user: UserType;
  isReferent = false;

  constructor(private router: Router,
              private authService: AuthService,
              private interlocutorService: InterlocutorService) { }

  async ngOnInit() {
    this.isLoggedIn = await this.authService.isLoggedIn();

    if (this.isLoggedIn) {
      this.user = await this.authService.getCurrentUser();

      this.interlocutorService
        .getinterlocutorRolesByEmail(this.user.email)
        .then((interlocutorRolesValue: any) => {
          if (interlocutorRolesValue.includes(interlocutorRoles.referent)) {
            this.isReferent = true;
          }
        })
        .catch(() => {});
    }
  }

  goToSearchPage(tabSelected: string) {
    this.router.navigate(['/' + this.routes.SEARCH], {
      queryParams: {
        notSearch: 'true',
        tab: tabSelected
      }
    });
  }

}
