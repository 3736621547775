<div>
  <h1 class="line">
    <span>{{ 'my-contacts.title' | translate | ucfirst }}</span>
  </h1>

  <div *ngIf="!isLoading && contacts.length === 0" class="text-center">
    <span class="hub-search-no-results">{{ 'my-contacts.no-contact' | translate | ucfirst }}</span>
  </div>

  <div class="pagination" *ngIf="!isLoading && contacts.length > 0">
    <pagination-template class="pagination-all" #p="paginationApi" [id]="configPagination[0].id"
                         (pageChange)="configPagination[0].currentPage = $event">
      <div class="custom-pagination">
        <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
          <a *ngIf="!p.isFirstPage()" (click)="p.previous()"
             title="{{ 'my-directory.previous-page' | translate | ucfirst }}">
            <i class="fa fa-chevron-circle-left"></i> </a>
        </div>
        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
          <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
            <span>{{ page.label }}</span>
          </a>
          <div *ngIf="p.getCurrent() === page.value" class="paginationSelected">
            <span>{{ page.label }}</span>
          </div>
        </div>
        <div class="pagination-next" [class.disabled]="p.isLastPage()">
          <a *ngIf="!p.isLastPage()" (click)="p.next()"
             title="{{ 'my-directory.next-page' | translate | ucfirst }}">
            <i class="fa fa-chevron-circle-right"></i> </a>
        </div>
      </div>
    </pagination-template>
  </div>

  <div class="mb-3" *ngFor="let contact of contacts | paginate: configPagination[0]">
    <hub-interlocutor-card
      [interlocutor]="contact"
      [isContact]="true"
      (onStarClick)="deleteContact($event.interlocutor)">
    </hub-interlocutor-card>
  </div>
</div>
