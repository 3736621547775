import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import {EncartService} from "../../../services/hub-api/encart.service";

@Component({
  selector: 'app-home-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class HomeDefaultComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService
  ) {}

  loginErrorModal: BsModalRef;
  @ViewChild('modalTemplate', { static: true }) template: TemplateRef<any>;
  email: string;

  async ngOnInit() {
    this.email = this.localStorageService.getLoginError();
    if (this.email) {
      this.showLoginErrorModal();
      this.localStorageService.deleteLoginError();
    }
  }

  goToLogin() {
    return this.authService.goToLogin();
  }

  showLoginErrorModal() {
    this.loginErrorModal = this.modalService.show(
      this.template,
      Object.assign({}, { class: 'hub-modal' })
    );
  }
}
