<div class="hub-ref-card shadow mb-3">
  <div *ngIf="!refererLoaded()">
    <p class="blink_text">...</p>
  </div>
  <div *ngIf="hasReferer() && refererLoaded()">
    <div [style.margin-bottom]="title ? '20px' : 'O'">
      <div *ngIf="title" class="hub-ref-card--title">
        {{ title | translate | ucfirst }}
      </div>
    </div>

    <div class="hub-ref-card--person">
      <table>
        <tr>
          <td>
            <img [src]="avatarUrl" class="hub-ref-card--person---avatar"
                 alt="{{ 'refCard.profilePicture' | translate | ucfirst }}"/>
          </td>
          <td>
            <div class="hub-ref-card--person---infos">
              <span class="hub-ref-card--person---name">{{ referer?.firstname }} {{ referer?.lastname }}</span>
              <span *ngIf="referer?.occupation?.label" class="hub-ref-card--person---occupation"
                    title="{{ referer?.occupation?.label }}">{{
                referer?.occupation?.label
                }}</span>
              <span *ngIf="referer?.structure?.name">{{
                referer?.structure?.name
                }}</span>
              <span *ngIf="referer?.phonenumber && !referer?.phonenumber2">
                {{ referer?.phonenumber }}</span>
              <span *ngIf="!referer?.phonenumber && referer?.phonenumber2">
                {{ referer?.phonenumber2 }}</span>
              <span *ngIf="referer?.phonenumber && referer?.phonenumber2">
                {{ referer?.phonenumber }} - {{ referer?.phonenumber2 }}</span>
              <span class="hub-ref-card--person---mail" *ngIf="referer?.email" title="{{ referer?.email }}">
                <a [href]="'mailto:' + referer?.email">
                  {{referer?.email}}</a></span>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="hub-ref-card--contact">
      <a *ngIf="referer?.email" [href]="'mailto:' + referer?.email"
         title="{{ 'refCard.contactByEmail' | translate | ucfirst }}">
        <img src="/assets/icons/mail-mainColor.png"/>
      </a>
      <a *ngIf="referer?.skype" [href]="sanitize('skype:' + referer?.skype + '?chat')"
         title="{{ 'refCard.contactBySkype' | translate | ucfirst }}">
        <img src="/assets/icons/skype-mainColor.png"/>
      </a>
      <a *ngIf="referer?.linkedin" target="_blank" rel="noreferer noopener"
         title="{{ 'refCard.contactByLinkedin' | translate | ucfirst }}" [href]="checklink(referer?.linkedin)">
        <img src="/assets/icons/linkedin-mainColor.png"/>
      </a>
      <div *ngIf="referer?.structure?.logo?.id">
        <img src="{{ getLogoUrl(referer?.structure?.logo) }}" alt="logo de la structure"
             class="hub-ref-card--contact---logo"/>
      </div>
    </div>
  </div>
</div>
