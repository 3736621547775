import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges {
  @Input() isOpen: boolean;
  @Output() close = new EventEmitter();

  _open: boolean;

  constructor() {}

  ngOnChanges() {
    this._open = this.isOpen ? true : false;
  }

  preventClose(event) {
    event.stopPropagation();
  }
}
