import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';
import { endpoints } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class OccupationService extends AbstractService {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.occupations;
  }
}
