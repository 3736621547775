<div class="hub-header">
  <div class="hub-header--content">
    <div class="d-flex h-100">
      <div role="button" class="hub-header-content--img" (keydown.enter)="goToHome()" (click)="goToHome()">
        <img class="hub-header--logo" src="../../../../../assets/images/logo-region-hub.jpg" alt="{{ 'header.picture' | translate | ucfirst }}" />
      </div>
      <div class="hub-header--elements">
        <div class="hub-header--title">
          <p>{{ 'header.welcome' | translate | ucfirst }}</p>
          <p>{{ 'header.view-agents-partners' | translate | ucfirst }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="hub-header--menus">
    <div class="hub-header--menus-inner">
      <a tabindex="0" class="hub-header--slider-menu" (keydown.enter)="toggleNav()" (click)="toggleNav()">
        <span>{{ 'header.menu' | translate }}</span>
        <i class="fa fa-bars pointer"></i>
      </a>
      <div class="hub-header--profile">
        <p>
          <img title="{{ user?.firstName }}" [src]="avatarUrl" alt="Profile" class="pointer avatar" />
          <a tabindex="0" (keydown.enter)="logout()" (click)="logout()" class="hub-logout">
            {{ 'logout' | translate | ucfirst }}
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
