import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanService } from '../../services/hub-api/plan.service';
import { RoutingStateService } from '../../services/routing-state.service';
import { TitleService } from '../../services/title.service';
import {mimeTypes, nameTabSearchPage, planTypes, urlRoutes} from '../../constants/index';
import { HubBreadCrumbsService } from 'src/app/services/hub-breadcrumbs.service';
import {UpperCaseFirstPipe} from "../../pipes/ucfirst.pipe";
import {TranslateService} from "@ngx-translate/core";
import {PlanFileService} from "../../services/hub-api/plan-file.service";
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {VideoModalComponent} from '../../components/modal/videoModal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-dispositif',
  templateUrl: './dispositif.component.html',
  styleUrls: ['./dispositif.component.scss']
})
export class DispositifComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    private route: ActivatedRoute,
    private planService: PlanService,
    private routingStateService: RoutingStateService,
    private router: Router,
    private ucfirst: UpperCaseFirstPipe,
    private translate: TranslateService,
    private breadcrumbs: HubBreadCrumbsService,
    private planFileService: PlanFileService,
    private modalService: NgbModal
  ) {}

  idPlan: string;
  slugPlan: string;
  user: any = {};
  plan: any = {};
  notFound = false;
  isLoading = true;
  routeURL = '';
  detailedCardFileName: string;

  mimeTypes = mimeTypes;
  planTypes = planTypes;

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.slugPlan = params.slug;
      if (this.slugPlan) {
        this.planService
          .getOneBySlugOrId(this.slugPlan)
          .then((data: any) => {
            if (!data.hasError) {
              this.plan = data;
              this.plan.faqs = this.plan.faqs.sort((a, b) => b.updatedat - a.updatedat);
              this.idPlan = this.plan.id;
              this.createBreadcrumbs();

              this.plan.documents = this.planFileService.sortDocuments(this.plan.documents);

              this.detailedCardFileName = this.plan.detailedcardfilename ? this.plan.detailedcardfilename :
                this.ucfirst.transform(
                  this.translate.instant('dispositif-page.detailed-card')
                );

              // Set le titre dans l'onget du navigateur
              this.titleService.setPageTitle(this.plan.name);

              this.isLoading = false;
            } else {
              this.notFound = true;
              this.isLoading = false;
            }
          })
          .catch(() => {
            this.notFound = true;
            this.isLoading = false;
          });
      }
    });
    this.routeURL = window.location.origin + this.router.url;
  }

  /*
   * Créer dynamiquement le breadcrumbs avec la page précedente si elle existe, sinon on affiche juste
   * l'élement courant.
   * Ex :
   *  - Si on arrive directement sur /entreprise/xx => "Accueil > Page d'informations entreprise"
   *  - Si on arrive depuis la recherche => "Accueil > Ma recherche > Page d'informations entreprise"
   */
  private createBreadcrumbs() {
    const breadcrumbsItems = [];
    // On va chercher l'url précédente du user, si on a une url, on l'injecte dans le breadcrumbs
    const previousUrl = this.routingStateService.getPreviousUrl();

    // On enlève le slash et on prends que la 1ère partie de l'url
    // pour aller faire la recherche de la traduction dans i18n après
    const previousUrlWithoutSlash = previousUrl.substr(1);

    // Si on a une page précente, on la rajoute
    if (
      previousUrlWithoutSlash &&
      previousUrlWithoutSlash.includes(urlRoutes.recherche)
    ) {
      breadcrumbsItems.push({
        label: 'breadcrumbs.'.concat(urlRoutes.recherche),
        url: '/'.concat(urlRoutes.recherche),
        params: {tab: nameTabSearchPage.plan}
      });
    } else if (
      previousUrlWithoutSlash &&
      previousUrlWithoutSlash === urlRoutes.myWallet
    ) {
      breadcrumbsItems.push({
        label: 'breadcrumbs.'.concat(previousUrlWithoutSlash),
        url: '/'.concat(urlRoutes.myWallet),
        params: []
      });
    } else if (
      previousUrlWithoutSlash &&
      previousUrlWithoutSlash === urlRoutes.myNotification
    ) {
      breadcrumbsItems.push({
        label: 'breadcrumbs.'.concat(previousUrlWithoutSlash),
        url: '/'.concat(urlRoutes.myNotification),
        params: []
      });
    }

    // Puis on place l'élement courant de la page!
    breadcrumbsItems.push({
      label: 'breadcrumbs.plan-details-page',
      url: '/financement/'.concat(this.plan.slug),
      params: []
    });

    // setTimeout est ici pour éviter une erreur dans la console
    setTimeout(() => this.breadcrumbs.add(breadcrumbsItems));
  }


  openVideoModal(video) {
    let modalOption: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(VideoModalComponent, modalOption);
    modalRef.componentInstance.video = video;
  }

  goBackSearch() {
    this.router.navigateByUrl(`/recherche`);
  }

  /**
   * Boucle sur le tableau en paramètre et renvoie un tableau avec juste le label à afficher dans l'IHM
   * @param arrayValues Tableau de valeur
   * @returns Un tableau avec juste la valeur des labels to display dans l'IHM
   */
  private getValuesForCard(arrayValues) {
    arrayValues.sort((a, b) => a.ordre - b.ordre);
    return arrayValues.map(value => value.data.label);
  }

}
