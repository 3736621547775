<div class="hub-company-card shadow white">
  <div class="project-to-company italic mb-2" *ngIf="entity.companyAfterProject">
    {{ 'company-card.project-transformed' | translate:{SIRET: entity.companyAfterProject.identifier} | ucfirst }}
  </div>
  <div class="row">
    <div class="company-name-and-icon col-3">
      <hub-entity-header [entity]="entity"></hub-entity-header>
    </div>
    <div class="company-contact col-md-3" [ngClass]="noDataForContact() ? 'no-data' : ''">
      <p><b><u>{{ 'company-card.contact' | translate | ucfirst }}</u></b></p>
      <p *ngIf="isCompany() && entity.contactname"><b>{{ 'company-card.name' | translate | ucfirst }}</b> {{ entity.contactname }}</p>
      <p *ngIf="privilegedContact.phone"><b>{{ 'company-card.phone' | translate | ucfirst }}</b> {{ privilegedContact.phone }}</p>
      <p *ngIf="privilegedContact.email"><b>{{ 'company-card.mail' | translate | ucfirst }}</b> {{ privilegedContact.email }}</p>
      <p *ngIf="noDataForContact()">Aucune information disponible</p>
    </div>
    <div class="company-information col-3" [ngClass]="!showName ? 'space-around' : ''">
      <p *ngIf="isCompany() && showSiret"><b>{{ 'company-card.siret' | translate | ucfirst }}</b> {{ entity.identifier }}</p>
      <p *ngIf="!isCompany() && showName"><b>{{ 'company-card.project' | translate | ucfirst }}</b> {{ entity.name }}</p>
      <div class="company-information--activity-areas">
        <p><b>{{ 'company-card.activity-area' | translate | ucfirst }}</b> {{ firstActivityArea() }}</p>
        <p *ngIf="entity?.activityareas?.length > 1" title="{{ activityAreasToToolTips() }}"> ...</p>
      </div>
      <p><b>{{ 'company-card.last-visit' | translate | ucfirst }}</b> {{ entity.lastvisitdate | dateFormatDDMMYYYY }}</p>
    </div>
    <div class="company-interlocutor col-3">
      <p *ngIf="showReferent"><b>{{ 'company-card.interlocutor' | translate | ucfirst }}</b> {{ entity.interlocutor?.firstname }}
        {{ entity.interlocutor?.lastname }}</p>
      <p *ngIf="showStructure"><b>{{ 'company-card.structure' | translate | ucfirst }}</b> {{ entity.interlocutor?.structure?.name }}</p>
      <button class="btn-primary" [ngClass]="disabled ? '' : 'pointer'" [disabled]="disabled" [ngStyle]="{'visibility': showPageButton ? 'visible': 'hidden'}" *ngIf="!modifyTextButton"
              (click)="onClickPageButton()">{{ 'company-card.access-page' | translate | ucfirst }}</button>
      <button class="btn-primary pointer" [ngStyle]="{'visibility': showPageButton ? 'visible': 'hidden'}" *ngIf="modifyTextButton && isCompany()"
              (click)="onClickPageButton()">{{ 'company-card.access-company-informations' | translate | ucfirst }}</button>
      <button class="btn-primary pointer" [ngStyle]="{'visibility': showPageButton ? 'visible': 'hidden'}" *ngIf="modifyTextButton && !isCompany()"
              (click)="onClickPageButton()">{{ 'company-card.access-project-holder-informations' | translate | ucfirst }}</button>
    </div>
  </div>
  <!--<div class="company-to-favorite">
    <i class="fa fa-star-o"></i>
  </div>-->
</div>
