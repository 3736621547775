<div id="page">
  <app-header></app-header>

  <hub-slider-menu></hub-slider-menu>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="rgba(255, 255, 255, 1)" type="ball-spin" name="global">
  </ngx-spinner>
  <div id="content" [ngClass]="isHomePage ? 'hub-body-home hub-body' : 'hub-body'">
    <ngx-spinner bdColor="" size="medium" color="#000000" type="ball-spin">
      <p class="spinner-text">{{ 'loading' | translate | ucfirst }}</p>
    </ngx-spinner>
    <div class="content-wrap" [class]="isHomePage ? '' : 'hub-container'">
      <hub-breadcrumbs *ngIf="!isHomePage"></hub-breadcrumbs>
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-hub-footer></app-hub-footer>
</div>
