<h1 class="line"><span>{{ 'site-plan.title' | translate | ucfirst }}</span></h1>
<nav>
  <p class="bold">{{ 'site-plan.personal-space' | translate | ucfirst }}</p>
  <ul>
    <li><a routerLink="{{ '/' + routes.HOME }}">{{ 'site-plan.home' | translate | ucfirst }}</a></li>
    <li><a routerLink="{{ '/' + routes.PROFILE }}">{{ 'site-plan.profile' | translate | ucfirst }}</a></li>
    <li><a routerLink="{{ '/' + routes.NOTIFICATIONS }}">{{ 'site-plan.notifications' | translate | ucfirst }}</a></li>
    <li *ngIf="isReferent"><a routerLink="{{ '/' + routes.MY_WALLET }}">{{ 'site-plan.my-wallet' | translate | ucfirst }}</a></li>
    <li><a routerLink="{{ '/' + routes.MY_CONTACTS }}">{{ 'site-plan.my-contacts' | translate | ucfirst }}</a></li>
    <li><a routerLink="{{ '/' + routes.DOSSIERS_PROJECTEURS }}">{{ 'site-plan.dossiers-projecteurs' | translate | ucfirst }}</a></li>
    <li><a routerLink="{{ '/' + routes.CONTACT_US }}">{{ 'site-plan.contact-us' | translate | ucfirst }}</a></li>
    <li><a href="javascript:void(0)" (click)="goToSearchPage(tabs.contact)">{{ 'site-plan.search-companies' | translate | ucfirst }}</a></li>
    <li><a href="javascript:void(0)" (click)="goToSearchPage(tabs.plan)">{{ 'site-plan.search-plans' | translate | ucfirst }}</a></li>
    <li><a href="javascript:void(0)" (click)="goToSearchPage(tabs.interlocutor)">{{ 'site-plan.directory' | translate | ucfirst }}</a></li>
  </ul>
</nav>
