import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { SessionService } from '../services/session.service';
import { AgentService } from '../services/hub-api/agent.service';

@Injectable()
export class InterlocutorGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private sessionService: SessionService,
    private agentService: AgentService
  ) {
    super(router, keycloakService);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async resolve => {
      if (!this.authenticated) {
        return resolve(true);
      }

      this.agentService
        .getOne(await this.sessionService.getAgentId())
        .then((agent: any) => {
          const interlocutor = agent.interlocutor || {};
          // Attente spécification ? Rediriger vers une page ? Afficher une popup, un message ?
          return resolve(interlocutor.id ? true : false);
        });
    });
  }
}
