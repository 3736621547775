import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CompanyLogoService } from 'src/app/services/hub-api/company-logo.service';

import { entityTypes } from '../../constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'hub-entity-logo',
  templateUrl: './entity-logo.component.html'
})
export class EntityLogoComponent implements OnInit, OnChanges {
  @Input()
  entity: any;

  isProjectHolder: boolean;
  logoUrl = '';

  constructor(private companyLogoService: CompanyLogoService) {}

  ngOnInit(): void {
    this.computeLogoUrl();
  }

  ngOnChanges(changes) {
    if (changes.entity) {
      this.entity = changes.entity
        ? changes.entity.currentValue
        : changes.entity;
      this.isProjectHolder = this.entity.type === entityTypes.project;
      this.computeLogoUrl();
    }
  }

  computeLogoUrl() {
    this.logoUrl = this.entity.logo
      ? this.companyLogoService.getFileUrl(this.entity.logo)
      : '';
  }
}
