import {Injectable, Injector} from '@angular/core';
import {AbstractService} from "./abstract.service";
import {endpoints} from "../../constants";

@Injectable({
  providedIn: 'root'
})
export class EncartService extends AbstractService {

  constructor(injector: Injector) {
    super(injector);
    this.endpoint = endpoints.encarts;
  }

  getEncartExtranet() {
    return this.api.get(`${this.endpoint}/extranet`);
  }
}
